
// $orange: #ee6e50
$orange_hover: #DE525B;
$red: #f56467;
$black: #000000;
$gray: #666666;
$error_red: #D55050;
$error_bg: #FFF2F2;
$error-border: #FFDBDB;
$red-link: #FD6639;

$unclickable: #929292;

// 链接颜色
$link: #108EE9;


// $s_color: #EE6E50
$t_color: #333;
$c_color: #808080;
$i_color: #B3B3B3;

// 边框颜色（二级）
$color-line: #f6f6f6;
$color-title: #1a1a1a;
$color-gray: #808080;
$color-disable: #bbbbbb;

// 分割线
$split-line: #e5e5e5;

$bc-1: #b95c4d;
$bc-2: #4a97a3;
$bc-3: #cf986d;
$bc-4: #92a89e;

$color-bar: #f2f2f2;
$color-dot: #b3b3b3;
// 修改主色
$main_blue: #fd8a25;
$main_current: #2CFFFF;
$s_color: #fd8a25;
$orange: #fd8a25;
$main: #fd8a25;
$main_hover: #2CFFFF;
$orange_hover: #fd8a25;
$bg_color: #f6f6f6;

// 主颜色 （黄 FD8A25）
$dd_main_color: #FD8A25;

// 手机两边间距
$m_pad: 5.3334%;

// pc导航高度
$pc_nav_height: 59px;

// pc footer高度
$pc_footer_height: 312px;

// 边框颜色（一级）
$line_color: #eee;

// 利好
$like_bull: #f92b61;

// 利空
$like_bear: #0abf1f;

// 行情-涨
$rise: #FF2A2A;

// 行情-跌
$fall: #3DBC9B;

// 行高
$line_height: 1.68em;


// 英文主颜色
$en_main: #EFC364;

// 英文黑颜色
$en_black: #2d2d2d;

// 英文 行情-涨
$en_rise: #3DBC9B;

// 英文 行情-跌
$en_fall: #FF2A2A;

// 英文 利好
$en_rise: #0abf1f;

// 英文 利空
$en_fall: #f92b61;

// 警告
$warning: #FC6243;
