.markets-wrap {
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	.table {
		width: 100%; }
	.tr {
		height: 40px;
		line-height: 40px;
		font-size: 0;
		padding: 0 15px;
		display: -webkit-flex;
		display: flex;
		&.tab-title {
			height: 40px !important;
			line-height: 40px !important;
			background-color: #fff !important; } }
	.td,.th {
		white-space: nowrap;
		&:last-child {
			text-align: right !important; } }
	.th {
		font-weight: normal;
		text-align: left;
		@include fs(12);
		color: #666; }
	.tr:nth-child(2n) {
		background-color: $bg_color; }
	.td {
		@include fs(14);
		// font-weight: bold
		&:last-child {
			-webkit-flex: 1;
			flex: 1; } }
	.list-cont {
		width: 800px;
		.part {
			margin-bottom: 40px;
			.bottom {
				margin-bottom: 20px; }
			.title {
				display: inline-block;
				@include fs(20);
				line-height: 28px;
				font-weight: bold; } }
		.ddIndex {
			border: 1px solid #eee;
			.bottom {
				margin-bottom: 13px; }
			.header {
				padding: 20px 15px 0 15px;
				border-bottom: 1px solid #eee;
				.title {
					vertical-align: middle; }
				.explain {
					display: inline-block;
					position: relative;
					margin-left: 7px;
					&:hover {
						.explain-cont {
							visibility: visible;
							opacity: 1;
							@include transform(0,0); } }
					.icon {
						display: inline-block;
						width: 18px;
						height: 18px;
						background: url(/public/css/img/icon_help@2x.png) no-repeat;
						background-size: 100% 100%;
						vertical-align: middle; }
					.explain-cont {
						width: 560px;
						padding: 10px 20px;
						background-color: #fff;
						border-radius: 2px;
						box-shadow: 0 0 3px 2px rgba(0,0,0,0.1);
						position: absolute;
						top: 39px;
						left: -110px;
						z-index: 99;
						visibility: hidden;
						opacity: 0;
						@include transform(0,-20px);
						@include transition(0.3s,ease,0.2s);
						&:after {
							content: "";
							display: block;
							width: 25px;
							height: 16px;
							background: url(/public/css/img/menu-arrow@2x.png) no-repeat;
							background-size: 100% 100%;
							position: absolute;
							top: -16px;
							left: 106px; }
						.item {
							margin-bottom: 20px; }
						h3 {
							@include fs(16);
							font-weight: bold;
							line-height: 22px;
							margin-bottom: 10px;
							margin-top: 20px;
							span {
								@include fs(14);
								font-weight: normal;
								margin-left: 10px; } }
						h4 {
							@include fs(14);
							line-height: 20px;
							font-weight: bold;
							margin-bottom: 10px; }
						p {
							@include fs(14);
							line-height: 20px;
							margin-bottom: 10px;
							text-align: justify; }
						img {
							display: block;
							margin: 0 auto; } } }
				.ddInfo {
					font-size: 0;
					margin-top: -5px;
					p {
						display: inline-block;
						@include fs(14);
						&.exponential {
							@include fs(36);
							line-height: 50px;
							font-weight: bold;
							margin-right: 20px; }
						&.two {
							@include fs(20);
							line-height: 28px;
							font-weight: bold;
							margin-right: 10px; }
						&.price {
							margin-left: 57px;
							span {
								display: block;
								color: #666;
								&:first-child {
									margin-bottom: 10px; }
								i {
									font-style: normal;
									color: #000; } } }
						&.fall {
							color: $fall; }
						&.rise {
							color: $rise; } } }
				.dvalue-switch {
					p {
						display: inline-block;
						@include fs(14);
						margin-right: 40px;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
						&.current {
							color: $main_blue;
							&:after {
								content: "";
								display: block;
								width: 100%;
								height: 3px;
								background-color: $main_blue;
								position: absolute;
								left: 0;
								bottom: -1px; } } } } }
			#dvalue-echarts {
				// margin-left: 15px
				// border-left: 1px solid #eee
 }				// border-right: 1px solid #eee
			.dvalue-chart {
				height: 300px;
				position: relative;
				.yAxis {
					width: 778px;
					padding: 0 10px;
					font-size: 0;
					text-align: center;
					display: flex;
					position: absolute;
					bottom: 10px;
					&.hide {
						display: none; }
					p {
						flex: 1;
						text-align: center;
						@include fs(12);
						color: #999;
						&:last-child {
 } } }							// display: none
				.sk-three-bounce {
					top: 125px; } } }
		.markets-info-box {
			.search-box {
				font-size: 0;
				margin-top: 2px;
				position: relative;
				input[type=search] {
					width: 129px;
					height: 24px;
					padding: 0;
					border: 1px solid $main;
					border-right: 0;
					@include fs(13);
					color: #999;
					padding-left: 10px;
					vertical-align: top; }
				.submit {
					width: 26px;
					height: 26px;
					line-height: 26px;
					text-align: center;
					font-size: 0;
					padding: 0;
					border: 0;
					background-color: $main;
					position: relative;
					&:after {
						content: "";
						display: block;
						width: 12px;
						height: 12px;
						background: url(/public/css/img/icon_search@2x.png) no-repeat;
						background-size: 100% 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						@include transform(-50%,-50%); } }
				#groupid {
					display: none;
					width: 150px;
					max-height: 189px;
					padding-left: 15px;
					position: absolute;
					top: 26px;
					left: 0;
					background-color: #fff;
					box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1);
					z-index: 99;
					overflow-y: scroll;
					&.visible {
						display: block; }
					li {
						height: 37px;
						line-height: 37px;
						border-bottom: 1px solid #eee;
						@include fs(12);
						color: #666;
						cursor: pointer; } } } }
		.markets-info,.moneyFlow-info {
			width: 798px;
			max-height: 582px;
			border: 1px solid #eee;

			.info-header {
				height: 40px;
				font-size: 0;
				.coin-all {
					width: 40px;
					height: 40px;
					border-right: 1px solid #ccc;
					position: relative;
					.iconBox {
						width: 41px;
						height: 40px;
						position: absolute;
						left: 0;
						top: 0;
						cursor: pointer;
						&.current {
							width: 40px;
							height: 38px;
							border: 1px solid #aaa;
							border-bottom-color: #fff;
							background-color: #fff;
							z-index: 9;
							&:after {
								content: "";
								display: block;
								width: 100%;
								height: 1px;
								background-color: #fff;
								position: absolute;
								bottom: -2px;
								left: 0; }
							.icon {
								width: 12px;
								height: 12px;
								background: url(/public/css/img/icon/icon_close@2x.png) no-repeat;
								background-size: 100% 100%; } } }
					.icon {
						display: block;
						width: 20px;
						height: 16px;
						background: url(/public/css/img/icon_menu@2x.png) no-repeat;
						background-size: 100% 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						@include transform(-50%,-50%); }
					.all-box {
						// display: block
						width: 766px;
						height: 470px;
						padding: 15px;
						border: 1px solid #aaa;
						background-color: #fff;
						position: absolute;
						top: 40px;
						left: 0;
						z-index: 8;
						font-size: 0;
						overflow-x: hidden;
						overflow-y: scroll;
						&.visible {
							display: block; }
						.all-box-cont {
							margin-right: -15px; }
						p {
							@include fs(14);
							color: #666;
							margin-bottom: 10px;
							margin-top: 20px; }
						button {
							padding: 0;
							border: 1px solid #ccc;
							width: 120px;
							height: 40px;
							line-height: 38px;
							text-align: center;
							color: $main;
							@include fs(14);
							background-color: #fff;
							margin-right: 10px;
							margin-bottom: 10px;
							&:hover {
								border-color: $main; } } } }
				.info-nav-box {
					width: 727px;
					height: 40px;
					padding: 0 15px;
					position: relative;
					overflow: hidden;
					.nav_arrow {
						height: 40px;
						line-height: 40px;
						position: absolute;
						right: 15px;
						top: 0;
						font-size: 0;
						span {
							display: inline-block;
							border: 6px solid transparent;
							opacity: 0.5;
							vertical-align: middle;
							cursor: pointer;
							&.arrow_next {
								border-right: 0;
								border-left-color: $main;
								margin-left: 5px; }
							&.arrow_prev {
								border-left: 0;
								border-right-color: $main; }
							&.current {
								opacity: 1; } } }
					.navBox {
						height: 55px;
						position: relative;
						margin-right: 32px;
						// overflow-x: scroll
						overflow: hidden;
						ul {
							white-space: nowrap;
							font-size: 0;
							position: absolute; }
						li {
							display: inline-block;
							height: 40px;
							line-height: 40px;
							margin-right: 40px;
							@include fs(14);
							cursor: pointer;
							&:last-child {
								margin-right: 0; }
							&.current {
								color: $main;
								position: relative;
								&:after {
									content: "";
									display: block;
									width: 100%;
									height: 3px;
									background-color: $main;
									position: absolute;
									bottom: 0;
									left: 0; } } } } } }
			.info-cont {
				max-height: 540px;
				overflow: hidden;
				.notInfo {
					width: 100%;
					text-align: center;
					padding: 15px 0; }
				.tab-title {
					background-color: $bg_color !important;
					.th {
						margin-right: 57px;
						&:first-child {
							margin-right: 101px; } } }
				.coin-info-box {
					.tr {
						height: 50px !important;
						line-height: 50px !important;
						a {
							display: -webkit-flex;
							display: flex; } }
					.td {
						color: #000;
						font-weight: normal;
						overflow: hidden;
						img {
							width: 20px;
							min-height: 20px;
							height: auto;
							max-height: 40px;
							@include border-radius(50%,50%,50%,50%); }
						&.coinInfo {
							padding-left: 28px;
							position: relative;
							img {
								position: absolute;
								top: 50%;
								left: 0;
								@include transformY(-50%); }
							.transactionPair {
								line-height: 20px;
								margin-top: 6px; }
							.exchange {
								@include fs(12);
								color: #666;
								line-height: 17px; } }
						&.coinPrice {
							.rmb {
								line-height: 20px;
								margin-top: 6px; }
							.dollar {
								@include fs(12);
								color: #666;
								line-height: 17px; } }
						&:nth-child(1) {
							width: 109px; }
						&:nth-child(2) {
							width: 105px; }
						&:nth-child(3) {
							width: 116px; }
						&:nth-child(4) {
							width: 116px;
							text-overflow: ellipsis; }
						&:nth-child(5) {
							width: 116px; }
						&:nth-child(6) {
							width: 116px; }
						&:last-child {
							text-align: left !important;
							min-width: 60px; }
						&.rise {
							color: $rise; }
						&.fall {
							color: $fall; } } } } }
		.moneyFlow-info {
			.info-nav-box {
				width: 768px !important; }
			.moneyFlow-chart {
				height: 328px;
				padding: 15px;
				background-color: $bg_color;
				font-size: 0;
				h4 {
					@include fs(14);
					line-height: 20px;
					position: relative;
					text-indent: 11px;
					&:after {
						content: "";
						display: block;
						width: 6px;
						height: 6px;
						background-color: $main;
						border-radius: 6px;
						position: absolute;
						left: 0;
						top: 50%;
						@include transformY(-50%); } }
				.monFlow-sub {
					display: inline-block;
					width: 100%;
					.titlebox,.chart {
						font-size: 0; }
					.side {
						display: inline-block;
						width: 50%; }
					.bot {
						@include fs(12);
						text-align: center;
						margin-top: -20px;
						p {
							display: inline-block;
							margin-left: 40px;
							padding-left: 22px;
							position: relative;
							&:after {
								content: "";
								display: block;
								width: 12px;
								height: 12px;
								background-color: #ccc;
								position: absolute;
								left: 0;
								top: 50%;
								margin-top: -6px; }
							&:first-child {
								margin-left: 0; }
							&:first-child:after {
								background-color: #FF7900; }
							&:nth-child(2):after {
								background-color: #DC3E2E; }
							&:nth-child(3):after {
								background-color: #01926B; }
							&:nth-child(4):after {
								background-color: #309F50; }
							&:nth-child(5):after {
								background-color: #6FB84F; }
							&:nth-child(6):after {
								background-color: #FFA10C; } } } }
				.sk-three-bounce {
					height: 300px !important; } } } }
	.sidebar {
		width: 360px;
		.part {
			margin-bottom: 40px;
			border: 1px solid #eee;
			.bottom {
				padding: 0 15px;
				background-color: $bg_color;
				h2 {
					display: inline-block;
					height: 48px;
					line-height: 48px;
					@include fs(16);
					color: #000;
					font-weight: bold; }
				.riseFall {
					height: 48px;
					line-height: 50px;
					font-size: 0;
					margin-right: -20px;
					span {
						display: inline-block;
						@include fs(14);
						color: #999;
						margin-right: 20px;
						cursor: pointer;
						&.current {
							color: #000;
							font-weight: bold;
							position: relative;
							&::after {
								content: "";
								display: block;
								width: 5px;
								height: 5px;
								background-color: $main;
								@include border-radius(50%,50%,50%,50%);
								position: absolute;
								top: 50%;
								left: -10px;
								@include transformY(-50%); } } } } }
			.more {
				color: #666;
				@include fs(14);
				line-height: 48px;
				cursor: pointer;
				.icon {
					display: inline-block;
					width: 16px;
					height: 16px;
					background: url(/public/css/img/icon_refresh.png) no-repeat;
					background-size: 100% 100%;
					vertical-align: middle;
					margin-top: -2px;
					margin-right: 5px; }
				&.current {
					.icon {
						animation: keepRotate 1s infinite linear; } } }
			.tips {
				font-size: 12px;
				color: #999;
				margin-top: 18px; }
			// .tabbar
			// 	width: 100%
			// 	display: -webkit-flex
			// 	display: flex
			// 	li
			// 		-webkit-flex: 1
			// 		flex: 1
			// 		height: 41px
			// 		line-height: 41px
			// 		text-align: center
			// 		@include fs(14)
			// 		color: #000
			// 		&.current
			// 			color: $main
			// 			position: relative
			// 			&:after
			// 				content: ""
			// 				display: block
			// 				width: 0
			// 				height: 0
			// 				border-width: 0 6px 8px 6px
			// 				border-style: solid
			// 				border-color: transparent transparent #f6f6f6
			// 				position: absolute
			// 				bottom: 0
			// 				left: 50%
			// 				@include transformX(-50%)
			&.rankings {
				.tr {
					height: 50px;
					line-height: 50px;
					&:nth-child(2n) {
						background-color: transparent; }
					&:nth-child(2n-1) {
						background-color: $bg_color; }
					a {
						display: -webkit-flex;
						display: flex;
						width: 100%;
						color: #000; }
					.th {
						margin-right: 115px; }
					.td {
						&:first-child {
							width: 115px; }
						&:nth-child(2) {
							width: 130px; }
						&:last-child {
							color: $rise;
							&.fall {
								color: $fall; } }
						&.coinInfo {
							padding-left: 24px;
							position: relative;
							min-width: 115px;
							.sort-box {
								display: block;
								width: 16px;
								height: 16px;
								line-height: 16px;
								text-align: center;
								@include fs(14);
								color: #999;
								font-style: normal;
								position: absolute;
								left: 0;
								top: 50%;
								@include transformY(-50%);
								&.before {
									color: #fff;
									@include fs(12); }
								&.first,&.two,&.three {
									background-color: $rise; }
								&.two {
									opacity: 0.8; }
								&.three {
									opacity: 0.5; } } }
						.coin-name {
							line-height: 20px;
							margin-top: 6px; }
						.exchange {
							line-height: 17px;
							@include fs(12);
							color: #666; } } }
				&.fall {
					.sort-box {
						&.first,&.two,&.three {
							background-color: $fall !important; } } } }
			&.curbExchange {
				.table {
					.tr:nth-child(2n-1) {
						background-color: $bg_color; }
					.tr:nth-child(2n) {
						background-color: transparent; } }
				.tr {
					.th {
						margin-right: 61px;
						&:nth-child(3) {
							margin-right: 48px; } }
					.td {
						&:first-child {
							width: 85px;
							font-weight: normal; }
						&:nth-child(2) {
							width: 109px; }
						&:nth-child(4) {
							&.rise {
								color: $rise; }
							&.fall {
								color: $fall; } } } } }
			&.tokens {
				.tr {
					.th {
						margin-right: 53px; }
					.td {
						&:first-child {
							width: 77px;
							font-weight: normal; }
						&:nth-child(2) {
							width: 115px; } } } } } } }

.markets-info-box {
	.coin-info-box {
		.table {
			max-height: 500px; } } }



// 行情详情页
.pc-markets-detail {
	.markets-wrap {
		.sidebar {
			.part {
				&.rankings {
					.tr {
						.td {
							&.coinInfo {
								width: 139px;
								padding-left: 0; } }
						.th:nth-child(2) {
							margin-right: 105px; } } }
				.part-detail {
					padding-left: 15px;
					.list {
						padding-bottom: 20px;
						border-bottom: 1px solid #eee;
						h3 {
							margin-top: 20px;
							padding-right: 15px;
							@include fs(16);
							line-height: 22px;
							margin-bottom: 10px; }
						p,a {
							padding-right: 15px;
							text-align: justify;
							@include fs(14);
							color: #666;
							line-height: 1.68em;
							word-wrap: break-word;
							&.book {
								color: #1A80FE; } }
						&:first-child {
							h3 {
								margin-bottom: 0; } }
						&:last-child {
							border-bottom: 0; } } } } } }
	.markets-kline-box {
		height: 532px;
		border: 1px solid #eee;
		.header {
			padding-bottom: 10px;
			border-bottom: 1px solid #eee;
			font-size: 0;
			.cont {
				display: inline-block;
				width: 50%;
				@include fs(14);
				vertical-align: top;
				&.cont-ri {
					font-size: 0;
					padding-top: 27px;
					p {
						display: inline-block;
						width: 50%;
						color: #666;
						@include fs(14);
						margin-bottom: 10px;
						line-height: 20px;
						white-space: nowrap;
						span {
							color: #000; } } }
				.coin-info {
					padding: 20px 0 0 20px;
					input {
						display: none; }
					.coin-pair {
						display: inline-block;
						position: relative;
						@include fs(20);
						line-height: 28px;
						vertical-align: top;
						&.disable {
							.all-info {
								display: none !important; }
							&:after {
								display: none !important; } }
						&:after {
							content: "";
							display: block;
							width: 0;
							height: 0;
							border-width: 6px 4px 0 4px;
							border-color: #000 transparent transparent transparent;
							border-style: solid;
							position: absolute;
							right: 0;
							top: 40%;
							z-index: -1; }
						&.current:after {
							@include rotate(-180deg); }
						&.coin-pair_name {
							margin-right: 68px; }
						p {
							font-weight: bold;
							cursor: pointer;
							padding-right: 18px; }
						.all-info {
							display: none;
							// width: 105px
							padding-left: 15px;
							background-color: #fff;
							position: absolute;
							top: 30px;
							right: -55px;
							z-index: 10;
							@include border-shadow(0,0,4px,2px,rgba(0,0,0,0.1));
							&.visible {
								display: block; }
							&::after {
								content: "";
								position: absolute;
								top: -7px;
								right: calc(50% - 7px);
								right: -webkit-calc(50% - 7px);
								right: -moz-calc(50% - 7px);
								width: 13px;
								height: 7px;
								z-index: 4;
								background: url(/public/css/img/dropdown-menu-arrow.svg); }
							.cont {
								width: 100%;
								max-height: 360px;
								overflow-y: scroll; }
							p {
								height: 35px;
								line-height: 35px;
								border-bottom: 1px solid #eee;
								@include fs(14);
								font-weight: normal;
								white-space: nowrap;
								&.last-child {
									border-bottom: 0; } } } } }
				.coin-price {
					padding-left: 20px;
					&.rise {
						color: $rise; }
					&.fall {
						color: $fall; }
					p {
						display: inline-block;
						margin-right: 10px;
						@include fs(14);
						line-height: 20px;
						&.cnyprice {
							@include fs(24);
							line-height: 33px; } } } } } }

	.article-list {
        margin-bottom: 13px;
        padding-top: 20px;
        .pic {
            float: left;
            margin-right: 30px;
            img {
                width: 100%;
                min-height: 100%; } }
        .cont {
            overflow: hidden;
            .title {
                @include fs(20);
                line-height: 28px;
                color: #000;
                display: block;
                margin-bottom: 10px;
                max-height: 84px;
                overflow: hidden; } }
        .info {
            color: #999;
            font-size: 0;
            margin-bottom: 10px;
            a {
                color: #999;
                &.tag {
                    color: $main_blue; } }
            .gap_point {
                margin: 0 3px; }
            .author {
                font-size: 12px; }
            .tag {
                margin-right: 13px; } }
        .options {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            .fun-box {
                position: absolute;
                right: 0;
                @include fs(12);
                color: #aaa;
                line-height: 17px;
                text-align: center;
                .icon {
                    display: block;
                    width: 21px;
                    height: 16px; }
                .num {
                    position: absolute;
                    top: -17px;
                    left: 50%;
                    @include transformX(-50%); } }
            .likes {
                top: 50%;
                margin-top: -47px;
                .icon {
                    background: url(/public/css/img/icon_pc_likes.png) no-repeat;
                    background-size: 100% 100%; }
                &.current {
                    color: $main_blue;
                    .icon {
                        background: url(/public/css/img/icon_pc_likes_curr.png) no-repeat;
                        background-size: 100% 100%; } } }
            .number-reads {
                top: 50%;
                cursor: auto;
                .icon {
                    background: url(/public/css/img/icon_pc_read.png) no-repeat;
                    background-size: 100% 100%; } }
            .comment {
                padding: 0 20px 15px;
                top: 50%;
                margin-top: 31px;
                right: -20px;
                .icon {
                    width: 18px;
                    height: 24px;
                    background: url(/public/css/img/icon_pc_share.png) no-repeat;
                    background-size: 100% 100%; }
                &:hover {
                    .share-box {
                        display: block; } } } }
        .summary {
            color: #666;
            @include fs(16);
            line-height: 23px;
            text-align: justify;
            max-height: 69px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; }
        .tag {
            font-size: 12px;
            color: $main;
            i {
                font-size: 1.8rem;
                display: inline-block;
                vertical-align: middle;
                margin-top: -4px;
                margin-right: 10px; }
            a {
                vertical-align: top;
                color: $main; } }
        > li {
            padding: 20px 52px 20px 0;
            border-bottom: 1px solid #F2F2F2;
            position: relative;
            &:last-child {
                border: none; } }
        .group {
            font-size: 0;
            margin-right: -6px;
            white-space: nowrap;
            position: relative;
            .group_cell {
                position: relative;
                display: inline-block;
                width: 210px;
                height: 180px;
                margin-right: 6px;
                vertical-align: top; }
            .tag_info {
                position: absolute;
                top: 0;
                text-align: center;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                font-size: 1.8rem;
                color: #fff;
                a {
                    line-height: 240px; } }
            .bottom_banner {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 10px 15px; }
            .title {
                color: #fff;
                @include fs(16);
                line-height: 24px;
                white-space: normal; } }
        .shadow {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(0,0,0,.5); }
        .post_part {
            .pic {
                position: relative;
                width: 200px;
                height: 160px;
                overflow: hidden; }
            .pro_icon {
                position: absolute;
                top: 5px;
                left: 5px; } }
        .authors_part {
            .group_cell {
                height: auto;
                padding-top: 30px;
                border: 1px solid #E3E4E7;
                text-align: center;
                margin-right: 0.13%; }
            img {
                border-radius: 50%;
                margin-bottom: 12px;
                display: inline-block !important; }
            .title {
                color: #333333;
                @include fs(18);
                line-height: 24px; }
            .bottom_banner {
                position: static;
                padding: 0;
                margin-bottom: 8px; }
            .fans_num {
                color: $main;
                margin-bottom: 29px;
                @include fs(13);
                line-height: 17px; } }
        .recommend_post {
            width: 800px;
            padding-right: 0;
            img {
                width: 100%; }
            .cont {
                position: relative; }
            .bottom_banner {
                position: absolute;
                bottom: 0;
                padding: 0 32px 20px;
                color: #fff;
                width: 100%; }
            .title {
                color: #fff;
                display: inline-block;
                white-space: nowrap;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                &.bottom_border {
                    padding-bottom: 20px;
                    margin-bottom: 10px;
                    width: 100%;
                    border-bottom: 1px solid rgba(277,277,277,.42); } }
            .info {
                color: #fff;
                margin-bottom: 0;
                .gap_point {
                    color: #C0C0C0; }
                a {
                    color: #fff; } } }
        .group_tit {
            font-size: 1.6rem;
            font-size: 16px;
            line-height: 22px;
            padding-left: 10px;
            border-left: 4px solid $main_blue;
            margin-bottom: 17px; }
        .slick-arrow {
            z-index: 2;
            width: 32px;
            height: 46px; }
        .slick-next {
            right: -32px; }
        .slick-prev {
            left: -35px; }
        .slick-prev:before, .slick-next:before {
            color: $main_blue;
            // opacity: .4
            font-size: 16px;
            font-family: 'icomoon'; }
        .slick-prev:before {
            content: "\e604"; }
        .slick-next:before {
            content: "\e605";
            padding-top: 20px; } } }

.share-box,.banner-share-box {
    display: none;
    width: 100px;
    @include border-shadow(0,0,4px,2px,rgba(0,0,0,0.1));
    position: absolute;
    top: 36px;
    left: 50%;
    @include transformX(-50%);
    background-color: #fff;
    z-index: 99;
    &::after {
        content: "";
        position: absolute;
        top: -7px;
        right: calc(50% - 7px);
        right: -webkit-calc(50% - 7px);
        right: -moz-calc(50% - 7px);
        width: 13px;
        height: 7px;
        z-index: 4;
        background: url(/public/css/img/dropdown-menu-arrow.svg); }
    .li {
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: #000;
        font-size: 0;
        span {
            vertical-align: middle;
            @include fs(12);
            i {
                line-height: 18px;
                font-size: x-small; } } }
    .icon1 {
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #ccc;
        font-size: xx-small;
        margin-right: 5px;
        margin-left: 10px;
        @include border-radius(50%,50%,50%,50%);
        &.circle-wechat {
            color: #44B549;
            border-color: #44B549; }
        &.circle-weibo {
            color: #e74c3c;
            border-color: #e74c3c; } } }
.load_more {
	display: inline-block;
	color: #ccc;
	text-align: center;
	@include fs(16);
	padding: 11px 42px;
	margin-bottom: 50px;
	cursor: pointer;
	margin-left: 50%;
	transform: translateX(-50%);
	line-height: 16px;
	span {
		margin-top: -2px; }
	&.load_more_btn {
		color: $main_blue;
		box-shadow: 0 2px 4px rgba(0,0,0,0.1);
		.loader {
			display: none; } }
	&.hide {
		display: none; } }



@keyframes keepRotate {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg); }
    to {
        transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
        -o-transform: rotate(-360deg); } }
@-webkit-keyframes keepRotate {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg); }
    to {
        transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
        -o-transform: rotate(-360deg); } }
