.ddh {
	background-color: #f6f6f6;
	.p-footer {
		min-width: 1200px;
		border-color: #fff; }
	textarea {
		resize: none; } }
.wrap {
	width: 1200px;
	margin-left: auto;
	margin-right: auto; }
// 错误颜色
.ddh-error {
	color: #F20000; }
.ddh-error-msg {
	font-size: 12px;
	padding-left: 17px;
	position: relative;
	margin-bottom: 4px;
	&::after {
		content: "";
		display: block;
		width: 12px;
		height: 12px;
		background: url(/public/css/img/ddh_tips_icon.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%); } }


.ddhHeader {
	min-width: 1200px;
	height: 80px;
	background-color: #fff;
	.wrap {
		height: 80px; }
	.logo {
		height: 100%;
		h1 {
			height: 100%;
			i {
				display: inline-block;
				width: 0;
				height: 100%;
				vertical-align: middle; }
			a {
				display: inline-block;
				width: 104px;
				height: 30px;
				background: url(/public/css/img/ddh_logo.png) no-repeat;
				background-size: 100% 100%;
				vertical-align: middle; } } }
	.userInfo {
		line-height: 80px;
		img {
			width: 34px;
			height: 34px;
			margin-top: -3px;
			border-radius: 50%; }
		.user {
			margin-right: 30px;
			.userName {
				font-size: 14px;
				color: #000;
				margin-left: 10px; } }
		.returnHome {
			a {
				font-size: 18px;
				color: $main_blue; } } } }
.ddh-wrap {
	min-width: 1200px;
	margin-top: 20px;
	padding-bottom: 20px;
	margin-bottom: $pc_footer_height;
	position: relative;
	.wrap {
		background-color: #fff;
		overflow: hidden; }
	.step {
		display: table;
		width: 908px;
		margin: 69px auto 0;
		li {
			display: table-cell;
			text-align: right;
			padding-bottom: 40px;
			position: relative;
			&:first-child {
				width: 48px; }
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background-color: #aaa;
				position: absolute;
				top: 23px;
				left: 0; }
			.number {
				display: inline-block;
				width: 44px;
				height: 44px;
				line-height: 44px;
				text-align: center;
				background-color: #fff;
				border: 2px solid #aaa;
				border-radius: 50%;
				color: #aaa;
				font-size: 18px;
				position: relative;
				z-index: 5; }
			.text {
				display: block;
				width: 150px;
				text-align: center;
				font-size: 18px;
				color: #aaa;
				line-height: 25px;
				margin-top: 15px;
				white-space: nowrap;
				position: absolute;
				right: 0;
				z-index: 5;
				margin-right: -50px; }
			&.active {
				&::before {
					background-color: $main_blue; }
				.number {
					color: $main_blue;
					border-color: $main_blue; }
				.text {
					color: $main_blue; } } } }
	.ddh-apply-tips {
		p {
			font-size: 14px;
			color: #F20000;
			text-align: center;
			margin-top: 93px;
			.icon {
				display: inline-block;
				width: 16px;
				height: 19px;
				background: url(/public/css/img/ddh_tips_icon.png) center no-repeat;
				background-size: 100% 16px;
				margin-right: 8px;
				vertical-align: middle; }
			span {
				vertical-align: middle; } } }
	.ddh-type-box {
		margin-top: 110px;
		padding-bottom: 100px;
		ul {
			display: table;
			width: 690px;
			margin: 0 auto;
			li {
				display: table-cell;
				&.type-personal {
					img {
						width: 100px;
						height: 116px; } }
				&.type-mechanism {
					img {
						width: 116px;
						height: 111px; } }
				.cont {
					width: 160px;
					margin: 0 auto;
					text-align: center;
					h3 {
						font-size: 36px;
						line-height: 50px;
						color: $main_blue;
						margin: 15px 0; }
					.tips {
						display: inline-block;
						text-align: left;
						font-size: 14px;
						line-height: 20px;
						color: #666; }
					a.btn {
						width: 106px;
						height: 40px;
						line-height: 40px;
						margin: 30px auto 0;
						border: 1px solid $main_blue;
						border-radius: 4px;
						font-size: 14px;
						color: $main_blue; } } } } } }


// 上传图片弹窗
#change-avatar {
    .current_avatar {
        margin-bottom: 30px;
        img {
            @include rounded(50%); } }
    .select {
        margin-bottom: 15px; }
    .process,.process_s {
        height: 20px;
        margin-bottom: 20px; }
    .info {
        color: $i_color; }
    .upload {
        position: relative;
        overflow: hidden; }
    .upload_input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -ms-filter: 'alpha(opacity=0)';
        font-size: 200px;
        direction: ltr;
        cursor: pointer; }
    .crop_wrap {
        margin-bottom: 30px;
        position: relative; }
    .crop_wrap_zoom {
        padding: 0 20px;
        position: relative;
        margin-bottom: 30px;
        .small,.big {
            position: absolute;
            top: -5px; }
        .icon-pic {
            font-size: 14px;
            color: #666;
            cursor: pointer; }
        .small {
            left: 0;
            margin-left: -20px; }
        .big {
            font-size: 14px;
            right: 0;
            top: -11px;
            margin-right: -25px;
            .icon-pic {
                font-size: 24px; } } } }





// 提交成功
.ddh-finish-box {
	width: 100%;
	height: 800px;
	background-color: #fff;
	text-align: center;
	overflow: hidden;
	img {
		margin-top: 220px; }
	h3 {
		color: $main_blue;
		font-size: 18px;
		line-height: 25px;
		margin-top: 32px;
		margin-bottom: 41px; }
	p {
		font-size: 14px;
		color: #000;
		line-height: 20px;
		margin-bottom: 15px; } }


// 得得号信息 personal
.ddh_index {
	.ddh-personal-info, .ddh-operator-info {
		font-size: 0;
		padding-bottom: 100px;
		margin-top: 120px;
		.form-part {
			font-size: 0;
			position: relative;
			margin-left: 303px;
			margin-bottom: 30px;
			label {
				position: absolute;
				right: 897px;
				display: inline-block;
				// width: 110px
				min-width: 250px;
				padding-right: 15px;
				text-align: right;
				line-height: 50px;
				vertical-align: top;
				font-size: 18px;
				color: #979797; }
			.input_profile {
				width: 578px;
				height: 28px;
				margin-bottom: 10px;
				padding: 10px;
				border: 1px solid #aaaaaa;
				border-radius: 4px;
				&.textarea {
					height: 100px; } }
			.upload-speed-box {
				width: 160px;
				font-size: 12px;
				text-align: center;
				color: #666;
				.process_s,.process {
					margin-bottom: 10px; } }
			.tip {
				width: 600px;
				// margin-left: 125px
				font-size: 12px;
				color: #7a7a7a; }
			.upload {
				display: inline-block;
				position: relative;
				width: 160px;
				height: 160px;
				margin-bottom: 10px;
				cursor: pointer;
				.pic-box {
					width: 160px;
					height: 160px;
					position: relative;
					span {
						display: block;
						width: 100%;
						height: 40px;
						line-height: 40px;
						text-align: center;
						background-color: rgba(255,255,255,0.6) !important;
						font-size: 12px;
						color: #666;
						position: absolute;
						left: 0;
						bottom: 0; } }
				.upload_icon {
					width: 100%;
					height: 100%; }
				.upload_input {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0; }
				img {
					width: 160px;
					height: 160px; } }
			&.protocol {
				position: relative;
				margin-left: 305px; }
			.input_checkbox {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				margin-right: 8px;
				vertical-align: top; }
			.remind {
				display: inline-block;
				margin-left: 18px;
				font-size: 14px;
				color: #7a7a7a;
				line-height: 20px;
				position: initial;
				text-align: left;
				a {
					color: $main_blue; } }
			&.mobile, &.email {
				.input_profile {
					width: 378px; }
				.btn {
					display: inline-block;
					width: 170px;
					height: 50px;
					background-color: $main_blue;
					margin-left: 30px;
					border-radius: 4px;
					line-height: 50px;
					font-size: 18px;
					color: #fff; }
				.codeTime {
					display: inline-block;
					height: 50px;
					line-height: 50px;
					font-size: 18px;
					color: #999;
					margin-left: 30px;
					&.hide {
						display: none; } } }
			&.mobile {
				.btn {
					vertical-align: top; }
				.codeTime {
					vertical-align: top; } }
			&.captcha {
				.input_profile {
					width: 200px; } }
			// 2018.5.25 国际手机号 zezhang@chaindd.com
			&.iscountry {
                border-radius: 6px;
                font-size: 0;
                .countryWarp {
                	display: inline-block;
                	width: 400px;
                	.countryCont {
                		display: -webkit-flex;
                		display: flex;
                		input {
                			-webkit-flex: 1;
                			flex: 1; } } }
                .country_box {
                    display: inline-block;
                    padding: 0 15px;
                    height: 48px;
                    vertical-align: top;
                    position: relative;
                    cursor: pointer;
                    border: 1px solid #aaa;
                    border-right: 0;
                    border-radius: 6px 0 0 6px;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 100%;
                        background-color: #aaa;
                        position: absolute;
                        right: 0;
                        top: 0; }
                    .choise {
                        display: inline-block;
                        width: 100%;
                        height: 48px;
                        text-align: center;
                        line-height: 48px;
                        font-size: 0;
                        .show_code {
                            color: #000;
                            font-size: 14px;
                            vertical-align: middle;
                            margin-right: 10px; }
                        .icon-arrow {
                            display: inline-block;
                            width: 10px;
                            height: 6px;
                            background: url(/public/css/img/icon_arrow.png) no-repeat;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            @include rotate(-180deg);
                            margin-top: -3px;
                            &.curr {
                                @include rotate(0);
                                margin-top: 0; } } } }
                input {
                    height: 28px;
                    vertical-align: middle;
                    border-radius: 0 6px 6px 0;
                    border-left: 0; }
                .dropdown-menu {
                    padding: 0;
                    top: 35px;
                    left: -63px;
                    border: 0;
                    box-shadow: 0 2px 4px 2px #ccc;
                    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.1);
                    ul,ol {
                        li {
                            font-size: 12px;
                            color: #000;
                            &.sortNum {
                                width: 100%;
                                height: 32px;
                                line-height: 32px;
                                background-color: #f6f6f6;
                                text-indent: 15px;
                                margin: -1px 0 0;
                                border: 0; } } }
                    ol {
                        padding: 15px 15px 10px 15px;
                        margin-bottom: -3px;
                        li {
                            display: inline-block;
                            line-height: 17px;
                            margin-right: 12px;
                            margin-bottom: 3px;
                            &.current {
                                color: $main_blue; }
                            &:hover {
                                color: $main_blue; } } }
                    ul {
                        position: relative;
                        li {
                            height: 38px;
                            line-height: 37px;
                            margin: 0 15px;
                            border-bottom: 1px solid #eee;
                            position: relative;
                            &:hover {
                                .icon {
                                    content: "";
                                    display: inline-block;
                                    width: 12px;
                                    height: 9px;
                                    background: url(/public/css/img/code_hover.png) no-repeat;
                                    background-size: 100% 100%;
                                    margin-left: 5px; } }
                            span {
                                float: right; } } } }
                .areas {
                    width: 300px;
                    height: 259px;
                    overflow-y: scroll; }
                .dropdown-menu::before, .dropup-menu::before {
                    display: none; } } }
		.option {
			width: 100%;
			text-align: center;
			margin-top: 50px;
			.btn {
				display: inline-block;
				width: 122px;
				height: 42px;
				line-height: 42px;
				font-size: 14px;
				color: #7a7a7a;
				border-radius: 4px; }
			.last_btn {
				color: $main_blue;
				margin-right: 120px;
				border: 1px solid $main_blue;
				background-color: #fff; }
			.next_btn, .submit_btn {
				color: #fff;
				background-color: $main_blue; } } }
	.ddh-operator-info {
		.form-part {
 } } }			// margin-left: 108px

.ddh_protocol {
	.wrap {
		width: 1200px;
		margin: 0 auto; }
	.protocol-box {
		width: 1000px;
		margin: 0 auto;
		padding-top: 30px;
		padding-bottom: 70px;
		line-height: 1.7em;
		font-size: 14px;
		h2 {
			font-size: 18px;
			line-height: 25px;
			text-align: center;
			margin-bottom: 40px; }
		h3 {
			font-size: 18px;
			font-weight: bold;
			line-height: 25px;
			margin: 30px 0 20px; }
		p {
			font-size: 14px;
			line-height: 20px; }
		li,p {
			margin-bottom: 20px; }
		li {
			ul {
				margin-top: 20px;
				li {
					margin-bottom: 10px;
					text-indent: 2em; } } } } }

.pc-ddh-list {
	.load_more {
		display: none;
		&.is_more {
			display: block; } } }

// 分辨率兼容
@media (max-width: 1440px) {
	.ddh_finish {
		.ddh-wrap {
			margin-bottom: 50px; } }
	.ddh-finish-box {
		height: calc(100vh - 100px);
		img {
			width: 220px;
			margin-top: 80px; } } }
