.advide-container {
    width: 700px;
    text-align: left;
    .top {
        margin: 152px 0 102px;
        .title {
            @include fs(24);
            line-height: 25px;
            margin-bottom: 50px; }
        .intro {
            margin-bottom: 40px;
            @include fs(16);
            line-height: 26px;
            text-align: left; }
        .button {
            i {
                @include fs(18);
                margin-right: 10px;
                vertical-align: middle; } } }
    .discussion {
        h2.title {
            @include fs(16);
            line-height: 22px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 50px; } }

    .no-result {
        text-align: center;
        color: #888; }

    .bullet-list {
        width: 640px;
        margin: 20px auto;
        li {
            padding: 26px 0;
            border-bottom: 1px solid #F2F2F2;
            &:last-child {
                border-bottom: none; }
            span {
                @include fs(14);
                color: $orange;
                vertical-align: top; }
            i {
                @include fs(14);
                color: $orange;
                font-style: normal;
                display: inline-block;
                margin: 0 5px;
                vertical-align: top; }
            p {
                @include fs(14);
                display: inline-block;
                color: #808080;
                width: 80%; } } } }
#advice-popup {
    display: none;
    h3 {
        margin-bottom: 30px; }
    .cont {
        width: 640px;
        border-bottom: 1px solid #D9D9D9; }
    p {
        width: 470px;
        color: #666;
        @include fs(16);
        padding-bottom: 35px;
        margin: 0 auto;
        a {
            color: $orange; } }
    .advice-form {
        margin: 20px 0;
        .half-input {
            width: 260px;
            margin-bottom: 30px;
            display: inline-block;
            @include fs(16);
            span {
                display: block;
                margin-bottom: 10px; }
            input {
                width: 250px;
                height: 34px;
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                padding: 0 5px; }
            i {
                font-size: 14px;
                font-style: normal;
                margin-top: 10px;
                display: block;
                color: #BD1A1A; } }
        div {
            overflow: auto;
            clear: both;
            margin-bottom: 30px;
            >i {
                font-size: 14px;
                font-style: normal;
                margin-top: 10px;
                display: block;
                color: #BD1A1A; }

            p {
                margin: 0;
                color: #333;
                padding-bottom: 0;
                margin-bottom: 15px;
                i {
                    color: $orange;
                    font-style: normal; }
                span {
                    @include fs(14); } }
            label {
                width: 125px;
                display: inline-block;
                @include fs(16);
                input {
                    margin-right: 10px; } }
            textarea {
                width: 100%;
                border: 1px solid #D9D9D9;
                height: 120px;
                box-sizing: border-box;
                resize: none;
                border-radius: 4px;
                padding: 10px; } }
        .captcha-box {
            input {
                width: 145px;
                height: 34px;
                border-radius: 4px;
                border: 1px solid #D9D9D9; }
            img {
                width: 70px;
                height: 30px;
                margin: 0 5px; }
            i {
                @include fs(16);
                color: #ccc;
                display: inline-block; }
            .error {
                font-size: 14px;
                font-style: normal;
                margin-top: 10px;
                display: block;
                color: #BD1A1A; } }
        button {
            margin: 0 auto;
            display: block; } } }

.result-box {
    position: fixed;
    top: 50%;
    margin-top: -50px;
    text-align: center;
    left: 50%;
    margin-left: -235px; }





