.list-page {
    @extend .c-page;
    padding-top: 50px;

    .list_top {
        &.award_top {
            padding-top: 71px; }
        &.special {
            .title {
                margin-bottom: 10px; }
            .options {
                margin-top: 30px; } }
        .pic {
            margin-bottom: 30px; }
        .title {
            @include fs(30);
            line-height: 52px;
            margin-bottom: 20px;
            padding-bottom: 0;
            border: none; }
        .abstract {
            margin-bottom: 24px;
            line-height: 30px;
            @include fs(16);
            color: #666666;
            a {
                color: #FB625A; } }
        #number_of_followers {
            margin-bottom: 30px;
            text-align: center;
            @include fs(16); }
        .share {
            // text-align: right
            margin-bottom: 50px;
            .text {
                @include fs(14);
                line-height: 23px;
                color: #b3b3b3; }
            i {
                margin-left: 12px;
                color: #B3B3B3; } }
        .auction {
            @extend .tc;
            margin-bottom: 63px;
            .btn {
                margin-bottom: 30px; }
            p {
                @include fs(14);
                line-height: 14px;
                color: #808080;
                margin-bottom: 14px; } } }
    h1,.t,.h-t {
        text-align: center;
        color: #333;
        @include fs(24);
        padding-bottom: 58px;
        border-bottom: 1px solid #F2F2F2; }
    .h-t {
        border-bottom: none; }
    &.my_bookmark {
        h1 {
            border-bottom: 1px solid #F2F2F2 !important; }
        .mod-article-list {} }
    //margin-bottom: 50px
    &.my_product {
        h1 {
            border-bottom: 1px solid #F2F2F2 !important;
            @include fs(16); } }
    &.user-detail {
        min-height: 500px;
        margin-top: 60px;
        padding-top: 80px !important; }
    &.my_post {
        min-height: 500px;
        margin-top: 60px;
        padding-top: 80px !important;
        h1 {
            padding-bottom: 30px;
            border-bottom: none; }
        nav {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin: 0 auto;
            margin-bottom: 18px;
            text-align: center;
            background-color: #F6F6F6;
            color: $gray;
            font-size: 0;
            @extend .tc;
            .num {
                color: #B3B3B3;
                margin-left: 8px; }
            // .line
            //     height: 14px
            //     display: inline-block
            //     *display: inline
            //     vertical-align: middle
            //     border-right: 1px solid #d7d7d7
            a {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                color: #B3B3B3;
                display: inline-block;
                *display: inline;
                width: 90px;
                height: 46px;
                line-height: 46px;
                font-size: 14px;
                vertical-align: middle;
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 14px;
                    background-color: #d7d7d7;
                    position: absolute;
                    top: 16px;
                    right: 0; }
                &:last-child:after {
                    display: none; }
                &:visited {
                    color: #b3b3b3; }
                &:hover {
                    color: #666;
                    .num {
                        color: #666; } } }
            a.current {
                color: #FD6639;
                .num {
                    color: #FD6639; } } }
        .load-more {
            margin-top: 0;
            padding-top: 50px;
            border-top: 1px solid #f2f2f2;
            margin-bottom: 30px; }

        .mod-article-list {
            li {
                padding: 20px 0;
                &:last-child {
                    border-bottom: 0; }
                .tools {
                    margin-left: 0;
                    a:hover {
                        color: #666; } }
                .title {
                    @include fs(18); }
                .cont {
                    width: 700px; }
                .info {
                    line-height: 15px;
                    margin-bottom: 0;
                    a {
                        color: #b3b3b3; } }
                .reason {
                    @include fs(18);
                    line-height: 21px;
                    color: #333;
                    margin-bottom: 20px; } }
            li.li-pading {
                padding: 30px 0;
                .edit {
                    position: absolute;
                    right: 0;
                    bottom: 34px;
                    display: inline-block;
                    height: 20px;
                    line-height: 20px;
                    @include fs(14);
                    color: $main; } }
            .quote {
                margin: 20px 0 0 20px;
                padding-left: 10px;
                border-left: 4px #EAEAEA solid;
                .tools {
                    margin-left: 0; }
                // a:hover
                //  // color: #b3b3b3
                .title {
                    color: #808080;
                    @include fs(16);
                    line-height: 30px;
                    margin-bottom: 13px; }
                .info {
                    padding: 5px 0;
                    margin-top: 0; } }

            .reject-msg {
                @include fs(18);
                line-height: 35px;
                color: #333333; } } } }


.mod-article-list {
    li {
        padding: 30px 0;
        border-bottom: 1px solid #F2F2F2;
        @extend .clear;
        position: relative;
        overflow: hidden;
        &:last-child {
            border-bottom: 0; }
        .stickTag {
            display: inline-block;
            background-color: #fc6243;
            border-radius: 4px;
            color: #fff;
            @include fs(18);
            padding: 1px 10px;
            vertical-align: middle;
            line-height: 27px;
            margin-top: -3px;
            margin-right: 5px;
            &.isSymbol {
                margin-right: 0; } }
        .title {
            display: block;
            color: #333;
            @include fs(24);
            margin-bottom: 12px;
            line-height: 1.4em; }
        .intro {
            @include fs(16);
            line-height: 1.6em;
            margin-bottom: 15px; }
        .cont {
            @extend .fl;
            width: 470px; }
        .pic {
            @extend .fr;
            position: absolute;
            top: 50%;
            right: 0;
            @include transformY(-50%);
            // -webkit-transform: translateY(-50%)
            // transform: translateY(-50%)
            margin-left: 30px; }
        .info {
            margin: 10px 0;
            color: #B3B3B3;
            @include fs(13);
            //@extend .clear
            a {
                color: #B3B3B3;
                //$orange
                &:hover {
                    color: #B3B3B3; } }
            .line {
                margin: 0 5px; } }
        .tag {
            color: $s_color;
            @include fs(13);
            overflow: inherit;
            i {
                @include fs(18);
                display: inline-block;
                *display: inline;
                vertical-align: middle;
                margin-top: -4px;
                margin-right: 5px; }
            a {
                color: $s_color !important;
                margin: 0 8px 10px 0;
                &:hover {
                    color: $orange_hover !important; } } }
        .author {
            @extend .fl;
            img {
                @include rounded(50%);
                margin-right: 10px; } }
        .relate {
            @extend .fr;
            .k {
                margin-left: 20px;
                i {
                    margin-right: 5px; } } }
        .tools {
            margin-left: 15px;
            a {
                color: #B3B3B3;
                &:visited {
                    color: #b3b3b3; }
                &:hover {
                    color: #FD6639; } } } }
    .reminder {
        @include fs(16);
        @extend .tc;
        color: #B3b3b3;
        line-height: 21px;
        margin-top: 60px; } }

.load-more {
    text-align: center;
    margin-top: 50px; }

// 热门列表
.hot-list {
    h1 {
        border: none;
        padding-bottom: 15px; }
    .tit-info {
        text-align: center;
        color: #B3B3B3;
        padding-bottom: 50px;
        border-bottom: 1px solid #e5e5e5;
        @include fs(13);
        margin-bottom: 5px; }
    .list-tag {
        a {
            color: #B3B3B3 !important; } }
    .mod-article-list li {
        min-height: 150px;
        .options {
            @extend .fr;
            a {
                display: inline-block;
                *display: inline;
                color: #808080;
                &:hover,&.current {
                    color: $s_color;
                    text-decoration: none;
                    .icon {
                        color: $s_color; } } }
            .icon {
                vertical-align: middle;
                text-align: center;
                display: inline-block;
                *display: inline;
                margin-right: 6px;
                @include fs(16); }
            .icon-comment {
                position: relative;
                top: 2px; }
            .num {
                @include fs(13);
                position: relative;
                top: 2px; } } } }
// 最新文章
.lastest-list {
    h1 {
        border: none;
        padding-bottom: 50px; }
    .lastest-part {
        margin-bottom: 20px;
        .date-bar {
            color: $s_color;
            @include fs(16);
            font-weight: bold;
            border-left: 4px solid $s_color;
            padding-left: 10px;
            margin-bottom: 20px; }
        .latest-list {
            padding-top: 5px;
            border-top: 1px solid #e5e5e5;
            margin: 0 0 30px 0;
            li {
                border-bottom: 1px solid #f2f2f2;
                padding: 20px 0;
                &:last-child {
                    border-bottom: none; } }
            .title {
                @include fs(18);
                color: $t_color;
                margin-bottom: 8px; }
            .time {
                color: $i_color;
                @include fs(13);
                i {
                    padding-right: 5px; } } } }
    .pagination {
        text-align: center; } }

// 推荐列表
.recommend-list {
    h1 {
        border: none;
        padding-bottom: 30px; }
    .how {
        color: $orange;
        @include fs(14);
        line-height: 14px;
        margin-bottom: 30px;
        cursor: pointer;
        &:hover {
            color: $orange-hover; } }
    .kind {
        width: 391px;
        line-height: 38px;
        margin: 0 auto;
        margin-bottom: 18px;
        background-color: #FFF;
        text-align: center;
        background-color: #F6F6F6;
        // padding-bottom: 20px
        // border-bottom: 1px solid #E5E5E5
        @include fs(13);
        .line {
            height: 14px;
            display: inline-block;
            *display: inline;
            vertical-align: middle;
            border-right: 1px solid #d7d7d7; }
        a {
            color: $c_color;
            margin: 0 36px; }
        .num {
            color: $i_color;
            margin-left: 5px; }
        .current {
            color: $s_color;
            .num {
                color: $s_color; } } }
    .r-author-list {
        li {
            border-bottom: 1px solid #F2F2F2; }
        .author-info {
            padding: 32px 0; } }
    .pagination {
        margin-bottom: 30px;
        border-top: none; } }


// 英文栏目
.english {
    .mod-user-info {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #F2F2F2; } }

// 关于我们
.about-page,.helps {
    color: #000;
    .t {
        border: none;
        padding-bottom: 50px; }
    .a-nav {
        text-align: center;
        height: 38px;
        line-height: 38px;
        margin-bottom: 50px;
        background-color: #F6F6F6;
        .line {
            line-height: 20px;
            height: 14px;
            display: inline-block;
            *display: inline;
            vertical-align: middle;
            border-right: 1px solid #D7D7D7;
            margin: -2px 30px 0; }
        a {
            @include fs(14);
            color: $c_color;
            &.current,&:hover {
                color: $s_color; } } }
    .cont {
        color: #393839;
        @include fs(16);
        a {
            color: $s_color; }
        p,.part {
            line-height: 26px;
            margin-bottom: 30px; }
        .part {
            ul {
                list-style-type: disc; }
            .tit {
                margin-bottom: 40px;
                @include fs(16); }
            .b-tit {
                padding-left: 10px;
                border-left: 4px solid $s_color;
                margin-bottom: 20px;
                @include fs(16); }
            p {
                margin-bottom: 0; }
            ol {
                list-style-type: decimal;
                line-height: 26px;
                padding-left: 40px; } }
        .list {
            margin-bottom: 40px;
            p {
                margin-bottom: 30px;
                &.low-bottom {
                    margin-bottom: 15px; } }
            ol {
                list-style-type: decimal;
                line-height: 26px;
                padding-left: 40px; }
            .tit, {
                padding-left: 10px;
                border-left: 4px solid $s_color;
                margin-bottom: 20px;
                @include fs(16); } }
        .inner {
            padding-top: 30px;
            border-top: 1px solid #E5E5E5;
            margin-bottom: 30px; }
        .article-list {
            li {
                margin-bottom: 50px; }
            .tit {
                border-left: 4px solid $s_color;
                padding-left: 10px;
                color: $c_color;
                @include fs(14);
                margin-bottom: 15px; }
            a {
                @include fs(18);
                color: $t_color; } }
        .bt {
            padding-top: 30px;
            border-top: 1px solid #E5E5E5; } }
    .f-link {
        a {
            display: inline-block;
            *display: inline;
            margin-bottom: 20px;
            margin-right: 40px;
            @include fs(16);
            color: $c_color;
            &:hover {
                color: $s_color; } } } }
.tooltip-weixin-inner {
    padding: 20px;
    h2 {
        @include fs(18);
        color: $t_color;
        text-align: center;
        margin-bottom: 20px; }
    p {
        @include fs(13);
        color: $c_color;
        margin-bottom: 28px; } }

// 修改列表
.hot-list,.pc-tag,.pc-user,.award {
    .mod-article-list li .cont {
        width: 470px; } }


//产品编辑页
.report h1 {
    margin-bottom: 50px;
    font-size: 24px;
    font-size: 2.4rem;
    border-bottom: 1px solid #F2F2F2 !important;
    text-align: center;
    padding-bottom: 30px; }




.recommend-list {
    .r-author-list {
        .author-info {
            .author-name {
                display: inline-block;
                line-height: 20px; }
            .pro-sign {
                position: relative;
                margin-left: 15px;
                top: -2px; } } } }




// 关于链得得
.about_wrap {
    // min-height: 100vh
    .cont_wrap {
        width: 700px;
        margin: 0 auto;
        &.contact_cont {
            padding-bottom: 50px; }
        h2 {
            font-size: 28px;
            line-height: 40px;
            color: #000;
            text-align: center;
            margin-top: 80px;
            margin-bottom: 40px; }
        p {
            font-size: 16px;
            color: #000;
            line-height: 22px;
            margin-bottom: 30px; } }
    .banner_box {
        img {
            width: 100%; } } }




.pc-tag,.pc-user {
    .mod-article-list {
        li {
            .info {
                @extend .clear; } } } }
