.p-products {
    width: 980px;
    margin: 0 auto;
    &.p-small {
        width: 700px;
        .product-panel .cont {
            width: 550px !important; }
        .slogen {
            width: auto !important; } }
    .top-recommends {
        position: relative;
        height: 480px;
        overflow: hidden;
        .right-nav {
            width: 310px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #020000;
            background-color: rgba(0,0,0,.7);
            z-index: 3;
            .nav-pannel {
                cursor: pointer;
                width: 252px;
                height: 68px;
                padding: 46px 25px 45px;
                border-bottom: 1px solid rgba(255,255,255,.3);
                border-right: 8px solid transparent;
                overflow: hidden;
                &:last-child {
                    border-bottom: none;
                    height: 69px; }
                &.active {
                    border-right-color: $orange; }
                .tit {
                    color: #fff;
                    @include fs(24);
                    line-height: 27px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    a {
                        color: #fff; } }
                .infor {
                    @include fs(16);
                    line-height: 19px;
                    color: rgba(255,255,255,.6); } }
            .logo {
                float: left;
                margin-right: 15px;
                border-radius: 15px;
                box-shadow: 0px 2px 0px 1px; }
            .r-cont {
                overflow: hidden; } }
        .slick-dots {
            visibility: hidden; }
        .slick-slider {
            margin-bottom: 15px; } }
    .top-recommend {
        width: 100%;
        height: 490px;
        overflow: hidden;
        position: relative;
        display: block;
        .infor {
            position: absolute;
            width: 420px;
            height: 355px;
            z-index: 1;
            top: 0;
            right: 0;
            padding: 75px 480px 50px 80px;
            color: #fff;
            background: white;
            background: rgba(0, 0, 0, 0.5);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.95));
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.95));
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.95));
            background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.95));
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.95));
            .hot-icon {
                margin-bottom: 40px; }
            .p-tit {
                @include fs(48);
                line-height: 51px;
                margin-bottom: 26px;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
                text-overflow: ellipsis; }
            .p-tip {
                @include fs(16);
                line-height: 16px;
                margin-bottom: 26px; }
            .intro {
                @include fs(22);
                line-height: 30px;
                height: 90px;
                overflow: hidden;
                margin-bottom: 50px; }
            .data {
                @include fs(20);
                display: inline-block;
                line-height: 42px;
                border: 2px #fff solid;
                @include rounded(5px);
                padding: 0 15px; } }
        .related_post {
            width: 506px;
            position: absolute;
            top: 395px;
            z-index: 2;
            left: 55px;
            .post-tit {
                line-height: 19px;
                @include fs(18);
                margin-bottom: 10px;
                width: 400px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: $orange;
                &:hover {
                    color: $orange-hover; }
                i {
                    @include fs(16);
                    margin-right: 10px; }
                a {
                    color: $orange;
                    &:hover {
                        color: $orange-hover; } } }
            .post-infor {
                @include fs(13);
                line-height: 13px;
                color: #fff;
                margin-left: 25px;
                a {
                    color: #fff; } }
            .other-report {
                position: absolute;
                right: 0px;
                line-height: 21px;
                @include fs(12);
                @include rounded(40px);
                border: 1px solid #909090;
                padding: 0 8px;
                a {
                    color: #909090; } } } }

    .products-list {
        padding-top: 30px;
        .product-sort-by {
            .tit {
                width: 142px;
                color: gray;
                text-align: center;
                @include fs(13);
                border: 1px solid #d9d9d9;
                line-height: 25px;
                @include rounded(3px);
                cursor: pointer; }
            a {
                width: 170px!important;
                text-align: left!important;
                padding: 0 15px;
                color: #666;
                line-height: 32px;
                &:hover,&.current {
                    color: $orange; } }

            i {
                @include fs(18);
                vertical-align: middle;
                margin-left: 5px; }
            .dropdown-menu {
                top: 35px;
                &::before {
                    right: 20px; } } }
        .catergory {
            padding-bottom: 18px;
            border-bottom: 1px #E5E5E5 solid;
            .tit {
                @include fs(16);
                line-height: 22px;
                display: inline-block;
                margin-right: 20px;
                &.current {
                    a {
                        color: $main; } }
                a {
                    color: #333; } } } }
    .product-panel {
        padding: 40px 0;
        border-bottom: 1px #f2f2f2 solid;
        &:last-child {
            border-bottom: none; }
        .logo {
            margin-right: 30px; }
        .cont {
            overflow: hidden; }
        .p-tit {
            @include fs(24);
            line-height: 32px;
            margin-bottom: 12px;
            a,p {
                color: $black;
                display: inline; }
            .edit-product {
                @include fs(13);
                color: $orange; }
            span {
                font-size: 14px;
                float: right;
                color: #808080; }
            .award {
                float: none;
                color: white; } }
        .time {
            margin-bottom: 14px;
            margin-top: 14px;
            color: #B3B3B3;
            @include fs(13);
            line-height: 15px;
            a {
                color: $orange;
                margin-left: 10px; }
            span {
                float: right;
                color: #5AA3E8; } }
        .slogen {
            color: #808080;
            @include fs(16);
            line-height: 24px;
            width: 700px; }
        .p-data {
            color: #808080;
            margin-top: 15px;
            @include fs(13); }
        .popularity {
            // position: absolute
            @include fs(24);
            line-height: 28px;
            float: right;
            // right: 0
            // top: 50%
            color: #808080;
            // margin-top: -14px
            i {
                margin-right: 10px; } }
        .award {
            display: inline-block;
            background-color: #FF9600;
            width: 89px;
            line-height: 26px;
            border: 3px #FFFFFF solid;
            color: #fff;
            @include fs(13);
            vertical-align: middle;
            text-align: center;
            i {
                margin-right: 5px;
                @include fs(20);
                vertical-align: middle;
                position: relative;
                top: -1px; }
            &.editor {
                background-color: #FC5254;
                i {
                    @include fs(18); } }
            &.financing {
                width: 108px;
                background-color: #FF3D00;
                cursor: pointer;
                i {
                    @include fs(16); }
                .hide-intro {
                    width: 214px;
                    padding: 20px 29px 12px 39px;
                    text-align: center;
                    background-color: #FFF;
                    border: 1px solid #EFEFEF;
                    display: none;
                    position: absolute;
                    top: 115px;
                    left: 560px;
                    text-align: left;
                    ul {
                        list-style-type: disc; } }
                .triangle {
                    position: absolute;
                    display: inline-block;
                    *display: inline;
                    border: 15px solid transparent;
                    border-right-color: #EFEFEF;
                    left: -30px;
                    top: 50%;
                    margin-top: -20px;
                    span {
                        position: absolute;
                        display: inline-block;
                        *display: inline;
                        border: 14px solid transparent;
                        border-right-color: #fff;
                        left: -12px;
                        top: -14px; } } } } } }

.gray-bg {
    background-color: #F5F5F5;
    box-shadow: 0 1px 5px rgba(0,0,0,.1) inset;
    .mce-toolbar-grp {
        background-color: transparent;
        position: fixed;
        top: 50%; }
    input {
        background-color: transparent; } }



.product-detail {
    width: 100%;
    margin: 0 auto;
    .gray-bg {
        background-color: #F5F5F5;
        box-shadow: 0 1px 5px rgba(0,0,0,.1) inset; }
    .product-panel {
        width: 700px;
        border-bottom: 0;
        margin: 0 auto;
        .popularity {
            position: static;
            margin-top: 0;
            color: #F28B73;
            @include fs(15); }
        .slogen {
            margin-bottom: 11px;
            width: auto; }
        .infor {
            @include fs(13);
            line-height: 15px;
            color: #B3B3B3;
            margin-bottom: 20px;
            .gap-line {
                margin: 0 15px; } }
        .linkman {
            padding: 20px 0;
            border-top: 1px dashed #E5E5E5;
            border-bottom: 1px dashed #E5E5E5;
            .intro {
                margin-bottom: 10px;
                @include fs(14);
                line-height: 15px;
                color: #B3B3B3;
                &.tc {
                    color: #666;
                    margin-bottom: 20px; } }
            .infor {
                @include fs(14);
                line-height: 24px;
                color: #808080;
                margin-bottom: 0; }
            .vc-btn {
                margin: 0 auto;
                display: inline-block;
                i {
                    @include fs(32);
                    vertical-align: middle; } } }

        .test-box {
            background-color: #F5F5F5;
            padding: 20px 25px;
            margin-top: 30px;
            strong {
                margin-right: 10px;
                color: #808080;
                @include fs(14);
                font-weight: normal;
                span {
                    @include fs(24);
                    color: $orange;
                    margin-left: 5px; } }
            b {
                font-weight: normal;
                margin-right: 5px; }
            .gray {
                color: white; }
            .right-area {
                float: right;
                margin-top: 3px;
                .btn {
                    display: inline-block;
                    width: 80px;
                    font-size: 13px;
                    height: 32px;
                    line-height: 32px;
                    margin: 0 1px; } } } }

    .product-introduce {
        width: 700px;
        margin: 0 auto;
        padding-top: 1px;
        .infor-txt {
            margin-bottom: 57px; }
        .tit {
            text-align: center;
            @include fs(24);
            line-height: 25px;
            padding-bottom: 33px;
            border-bottom: 1px #F2F2F2 solid;
            margin-bottom: 30px; }
        .small-pics {
            text-align: center;
            margin-bottom: 55px;
            img {
                max-width: 105px;
                max-height: 70px; }
            li,ul {
                display: inline-block; }
            li {
                margin: 0 10px;
                cursor: pointer;
                border: 5px rgba(0,0,0,.0) solid; }
            li.current {
                // margin: 0 15px
                border: 5px rgba(0,0,0,.7) solid; } }
        .intro-switch {
            margin: 40px 0;
            font-weight: bold;
            a {
                border-right: 1px solid #9A9A9A;
                display: inline-block;
                color: #333;
                line-height: 14px;
                padding: 0 20px;
                &:first-child {
                    padding-left: 0; }
                &:last-child {
                    border-right: 0; }
                &.on {
                    color: $orange; } } }
        .pics {
            margin-bottom: 30px;
            .slick-dots {
                bottom: 24px;
                display: none !important;
                li {
                    margin: 0 15px;
                    button::before {
                        color: transparent;
                        border: 2px #fff solid;
                        @include rounded(50%);
                        width: 16px;
                        height: 16px;
                        opacity: 1;
                        background-color: transparent; } }
                li.slick-active button:before {
                    opacity: 1;
                    background-color: #fff; } }
            .slick-slide {
                img {
                    display: inline-block;
                    cursor: pointer; } } }
        .intro,.group-intro,.txt {
            @include fs(18);
            line-height: 31px;
            margin-bottom: 30px; }
        .txt {
            margin-bottom: 0;
            a {
                color: $main; } } }
    .options {
        padding-bottom: 37px;
        border-bottom: 1px #f2f2f2 solid;
        margin-bottom: 50px;
        .tags {
            width: 470px; }
        .act-tag {
            margin: 10px 20px 10px 0; }
        .shares {
            .txt {
                @include fs(14);
                color: #808080;
                margin-right: -5px; }
            [class^="circle"] {
                margin-left: 15px;
                border: 1px #000 solid;
                @include rounded(50%);
                display: inline-block;
                width: 40px;
                height: 40px;
                text-align: center;
                i {
                    line-height: 40px;
                    @include fs(18); } }
            .circle-weibo {
                border-color: #e74c3c;
                i {
                    color: #e74c3c; } }
            .circle-wechat {
                border-color: #44B549;
                i {
                    color: #8ee566; } }
            .circle-tencent {
                border-color: #39b2e2;
                i {
                    color: #39b2e2; } } } }
    .buttons {
        text-align: center;
        margin-bottom: 80px;
        .btn {
            margin: 0 30px;
            color: #fff;
            i {
                margin-right: 10px; } } }
    .post-comment {
        .attitude {
            color: $orange;
            @include fs(16);
            margin-right: 0; } }
    .articles {
        margin-bottom: 50px;
        .tit {
            text-align: center;
            @include fs(24);
            line-height: 25px;
            padding-bottom: 53px;
            border-bottom: 1px #F2F2F2 solid; }
        .tit-has-more {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 20px; }
        .more {
            text-align: center;
            @include fs(14);
            line-height: 16px;
            padding-bottom: 40px;
            border-bottom: 1px #F2F2F2 solid;
            a {
                color: $orange;
                &:hover {
                    color: $orange-hover; } } }
        .reminder {
            text-align: center;
            @include fs(16);
            line-height: 24px;
            padding: 10px 0 50px;
            color: #808080;
            a {
                color: $orange;
                &:hover {
                    color: $orange-hover; } } }
        .mod-article-list {
            li {
                padding-top: 40px;
                h2 {
                    @include fs(24);
                    line-height: 34px;
                    color: #333; }
                .intro {
                    color: #808080;
                    @include fs(16);
                    line-height: 24px;
                    margin-bottom: 0; } } } }
    .post-container {
        margin-bottom: 70px; } }

.trendmakers_reported {
    width: 700px;
    margin: 0 auto;
    h1.tit {
        padding-bottom: 18px;
        @include fs(16);
        line-height: 22px;
        border-bottom: 1px #E5E5E5 solid; }
    .mod-article-list {
        width: 640px;
        margin: 0 auto; } }

.pbox {
    padding-bottom: 20px;
    min-height: 600px; }

.apply-list-box {
    h3 {
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 20px;
        span {
            margin-left: 10px; } }
    .empty-text {
        font-size: 14px;
        text-align: center;
        color: #999;
        margin: 20px auto; }

    .user-list {
        padding: 40px 0;
        a {
            display: inline-block;
            text-align: center;
            color: #333;
            margin: 0px 20px 30px 20px;
            img {
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: block;
                margin-bottom: 10px; } } } }

.report-list-box {
    list-style: none;
    a {
        text-align: center;
        &.user-avatar {
            color: $orange;
            display: inline-block;
            width: 84px;
            margin-right: 30px;
            p {

                span {
                    display: block;
                    color: #808080; } }
            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-bottom: 10px; } } }
    li {
        border-bottom: 1px solid #D8D8D8;
        padding: 40px 0;
        &:first-child {
            border-top: 1px solid #D8D8D8; }
        div {
            display: inline-block;
            width: 540px;
            vertical-align: top;
            a {
                @include fs(16);
                color: #333;
                display: block;
                text-align: left; }
            p {
                margin: 20px 0;
                a {
                    color: #808080;
                    @include fs(14);
                    margin-right: 20px;
                    display: inline-block;
                    i {
                        @include fs(16);
                        margin-right: 5px;
                        position: relative;
                        top: 2px; } } } } }
    .report-image-list {
        overflow: auto;
        li {
            display: inline-block;
            width: 120px;
            height: 120px;
            padding: 0 !important;
            margin-right: 20px;
            border: none !important;
            float: left;
            &:last-child {
                margin-right: 0; }
            img {
                width: 100%; } } } }

.apply-text {
    text-align: center;
    padding-top: 1px;
    h2 {
        color: #333;
        @include fs(24);
        margin-top: 20px; }
    p {
        @include fs(14);
        margin: 30px auto;
        span {
            color: $orange; }
        &.apply-note {
            width: 700px;
            background-color: #F5F5F5;
            color: #333;
            padding: 30px 0;
            text-align: center;
            margin: 30px auto;
            span {
                &.pending {
                    color: #4A90E2; }
                &.rejected {
                    color: #E74C3C; }
                &.passed {
                    color: #5B9A00; } } }
        &.apply-note-simple {
            color: #808080; } } }

.confirm-page {
    display: none; }

.form {
    width: 580px;
    margin: 50px auto;
    padding-bottom: 90px;
    padding-top: 30px;
    &.confirm {
        p {
            color: #333C4E;
            @include fs(14);
            padding: 30px 0;
            border-bottom: 1px solid #D9D9D9;
            margin: 0; }
        .confirm-info {
            padding-bottom: 40px;
            .form-control {
                margin-top: 20px; } }
        .button-block {
            text-align: center;
            button {
                margin: 0 10px;
                display: inline-block;
                width: auto;
                padding: 0 26px; } } }

    .btn {
        width: 20%; }
    .form-control {
        margin-bottom: 20px;
        &.accept-box {
            .error-msg {
                margin-left: 0; } }
        label {
            width: 94/580*100%;
            color: #333C4E;
            @include fs(14);
            margin-right: 18/580*100%;
            display: inline-block;
            vertical-align: top;
            line-height: 51px;
            span {
                color: #D0021B;
                margin-left: 3px; } }
        .error-msg {
            display: block;
            margin-left: 18%;
            color: #D0021B;
            margin-top: 10px; }
        .sub {
            width: auto;
            &:last-child {
                margin-right: 0; }
            span {
                color: #333C4E;
                @include fs(14);
                margin-left: 10px;
                margin-right: 5px; } }
        &.error {
            input {
                border-color: #D0021B !important; }
            .nice-select {
                border-color: #D0021B; } }
        input[type=text],textarea {
            border: 1px solid #D9D9D9;
            height: 49px;
            width: 340/580*100%;
            border-radius: 5px;
            padding: 0 2%;
            @include fs(18);
            outline: none;
            background-color: white; }
        textarea {
            height: auto;
            padding: 2%; }
        select {
            width: 100px; }
        .control-cell {
            width: 450/580*100%;
            display: inline-block;
            span {
                margin: 0 10px 0 0; }
            input {
                margin-top: 20px;
                width: 350/478*100%; } }
        >span {
            margin: 0 10px 0 2px; }
        strong {
            line-height: 51px;
            font-size: 14px; }
        .list {
            max-height: 200px;
            overflow: auto; } }

    .nice-select {
        display: inline-block;
        float: none;
        height: 51px;
        line-height: 51px;
        margin-right: 10px;
        border: 1px solid #D9D9D9;
        .current {
            background: none; } }

    .icheckbox_minimal-yellow {
        margin-right: 10px; } }


.report-list {
    li {
        a {
            color: #333;
            @include fs(18); }
        p {
            color: #808080;
            @include fs(16); }
        .info-area {
            overflow: auto;
            margin: 20px auto 0 auto;
            p {
                @include fs(13);
                float: left; }
            span {
                @include fs(13);
                color: #4A90E2;
                float: right;
                a {
                    @include fs(13);
                    color: #4A90E2;
                    margin-left: 10px; } } }

        .report-image-list {
            overflow: auto;
            li {
                display: inline-block;
                width: 120px;
                height: 120px;
                padding-bottom: 0 !important;
                margin-right: 20px;
                float: left;
                &:last-child {
                    margin-right: 0; }
                img {
                    width: 100%; } } } } }















