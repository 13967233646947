.tmtpass_bg {
    width: 100%;
    height: 50%;
    min-height: 50vh;
    background-color: #FD8A25;
    position: absolute;
    top: 0;
    left: 0; }
.tmtpass {
    .tmtlogo {
        width: 600px;
        margin: 110px auto 30px;
        position: relative;
        img {
            height: 80px; } }
    * {
        box-sizing: border-box; }
    a.link {
        color: $main_blue; }
    .pannel {
        width: 600px;
        margin: 0 auto 80px;
        font-size: 0;
        [class^='pass_'] {
            @include fs(14);
            color: #B3B3B3;
            height: 100%; }
        .pass_logo {
            background-color: $main_blue;
            text-align: center;
            padding-top: 170px;
            width: 420px;
            .vip_bank {
                line-height: 28px;
                font-size: 1.8rem;
                color: #FFFFFF;
                letter-spacing: 0.9px;
                &:last-of-type {
                    margin-bottom: 15px; }
                &:first-of-type {
                    margin-top: 139px; } }
            .bank {
                line-height: 36px;
                img {
                    width: 120px; }
                .zhaoshang {
                    margin-right: 20px; } } } }
    .pass_form {
        position: relative;
        top: 0;
        width: 600px;
        background-color: #FFF;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        overflow: hidden;
        &.message_yz {
            padding: 50px 0;
            box-shadow: 0 0 6px rgba(0,0,0,0.2);
            form {
                padding-top: 20px; }
            .title {
                margin-bottom: 40px; } }
        &.message_cz {
            padding: 30px 0; }
        #wechat_qr_l {
            width: 100%;
            height: 100%;
            padding: 100px 50px 50px;
            background-color: #fff;
            text-align: center;
            position: absolute;
            top: 0;
            transform: scale(0.6) translateY(10%);
            transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
            z-index: -1;
            &.visible {
                opacity: 1;
                filter: alpha(opacity=100);
                -ms-filter: alpha(opacity=100);
                transform: none;
                z-index: 10; }
            #code_box_l {
                iframe {
                    height: 200px;
                    position: relative; } }
            .close_icon {
                display: block;
                width: 20px;
                height: 20px;
                background-image: url(/public/css/img/close_block.png);
                background-size: cover;
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px; }
            .title {
                margin-bottom: 80px; }
            img {
                width: 200px;
                height: 200px; }
            .tips {
                margin: 30px 0 60px;
                i {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    line-height: 32px;
                    text-align: center;
                    border: 2px solid #01C801;
                    border-radius: 50%;
                    @include fs(16);
                    color: #01C801; }
                span {
                    margin-left: 10px; } }
            .other_login {
                @include fs(16);
                p {
                    color: #000; }
                a {
                    color: $dd_main_color;
                    margin-left: 5px; } } }
        form {
            padding: 100px 50px 30px;
            height: 100%; }
        input[type="text"],input[type="password"] {
            border: none;
            padding: 0 15px;
            line-height: 48px;
            height: 50px;
            border: 1px solid #ccc;
            width: 500px;
            @include fs(16);
            color: #333333;
            border-radius: 6px;
            &.middle {
                width: 363px;
                margin-right: 25px; }
            &.s_middle {
                width: 330px;
                margin-right: 25px; }
            &.small {
                width: 150px;
                margin-right: 20px; } }
        .bank {
            img {
                border: 1px solid transparent;
                display: inline-block;
                &.current {
                    border-color: $main; } } }
        .btn_v1 {
            line-height: 50px;
            text-align: center;
            background-color: #FFF;
            border: 0;
            color: $main_blue;
            padding: 0 20px;
            @include fs(16);
            vertical-align: bottom;
            display: inline-block;
            // border-radius: 80px
            &.blue {
                background-color: $main_blue;
                color: #fff; }
            &.large {
                width: 500px;
                height: 50px;
                line-height: 50px;
                border-radius: 6px;
                padding: 0 62px;
                @include fs(18);
                position: static; }
            &.banner {
                width: 100%;
                padding-left: 0;
                padding-right: 0; }
            &.disable {
                border-color: #d8d8d8;
                color: #d8d8d8; }
            &.js_send_code {
                position: absolute;
                top: 1px;
                right: 1px;
                line-height: 48px;
                border-radius: 6px;
                color: #333; } }
        .form_part {
            margin-bottom: 30px;
            overflow: visible;
            white-space: nowrap;
            position: relative;
            &.code_change {
                position: relative;
                .btn_v1 {
                    line-height: 48px;
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    border-radius: 6px;
                    color: #333; } }
            i {
                cursor: pointer;
                &:hover {
                    color: #333; } }
            .pic_code_box {
                font-size: 0;
                position: absolute;
                right: 12px;
                top: 1px;
                height: 48px;
                line-height: 48px; }
            &.phone_code_box {
                .tips {
                    position: absolute;
                    top: 0px;
                    right: 15px;
                    line-height: 48px; } }
            // 2018.5.25 国际手机号 zezhang@chaindd.com
            &.iscountry {
                border: 1px solid #ccc;
                border-radius: 6px;
                font-size: 0;
                display: -webkit-flex;
                display: flex;
                .country_box {
                    display: inline-block;
                    padding: 0 15px;
                    height: 48px;
                    vertical-align: middle;
                    position: relative;
                    cursor: pointer;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 100%;
                        background-color: #D8D8D8;
                        position: absolute;
                        right: 0;
                        top: 0; }
                    .choise {
                        display: inline-block;
                        width: 100%;
                        height: 48px;
                        text-align: center;
                        line-height: 48px;
                        font-size: 0;
                        .show_code {
                            color: #000;
                            font-size: 14px;
                            vertical-align: middle;
                            margin-right: 10px; }
                        .icon-arrow {
                            display: inline-block;
                            width: 10px;
                            height: 6px;
                            background: url(/public/css/img/icon_arrow.png) no-repeat;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            @include rotate(0);
                            margin-top: -3px;
                            &.curr {
                                @include rotate(-180deg);
                                margin-top: 0; } } } }
                input {
                    flex: 1;
                    -webkit-flex: 1;
                    height: 48px;
                    vertical-align: middle;
                    border: 0;
                    border-radius: 0 6px 6px 0; }
                .dropdown-menu {
                    padding: 0;
                    top: 35px;
                    left: -63px;
                    border: 0;
                    box-shadow: 0 2px 4px 2px #ccc;
                    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.1);
                    ul,ol {
                        li {
                            font-size: 12px;
                            color: #000;
                            &.sortNum {
                                width: 100%;
                                height: 32px;
                                line-height: 32px;
                                background-color: #f6f6f6;
                                text-indent: 15px;
                                margin: -1px 0 0;
                                border: 0; } } }
                    ol {
                        padding: 15px 15px 10px 15px;
                        margin-bottom: -3px;
                        li {
                            display: inline-block;
                            line-height: 17px;
                            margin-right: 12px;
                            margin-bottom: 3px;
                            &.current {
                                color: $main_blue; }
                            &:hover {
                                color: $main_blue; } } }
                    ul {
                        position: relative;
                        li {
                            height: 38px;
                            line-height: 37px;
                            margin: 0 15px;
                            border-bottom: 1px solid #eee;
                            position: relative;
                            &:hover {
                                .icon {
                                    content: "";
                                    display: inline-block;
                                    width: 12px;
                                    height: 9px;
                                    background: url(/public/css/img/code_hover.png) no-repeat;
                                    background-size: 100% 100%;
                                    margin-left: 5px; } }
                            span {
                                float: right; } } } }
                .areas {
                    width: 300px;
                    height: 259px;
                    overflow-y: scroll; }
                .dropdown-menu::before, .dropup-menu::before {
                    display: none;
                    // .nav
                    //     ul
                    //         display: flex
                    //         display: -webkit-flex
                    //         height: 40px
                    //         line-height: 40px
                    //         border-bottom: 1px solid #ccc
                    //         li
                    //             flex: 1
                    //             -webkit-flex: 1
                    //             text-align: center
                    //             font-size: 14px
                    //             color: #000
                    //             position: relative
                    //             &.current
                    //                 color: $main_blue
                    //             &.current::after
                    //                 content: ""
                    //                 display: block
                    //                 width: 100%
                    //                 height: 2px
                    //                 background-color: $main_blue
                    //                 position: absolute
                    //                 left: 0
                    //                 bottom: -1px
                    //             &:hover
                    //                 color: $main_blue
                    //             &:hover::after
                    //                 content: ""
                    //                 display: block
                    //                 width: 100%
                    //                 height: 2px
                    //                 background-color: $main_blue
                    //                 position: absolute
                    //                 left: 0
                    //                 bottom: -1px
                    // .cont
                    //     max-height: 228px
                    //     overflow-y: scroll
                    //     ul
                    //         display: none
                    //         padding: 0 15px
                    //         &:first-child
                    //             display: block
                    //         li
                    //             height: 38px
                    //             line-height: 37px
                    //             border-top: 1px solid #eee
                    //             font-size: 12px
                    //             color: #000
                    //             span
                    //                 float: right
                    //             &:first-child
 } } }                    //                 border-top: 0
        .form_last {
            margin-bottom: 15px; }
        .form_first {
            margin-bottom: 15px; }
        .agree {
            line-height: 20px;
            margin-bottom: 15px;
            margin-top: 15px;
            color: #B3B3B3;
            @include fs(16);
            text-align: center;
            .agreement {
                color: #00A4A0;
                margin-left: 5px;
                text-decoration: underline; } }
        .get_more {
            @include fs(16);
            color: #b3b3b3;
            line-height: 25px;
            .remember {
                margin-right: 8px;
                width: 17px;
                height: 17px;
                vertical-align: middle; }
            label {
                color: #909090; }
            .forget {
                color: #B3B3B3; } }
        .returnLogin {
            margin-top: 30px;
            @include fs(16);
            text-align: center; }
        .login-tip {
            display: none; }
        .title {
            @include fs(36);
            color: #FD8A25;
            line-height: 36px;
            margin-bottom: 79px;
            text-align: center;
            a {
                cursor: auto;
                color: $main_blue;
                display: none;
                &.current {
                    display: inline-block; } }
            .or {
                color: #808080;
                @include fs(13);
                margin: 0 20px; }
            .link {
                @include fs(13);
                cursor: pointer; } }
        .intro {
            @include fs(13);
            line-height: 21px;
            margin-bottom: 20px; }
        .option {
            text-align: center;
            margin-top: 30px;
            button {
                margin-right: 20px;
                position: absolute;
                bottom: 50px; }
            .agree {
                @include fs(13);
                text-align: left; }
            a {
                margin: 0 5px; }
            .hint {
                display: inline-block;
                margin: 30px 0;
                @include fs(18);
                color: #ccc;
                a {
                    margin: 0;
                    text-decoration: underline;
                    color: $dd_main_color; } }
            .another {
                margin-top: 60px; } }
        .form_pop {
            position: absolute;
            width: 500px;
            height: 403px;
            top: 50%;
            left: 50%;
            margin-left: -250px;
            margin-top: -201px;
            padding: 50px 99px;
            background-color: #FFF;
            box-shadow: 0 0 50px 0 rgba(0,0,0,.3);
            .form_msg {
                top: 50px; }
            .tit {
                padding-top: 87px;
                color: #666;
                @include fs(24);
                line-height: 28px;
                margin-bottom: 53px; }
            .form_part {
                margin-bottom: 50px; }
            .close-btn {
                position: absolute;
                top: 40px;
                right: 40px;
                color: #A0A0A0;
                @include fs(23);
                cursor: pointer;
                transform: translate(0,0) translateZ(0);
                -webkit-transform: translate(0,0) translateZ(0); } }
        .form_result {
            min-height: 350px;
            display: table;
            // display: none
            padding: 0 50px;
            background-color: #FFF;
            width: 100%;
            height: 100%;
            position: relative;
            .cell {
                display: table-cell;
                text-align: center;
                vertical-align: middle; }
            .title {
                margin-bottom: 40px; }
            .result_cont {
                margin-bottom: 50px;
                p {
                    line-height: 24px;
                    color: #666; } }
            .remind {
                margin-top: 50px;
                @include fs(13); } }

        .captcha-box {
            position: relative;
            //margin-top: -13px
            &.five-forget-captcha {
                margin-top: 0; }
            .img-box {
                //margin-top: -12px
                margin-right: 12px;
                display: inline-block;
                left: 0;
                //top: 0
                vertical-align: middle;
                line-height: 35px;
                img {
                    width: 105px;
                    height: 42px; } }
            .js-refresh-captcha {
                vertical-align: middle;
                font-size: 12px; }
            .btn_v1 {
                line-height: 48px;
                position: absolute;
                top: 1px;
                right: 1px;
                border-radius: 6px;
                color: #333; }
            .captcha_tips {
                    position: absolute;
                    height: 48px;
                    line-height: 48px;
                    top: 0;
                    right: 0;
                    padding: 0 20px; } }
        .phone_code_box {
 }            //margin-top: -13px
        .hide {
            display: none; }
        .third_party {
            .sns-btn {
                display: inline-block;
                width: 40px;
                height: 40px;
                color: #fff;
                border-radius: 50%;
                line-height: 40px;
                text-align: center;
                margin-left: 20px;
                margin-right: 0;
                font-size: 18px;
                &.sina-weibo {
                    background-color: #F56467; }
                &.wechat {
                    border: 2px solid #01C801;
                    border-radius: 50%;
                    margin-left: 30px;
                    color: #01C801; } } }
        .third_user_info {
            position: relative;
            padding-bottom: 20px;
            border-bottom: 1px solid #D8D8D8;
            overflow: hidden;
            .img-box {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%; } }
            .nickname {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                // margin-top: -20px
                margin: 0;
                line-height: 16px;
                @include fs(14);
                color: #333333;
                span {
                    line-height: 16px;
                    @include fs(12);
                    color: #2A2A2A; } } }
        .another_box {
            text-align: center;
            margin-top: 60px;
            .pc_sns_box {
                .tips {
                    font-size: 18px;
                    color: #000;
                    vertical-align: middle; }
                .sns_style {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    line-height: 34px;
                    text-align: center;
                    border: 2px solid #27AE60;
                    border-radius: 50%;
                    font-size: 18px;
                    color: #27AE60;
                    vertical-align: middle;
                    margin-left: 10px;
                    i:hover {
                        color: #27AE60; } } } }
        .testBox {
            width: 20px;
            height: 20px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            right: 0; } }
    .form_msg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        text-align: center;
        ul {
            list-style-type: none; }
        li,p {
            max-width: 70%;
            display: inline-block;
            padding: 12px 20px 10px;
            color: #594042;
            @include fs(13);
            line-height: 1em;
            background-color: #FFF2F2;
            border: 1px solid #FFDBDB; } }
    .bind_form {
        .form_tabs {
            border-bottom: 1px solid #D8D8D8;
            margin-bottom: 20px;
            .title {
                color: #666;
                margin-bottom: -1px;
                padding-bottom: 10px;
                display: inline-block;
                cursor: pointer;
                &.current {
                    cursor: default;
                    border-bottom: 1px solid $main_blue;
                    color: $main_blue; } }
            .fr {
                margin-top: -1px; } }
        .third_info {
            position: relative;
            margin-top: 80px;
            .third_avatar {
                border-radius: 50%;
                position: absolute;
                left: 0;
                bottom: 5px; }
            input {
                text-align: right; } } } }




#wechat_qr {
    background: #fff;
    border: 1px solid rgba(26, 53, 71, 0.12);
    border-radius: 4px;
    box-shadow: 0 -1px 2px rgba(26, 53, 71, 0.1);
    width: 200px;
    height: 280px;
    text-align: center;
    position: absolute;
    top: -264px;
    right: 40px;
    transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
    transform: scale(0.8) translateY(10%);
    opacity: 0;
    z-index: -1;
    &.visible {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: alpha(opacity=100);
        zoom: 1;
        pointer-events: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        z-index: 10 !important; }
    &:after {
        content: "";
        position: absolute;
        bottom: -7px;
        right: calc(50% - 7px);
        right: -moz-calc(50% - 7px);
        width: 13px;
        height: 7px;
        z-index: 4;
        background: url(/public/css/img/dropdown-menu-arrow.svg);
        transform: rotate(180deg); }
    iframe {
        width: 100%;
        height: 400px;
        overflow: hidden; } }






//移动版样式，覆盖原有样式
@media (max-width: 720px) {
    .testBox {
        display: none; }
    .wxTestBox {
        a {
            display: block;
            width: 20px;
            height: 20px;
            background-color: #fff;
            position: fixed;
            bottom: 0;
            left: 0; } }
    .find_password {
        .tmtpass {
            .pass_form {
                input[type="text"] {} } } }
    .tmtpass {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-color: #FFF;
        background-image: none;
        overflow: hidden;
        .tmtpass_bg {
            height: 175px;
            min-height: auto; }
        .tmtlogo {
            margin: 15px 0 0 21px;
            img {
                height: 35px; } }
        .m_tit {
            display: block;
            @include fs(30);
            color: #fff;
            line-height: 42px;
            text-align: center;
            margin-top: 37px;
            position: relative; }
        .btn_v1 {
            border-right: 80px;
            line-height: 50px !important; }
        .pannel {
            position: relative;
            width: 100%;
            height: auto;
            top: 0 !important;
            left: 0;
            margin-left: 0;
            margin-top: 0px;
            // transform: translateY(-50%)
            padding: 25px $m_pad 0;
            .pass_logo {
                display: none; }
            .pass_form {
                //width: 600px !important
                margin: 0 auto; } }
        .form_msg {
            top: 64px;
            position: fixed; }
        .pass_form {
            position: static;
            width: 100%;
            &.message_yz {
                padding-top: 0;
                .title {
                    margin-bottom: 40px !important; }
                .form_result {
                    min-height: auto; } }
            .bank {
                display: none; }
            .form_part {
                margin-bottom: 15px;
                margin-top: 0;
                position: relative;
                &:first-child {
                    margin-bottom: 30px; }
                &.get_more {
                    display: none; }
                &.returnLogin {
                    @include fs(12);
                    text-align: right; }
                &.phone_code_box {
                    .tips {
                        line-height: 35px; } }
                input {
                    height: 35px;
                    line-height: 35px;
                    @include fs(12);
                    border-radius: 3px; }
                .m_right_tips {
                    display: block; }
                .pc_box {
                        display: none; }
                .pic_code_box {
                    height: 34px;
                    line-height: 34px; }
                &.iscountry {
                    input {
                        height: 35px; }
                    .country_box {
                        padding: 0 10px;
                        height: 35px;
                        .choise {
                            height: 35px;
                            line-height: 35px; } } } }
            .form_result {
                width: 100%;
                max-width: 100%;
                padding: 40px 20px 0;
                display: block;
                .cell {
                    display: block;
                    .large {
                        width: 100%;
                        line-height: 50px !important; } } }
            .login-tip {
                display: block;
                width: 100%;
                margin-bottom: 40px;
                line-height: 32px;
                border: 1px solid #22BB95;
                text-align: center;
                @include fs(13);
                color: #808080;
                a {
                    text-decoration: underline;
                    color: #21B890; } }
            .title {
                text-align: center;
                // color: $main
                @include fs(16);
                margin-bottom: 0;
                span {
                    display: inline-block;
                    width: 50%;
                    a {
                        display: inline-block;
                        line-height: 16px;
                        color: #000;
                        position: relative;
                        &.current {
                            color: $main_blue;
                            &:after {
                                content: "";
                                display: block;
                                width: 100%;
                                height: 2px;
                                background-color: $main_blue;
                                position: absolute;
                                left: 0;
                                top: 26px; } } } }
                .register_tit {
                    display: inline-block; } }
            .intro {
                text-align: center; }
            .option {
                position: static;
                padding: 0;
                margin-top: 15px;
                .another {
                    @include fs(16);
                    line-height: 23px;
                    text-align: center;
                    margin: 30px auto;
                    position: relative;
                    .line-left,.line-right {
                        width: 75px;
                        display: inline-block;
                        border-bottom: 1px solid #b3b3b3;
                        position: absolute;
                        top: 11px;
 }                        // margin-bottom: 5px
                    .line-left {
                        left: 0; }
                    .line-right {
                        right: 0; } } }
            form {
                padding: 20px 15px 50px; }
            input[type="text"],input[type="password"] {
                width: 100%;
                &.middle {
                    width: 50%;
                    margin-right: 25px; }
                &.s_middle {
                    width: 50%;
                    margin-right: 25px; }
                &.small {
                    width: 35%;
                    margin-right: 20px; } }
            .help_result {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #FFF;
                padding: 50px 4%;
                height: auto; }
            .third_party .sns-btn.wechat {
                margin-left: 0;
                display: inline-block;
                border-color: #01C801;
                color: #fff; }
            .form_pop {
                width: 95%;
                margin-top: 0;
                margin-left: 0;
                transform: translate(-50%,-50%);
                padding: 25px 4%;
                .close-btn {
                    top: 10px;
                    right: 10px; } }
            .third_party {
                float: none;
                margin-bottom: 0px;
                display: none;
                text-align: center;
                .sns-btn {
                    display: inline-block;
                    // width: 100%
                    height: 45px;
                    margin: 0 20px;
                    border: 2px solid #F56467;
                    border-radius: initial;
                    line-height: 45px;
                    width: 45px;
                    color: #F56467;
                    border-radius: 50%;
                    i {
                        @include fs(20);
 }                        // margin-right: 15px
                    &.sina-weibo {
                        background-color: #FFF;
                        margin-bottom: 15px; }
                    &.sns-weixin {
                        background-color: #FFF;
                        color: #01C801;
                        border: 1px solid #01C801; }
                    .hide {
                        // display: inline-block
                        font-size: 18px; } } }
            .option {
                // margin-top: 75px
                .btn_v1 {
                    margin-bottom: 15px;
                    width: 100%;
                    margin-right: 0px;
                    border-radius: 80px; }

                .btn_v1.large {
                    @include fs(12);
                    height: 35px;
                    line-height: 35px !important;
                    border-radius: 3px; }
                button {
                    position: static; }
                .agree {
                    text-align: center; }
                .hint {
                    display: none;
                    float: none;
                    margin-top: 0;
                    margin-bottom: 20px;
                    text-align: center;
                    position: relative;
                    right: auto;
                    bottom: auto;
                    .hint_left,.hint_right {
                        position: absolute;
                        line-height: 23px;
                        @include fs(16); }
                    .hint_left {
                        right: 0;
                        a {
                            text-decoration: none; }
                        .m_hide {
                            display: none; } }
                    .hint_right {
                        right: 0;
                        a {
                            color: #b3b3b3; } } } }
            .btn_v1 {
                @include fs(12);
                color: #333;
                line-height: 33px !important; }
            .form_part {
                &.code_change {
                    .btn_v1 {
                        line-height: 33px !important; } } }
            .captcha-box {
                img {
                    width: auto !important;
                    height: 30px !important;
                    margin-top: 2px; }
                .captcha_tips {
                    height: 35px;
                    line-height: 35px; } }
            .another_box {
                margin-top: 30px !important; } }
        .bind_form .form_tabs .title {
            @include fs(16); } }
    .p-footer {
        display: none; }

    // 手机版 提示信息（忘记密码...）
    .m_right_tips {
        display: block;
        @include fs(12);
        color: #666;
        line-height: 36px;
        position: absolute;
        top: 0;
        right: 15px; } }



//by hopekayo
.reminder-cont {
    text-align: center !important;
    min-width: 280px;

    .btn {
        margin: 0; }
    // .close-btn
    //     position: absolute
    //     right: 10px
    //     color: #b3b3b3
    //     @include fs(14)

    .txt {
        @include fs(24);
        margin-bottom: 40px; }
    .msg-txt {
        @include fs(14);
        color: $gray;
        margin-bottom: 35px; }
    .error_msg {
        .msg {
            margin-bottom: 30px; } }
    .captcha-input {
        width: 50%; }
    .input_gray {
        width: 60%; }
    .bottom-line {
        border-bottom: 1px #D8D8D8 solid;
        padding-bottom: 15px; }
    .input-part {
        margin-bottom: 50px;
        overflow: hidden;
        .btn {
            margin: 5px; } }
    p.end-msg {
        @include fs(13);
        color: #B3B3B3;
        margin-top: 30px; }
    .avatar {
        margin-bottom: 40px;
        img {
            @include rounded(50%); } }
    .help-part {
        margin-top: 45px;
        text-align: left; }

    .btn-right {
        position: relative;
        right: 12%;
        *display: none;
        opacity: 0;
        &.show {
            right: 0;
            opacity: 1;
            *display: inline-block; } }

    .btn-left {
        position: relative;
        left: 12%;
        *display: none;
        opacity: 0;
        &.show {
            left: 0;
            opacity: 1;
            *display: inline-block; } }
    .v_active {
        position: relative;
        z-index: 3;
        height: 38px; }

    .text-black {
        color: black; } }




.page_wrap {
    padding-top: 80px;
    text-align: center;
    .page_wrap_bg {
        height: 90vh;
        min-height: 760px;
        padding-bottom: 50px;
        background-image: url(/public/css/img/pc_index_ban.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat; }
    .top_txt {
        color: #fff;
        overflow: hidden;
        h2 {
            font-size: 66px;
            margin-top: 60px;
            line-height: 92px; }
        h3 {
            font-size: 36px;
            margin-top: 30px;
            line-height: 50px; } }
    .bot_code {
        margin-top: 60px;
        img {
            width: 250px;
            height: 250px; }
        span {
            display: block;
            width: 250px;
            height: 50px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            background-color: $dd_main_color; } }
    .btn_tips {
        width: 250px;
        height: 60px;
        background-color: #fff;
        border-radius: 6px;
        margin: 30px auto 0;
        a {
            display: block;
            width: 250px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 28px;
            color: $dd_main_color; } } }

.padding_80 {
    padding-top: 40px; }
#change-password_wrapper {
    background-color: #fff !important; }
@media (max-width: 900px) {
    html {
        min-width: 900px; } }

// 兼容1440笔记本
@media (max-width: 1440px) {

    .tmtpass {
        .pass_form {
            .another_box {
                margin-top: 0; } } } }

@media screen and (min-width: 320px) and (max-width: 480px) {
    html {
        min-width: auto !important; } }

// 增加滑动验证码
.yp-riddler-button {
    height: 0 !important;
    line-height: 0 !important;
    border: 0 !important; }

.yp-riddler-button_logo, .yp-riddler-button_text, .yp-riddler-mark {
    display: none !important; }
