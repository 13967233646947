.word_content {
    width: 800px;
    margin: 0 auto;
    padding-top: 30px;
    .word_list {
        margin-bottom: 60px;
        &>.day_part>.date {
            padding-top: 16px; }
        .source-box {
            margin-top: 12px;
            p {
                margin-bottom: 0;
                text-align: right;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 2px;
                    background-color: #999999;
                    margin-right: 8px;
                    margin-top: -3px;
                    vertical-align: middle; } } }
        .day_part {
            margin-bottom: 10px;
            ul {
                .a_img {
                    display: inline-block;
                    margin-top: 24px; } } }

        .date {
            padding-top: 28px;
            color: #666666;
            margin-bottom: -2px;
            time {
                display: inline-block;
                line-height: 20px;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-color: #666666;
                    margin-right: 8px;
                    margin-top: -3px;
                    vertical-align: middle; }
                span {
                    margin-left: 10px; } } }
        li {
            border-bottom: 1px solid #F2F2F2;
            padding: 26px 0;
            .w_tit {
                margin-bottom: 12px;
                line-height: 25px;
                font-size: 18px;
                &:hover {
                    a {
                        color: $main; } }
                a {
                    color: #333; } }
            p {
                font-size: 14px;
                line-height: 20px;
                color: #666666;
                a {
                    color: #666666; }
                .link {
                    color: #108EE9;
                    a {
                        @include fs(13);
                        color: #108EE9;
                        text-decoration: underline; } } } }
        .source {
            line-height: 17px;
            color: #999999;
            font-size: 12px;
            margin-right: 24px; }
        .info {
            margin-top: 24px;
            line-height: 20px; }
        .shares {
            .txt {
                font-size: 12px;
                color: #999999;
                margin-right: 2px; }
            [class^="circle"] {
                margin-right: 12px;
                @include rounded(50%);
                display: inline-block;
                width: 20px;
                height: 20px;
                text-align: center;
                background-color: #bbbbbb;
                i {
                    line-height: 20px;
                    font-size: xx-small;
                    color: #fff;
                    display: inline-block;
                    width: 100%;
                    transform: scale(0.8); } }
            .circle-weibo {
                &:hover {
                    background-color: #EC8181; } }
            .circle-wechat {
                &:hover {
                    background-color: #7CBE96; } }
            .qrcode {
                width: 88px;
                height: 88px;
                margin-bottom: 6px; }
            .w_txt {
                @include fs(14);
                line-height: 20px; }
            .wx-dropdown {
                width: 88px;
                padding: 16px 16px 10px;
                left: -51px;
                bottom: 40px; } }


        .options {
            color: #808080;
            @include fs(14);
            i {
                @include fs(16);
                margin-right: 10px; }
            span {
                cursor: pointer; }
            .js_like {
                .icon-Shape9 {
                    display: none;
                    margin-top: -3px; }
                &.current {
                    .icon-Shape9 {
                        display: inline-block;
                        *display: inline;
                        -webkit-animation: scalefade 300ms;
                        animation: scalefade 300ms; }
                    .icon-like-1 {
                        display: none; }
                    color: $main_blue; }
                .num {
                    display: inline-block;
                    min-width: 25px; } }
            .js_commment {
                img {
                    vertical-align: middle; }
                .num {
                    display: inline-block;
                    color: #999999; } }
            .dropdown-menu-part {
                a {
                    font-size: 13px !important;
                    font-size: 1.3rem !important;
                    color: #333;
                    &:hover {
                        color: $main_blue; } } } } }

    .pagination {
        li {
            padding: 0;
            border: 0; } } }
.like-box {
    font-size: 0;
    //line-height: 44px
    display: inline-block;
    vertical-align: middle;
    a {
        display: inline-block;
        margin-right: 24px;
        line-height: 20px;
        font-size: 0;
        color: #999999;
        &.like-bull {
            .icon {
                background: url(/public/css/img/like_bull1.png) no-repeat;
                background-size: 100%; }
            &.curr {
                color: $like_bull;
                .icon {
                    background: url(/public/css/img/like_bull_curr1.png) no-repeat;
                    background-size: 100%; } } }
        &.like-bear {
            .icon {
                background: url(/public/css/img/like_bear1.png) no-repeat;
                background-size: 100%; }
            &.curr {
                color: $like_bear;
                .icon {
                    background: url(/public/css/img/like_bear_curr1.png) no-repeat;
                    background-size: 100%; } } }
        .icon {
            display: inline-block;
            width: 11px;
            height: 11px;
            vertical-align: middle;
            margin: 0 !important; }
        span {
            vertical-align: middle;
            @include fs(12);
            &.name {
                margin: 0 5px 0 2px; } } } }
.word_detail {
    .dropdown-menu-part {
        .wx-dropdown {
            width: 88px;
            padding: 16px 16px 10px;
            left: -31px;
            bottom: 49px; } }
    article {
        margin-bottom: 40px;
        h1 {
            @include fs(24);
            color: #05121E;
            margin-bottom: 10px; }
        .glass {
            margin-bottom: 30px;
            position: relative;
            display: inline-block;
            cursor: pointer;
            .w_de_img {
                margin: 0 !important;
                width: auto; }
            i {
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: #fff;
                @include fs(40); } } }
    .post-info {
        margin-bottom: 25px;
        .color-unclickable {
            color: #999998;
            @include fs(12); } }
    .inner {
        p {
            @include fs(16);
            line-height: 30px; } }
    .post-options {
        line-height: 35px;
        overflow: visible;
        .shares {
            [class^="circle"] {
                height: 32px;
                width: 32px;
                i {
                    line-height: 32px; } }
            .circle-wechat {
                color: #27AE60;
                i {
                    color: #27AE60; } }
            .circle-like {
                border-color: $main_blue;
                padding: 0 10px;
                border-radius: 47px;
                width: auto;
                cursor: pointer;
                line-height: 32px;
                vertical-align: top;
                i,.num {
                    color: $main_blue; }
                i {
                    margin-right: 4px;
                    @include fs (15);
                    position: relative;
                    top: 1px; }
                .num {
                    @include fs (13); } }
            .circle-like.current {
                background: $main_blue;
                i,.num {
                    color: #fff; } } } }
    .more-message {
        @include fs(14);
        color: $main_blue;
        letter-spacing: 0;
        line-height: 20px;
        display: block;
        text-align: center;
        margin: 20px auto 40px;
        text-decoration: underline; }
    .post-comment {
        margin-top: 40px; }
    .tags {
        color: $main_blue;
        @include fs(13);
        a {
            color: $main_blue; }
        i {
            margin-right: 10px;
            display: inline-block;
            background-image: url("/public/css/img/tag.svg");
            width: 15px;
            height: 15px;
            background-size: cover;
            vertical-align: middle; } }
    .link {
        @include fs(14);
        text-decoration: underline; } }
.no_second_nav {
    .save_img {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.9);
        overflow: scroll;
        z-index: 10;
        .close {
            position: absolute;
            color: #fff;
            right: 90px;
            top: 90px;
            font-size: 38px;
            font-size: 42px;
            cursor: pointer;
            z-index: 11; }
        .big_img {
            left: 50%;
            top: 50%;
            position: absolute;
            max-width: 100%; } } }




