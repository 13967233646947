.post_new .wrapper {
    margin-top: 30px; }

.post-new-sh {
    .reference {
        height: 60px; }
    .btn {
        vertical-align: middle; }
    .autosave {
        font-size: 14px;
        vertical-align: middle; }
    .confrim-post_open {
        margin-left: 20px; } }

.post-new {
    .cover-summary-box {
        margin-top: 30px;
        .box-title {
            margin-bottom: 15px;
            line-height: 21px;
            font-size: 16px; } }
    .post-cover-box {
        position: relative;
        display: inline-block;
        width: 125px;
        .upload-speed-box {
            position: absolute;
            left: 0;
            bottom: 16px;
            width: 100%;
            text-align: center; }
        .upload-box {
            position: relative;
            display: inline-block;
            width: 125px;
            vertical-align: middle;
            .tips-box {
                width: 123px;
                height: 98px;
                border: 1px dashed #aaaaaa;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 25px;
                    transform: translateX(-50%);
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url(/public/css/img/editor/diagram1@2x.png) no-repeat;
                    background-size: 20px 20px; }
                .tip-text {
                    margin-top: 55px;
                    line-height: 20px;
                    font-size: 14px;
                    color: #333333;
                    text-align: center; } }
            .upload_input {
                width: 100%;
                height: 100px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 5;
                &:hover+.tips-box+.pic-box .again {
                    i {
                        background-image: url(/public/css/img/editor/diagram3@2x.png); }
                    em {
                        color: $main; } } }
            .pic-box {
                position: relative;
                max-width: 100%;
                height: 100px;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); }
                .again {
                    position: absolute;
                    bottom: 10px;
                    display: block;
                    width: 100%;
                    font-size: 0;
                    text-align: center;
                    i {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background-image: url(/public/css/img/editor/diagram2@2x.png);
                        background-repeat: no-repeat;
                        background-size: 12px 12px; }
                    em {
                        margin-left: 5px;
                        line-height: 20px;
                        font-size: 14px;
                        font-style: normal;
                        color: #ffffff; } }
                img {
                    height: 100%; } } } }
    .summary-box {
        display: inline-block;
        width: 563px;
        height: 60px;
        padding: 15px 20px 23px;
        margin-left: 20px;
        border: 1px solid #eeeeee;
        position: relative;
        vertical-align: top;
        &:before {
            content: "";
            display: inline-block;
            width: 40px;
            height: 25px;
            background: url(/public/css/img/editor/summary@2x.png) no-repeat;
            background-size: 40px 25px;
            position: absolute;
            top: -6px;
            left: -4px; }
        textarea {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            border: 0;
            resize: none;
            line-height: 20px;
            font-size: 14px;
            color: #333333;
            &::-webkit-input-placeholder {
                color: #aaaaaa; }
            &::-moz-placeholder {
                color: #aaaaaa; }
            &:-ms-input-placeholder {
                color: #aaaaaa; } }
        span {
            position: absolute;
            bottom: 5px;
            right: 20px;
            font-size: 12px;
            color: #aaaaaa;
            &.warning {
                color: $warning; }
            i {
                font-size: 24px;
                font-style: normal; } } }
    p.tips-size {
        margin-top: 5px;
        line-height: 17px;
        font-size: 12px;
        color: #999999;
        white-space: nowrap;
        &:before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-top: -3px;
            margin-right: 5px;
            background-color: #aaaaaa;
            border-radius: 5px;
            vertical-align: middle; } }
    #post_title {
        display: block;
        width: 100%;
        height: 47px;
        padding-bottom: 10px;
        margin: 30px 0;
        font-size: 36px;
        font-weight: normal;
        line-height: 45px;
        color: #000;
        border: 0;
        border-bottom: 1px solid #d8d8d8; } }
// 上传图片弹窗
#change-post-cover {
    .current_avatar {
        margin-bottom: 30px;
        img {
            @include rounded(50%); } }
    .select {
        margin-bottom: 15px; }
    .process,.process_s {
        height: 20px;
        margin-bottom: 20px; }
    .info {
        color: $i_color; }
    .upload {
        position: relative;
        overflow: hidden; }
    .upload_input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -ms-filter: 'alpha(opacity=0)';
        font-size: 200px;
        direction: ltr;
        cursor: pointer; }
    .crop_wrap {
        margin-bottom: 30px;
        position: relative; }
    .crop_wrap_zoom {
        padding: 0 20px;
        position: relative;
        margin-bottom: 30px;
        .small,.big {
            position: absolute;
            top: -5px; }
        .icon-pic {
            font-size: 14px;
            color: #666;
            cursor: pointer; }
        .small {
            left: 0;
            margin-left: -20px; }
        .big {
            font-size: 14px;
            right: 0;
            top: -11px;
            margin-right: -25px;
            .icon-pic {
                font-size: 24px; } } } }

// 编辑器内样式
#editor {
    .w-e-text {
        ul {
            list-style: disc; }
        ol {
            list-style: decimal; } } }


hr {
    background-color: #666; }

.autosave {
    font-size: 14px;
    vertical-align: middle; }
.btn {
    vertical-align: middle; }
.confrim-post_open {
    margin-left: 20px; }


// 2020.03.19 样式改版 zezhang@chaindd.com
.post-new {
    width: 1140px;
    padding-bottom: 110px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0;
    &.post-new-fixed {
        .draft-columns {
            position: fixed;
            top: 0;
            z-index: 10; }
        .main {
            margin-left: 390px;
            .post-title-box {
                position: fixed;
                top: 0;
                z-index: 10; } } }
    .draft-columns {
        display: inline-block;
        width: 360px;
        vertical-align: top;
        background-color: #ffffff;
        .columns-title {
            height: 42px;
            padding: 0 15px;
            line-height: 42px;
            font-size: 16px;
            color: #333333;
            border: 1px solid #eeeeee;
            border-top: 3px solid $main; }
        .columns-item {
            max-height: 384px;
            border-left: 1px solid #eeeeee;
            border-right: 1px solid #eeeeee;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width : 5px;
                height: 1px; }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: #eeeeee; }
            &::-webkit-scrollbar-track {
                background: transparent; }
            .columns-list {
                position: relative;
                padding: 15px;
                padding-bottom: 16px;
                cursor: pointer;
                overflow: hidden;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 1px;
                    background-color: #eeeeee;
                    position: absolute;
                    left: 15px;
                    bottom: 0; }
                &:hover {
                    background-color: #f6f6f6;
                    &:after {
                        display: none; } }
                &.active {
                    margin-left: -1px;
                    border-left: 3px solid $main;
                    background-color: #f6f6f6; }
                .draft-title {
                    width: 300px;
                    height: 24px;
                    margin-bottom: 5px;
                    line-height: 24px;
                    font-size: 14px;
                    color: #333333;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis; }
                .draft-time {
                    height: 17px;
                    line-height: 17px;
                    font-size: 12px;
                    color: #666666; }
                i.delete-draft {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url(/public/css/img/editor/delete_draft@2x.png) no-repeat;
                    background-size: 20px 20px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    &:hover {
                        background-image: url(/public/css/img/editor/delete_draft_curr@2x.png); } } } }
        .no-postnew {
            position: relative;
            width: 358px;
            height: 300px;
            border: 1px solid #eeeeee;
            border-top: 0;
            border-bottom: 0;
            text-align: center;
            font-size: 0;
            span {
                position: absolute;
                left: 0;
                bottom: 40px;
                display: block;
                width: 100%;
                line-height: 20px;
                font-size: 14px;
                color: #999999; } }
        .to_new {
            height: 50px;
            line-height: 50px;
            color: $main;
            text-align: center;
            background-color: #f6f6f6;
            cursor: pointer;
            &:hover {
                color: #ffffff;
                background-color: $main;
                i {
                    background-image: url(/public/css/img/editor/to_new_curr@2x.png); } }
            i {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 5px;
                background: url(/public/css/img/editor/to_new@2x.png) no-repeat;
                background-size: 24px 24px;
                vertical-align: middle; }
            span {
                font-size: 16px;
                vertical-align: middle; } } }
    .main {
        display: inline-block;
        width: 750px;
        margin-left: 30px;
        .post-title-box {
            width: 670px;
            padding-right: 80px;
            padding-bottom: 15px;
            position: relative;
            background-color: #ffffff;
            span {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                color: #aaaaaa;
                margin-top: -7.5px;
                &.warning {
                    color: $warning; }
                i {
                    font-style: normal; } } }
        .title {
            height: 28px;
            line-height: 28px;
            @include fs(20);
            @include plh(#B3B3B3);
            border: none;
            width: 100%;
            font-weight: normal;
            vertical-align: middle;
            &::-webkit-input-placeholder {
                color: #aaaaaa; }
            &::-moz-placeholder {
                color: #aaaaaa; }
            &:-ms-input-placeholder {
                color: #aaaaaa; } }
        .inner-wrap {
            border: 1px solid #eeeeee;
            overflow: hidden; }
        .inner {
            @include fs(16);
            textarea {
                width: 700px;
                border: none; } }
        .wysiwyg-container {
            width: 700px;
            border: none; }
        .wysiwyg-editor {
            p {
                margin-bottom: 20px; }
            ol,ul {
                margin: 20px;
                list-style: initial; }
            ol {
                list-style-type: decimal; } } }
    .footer-bar {
        position: fixed;
        width: 100%;
        bottom: 0;
        right: 0;
        height: 80px;
        background-color: #FFF;
        box-shadow: 0 -6px 16px 0 rgba(0,0,0,0.05);
        .bar-cont {
            width: 1140px;
            margin: 0 auto;
            font-size: 0;
            .btn-part {
                display: inline-block;
                height: 80px;
                line-height: 80px;
                #bar-first {
                    opacity: 0; }
                .checkbox-style-box {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    position: relative;
                    vertical-align: middle;
                    input:checked+i {
                        background-image: url(/public/css/img/editor/checked@2x.png); }
                    i {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        background-image: url(/public/css/img/editor/checkbox@2x.png);
                        background-repeat: no-repeat;
                        background-size: 16px 16px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: -1; } }
                span {
                    margin-left: 5px;
                    line-height: 20px;
                    font-size: 14px;
                    color: #999999;
                    vertical-align: middle; } }
            .btn-box {
                display: inline-block;
                margin-left: 70px;
                vertical-align: middle;
                button {
                    display: inline-block;
                    width: 98px;
                    height: 34px;
                    padding: 0;
                    margin-right: 30px;
                    border: 1px solid #eeeeee;
                    font-size: 14px;
                    color: #aaaaaa;
                    background-color: transparent;
                    &.btn-confrim {
                        margin-right: 0;
                        color: #ffffff;
                        background-color: rgba(253,138,37,0.5);
                        &.active,&.active:hover {
                            color: #ffffff;
                            background-color: $main; } }
                    &.active {
                        color: #333333;
                        &:hover {
                            background-color: #eeeeee; } }
                    &.isSave {
                        color: #aaaaaa; }
                    span {
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        display: inline-block;
                        background: #FFFFFF;
                        margin-right: 1px;
                        vertical-align: middle;
                        @include animation2(mymove,1.2s,infinite,ease-in-out,-0.5s);
                        &:nth-child(2) {
                            @include animation2(mymove,1.2s,infinite,ease-in-out,-0.3s); }
                        &:nth-child(3) {
                            @include animation2(mymove,1.2s,infinite,ease-in-out,-0.1s); }
                        @keyframes mymove {
                            0% {
                                @include scale(0.6,0.6); }
                            25% {
                                @include scale(1.1,1.1); }
                            50% {
                                @include scale(0.6,0.6); }
                            75% {
                                @include scale(0.6,0.6); }
                            100% {
                                @include scale(0.6,0.6); } } } } } } } }



#confrim-post {
    .universal-container {
        width: 700px;
        max-width: 700px; }
    .txt {
        margin-bottom: 0; }
    .info {
        color: #666;
        @include fs(14); }
    .btn-part {
        margin-top: 40px;
        .status {
            color: #B3B3B3;
            font-size: 13px;
            margin-right: 15px; } }
    .first {
        color: #B3B3B3;
        @include fs(14);
        margin-top: 15px;
        input {
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 5px; } } }

// 删除草稿
.delete_draft_wrap {
    position: fixed;
    top: 327px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    text-align: center;
    z-index: 10;
    .text-box {
        padding: 15px 0; }
    .tit {
        margin-bottom: 10px;
        line-height: 22px;
        font-size: 16px; }
    .text {
        line-height: 24px;
        font-size: 14px; }
    .btn-box {
        padding: 15px 0;
        border-top: 1px solid #eeeeee;
        font-size: 0;
        text-align: center;
        button {
            width: 98px;
            height: 36px;
            line-height: 34px;
            font-size: 14px;
            border: 1px solid #eeeeee;
            &.delete-btn {
                margin-left: 52px;
                line-height: 36px;
                color: #ffffff;
                background-color: $main;
                border: 0; } } } }
