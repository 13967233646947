.gift-page {
    padding: 20px 0 0;
    width: 980px;
    .show {
        padding-top: 30px;
        position: relative;
        margin-bottom: 40px;
        h1 {
            @include fs(24);
            line-height: 25px;
            color: $black;
            margin-bottom: 34px; }
        .info {
            width: 500px;
            margin: 0 auto 44px;
            @include fs(14);
            line-height: 22px;
            text-align: center; }
        .panel {
            width: 310px;
            margin: 0 auto;
            background-color: #F6F6F6;
            padding: 10px 0;
            color: #808080;
            @include fs(14);
            a,span {
                color: $orange; }
            a:hover {
                color: $orange-hover; } }

        .btn {
            @include fs(14);
            margin-bottom: 50px; } }
    .gift-list {
        .title {
            @include fs(16);
            color: $t_color;
            padding-bottom: 20px;
            margin-bottom: 39px;
            border-bottom: 1px solid #e5e5e5; }
        .list {
            text-align: justify;
            &:after {
                width: 100%;
                display: inline-block;
                *display: inline;
                height: 0;
                content: ''; }
            li {
                display: inline-block;
                *display: inline;
                margin-bottom: 50px;
                color: $i_color;
                @include fs(13);
                // border: 1px solid #E5E5E5
                .inner {
                    padding: 20px 20px 20px 0; }
                .name {
                    @include fs(16);
                    display: block;
                    margin-bottom: 10px;
                    color: $t_color; } } }
        .list-all {
            margin-left: -36px;
            li {
                width: 300px;
                margin-left: 36px; } }
        .load-more {
            margin: 0 0 60px; } } }
.rich-page {
    color: $t_color;
    padding-top: 30px;
    .t {
        @include fs(24);
        padding-bottom: 50px;
        margin-bottom: 30px; }
    .load-more {
        margin-bottom: 30px; }
    .rich-inner {
        @include fs(16);
        .status {
            line-height: 26px;
            margin-bottom: 40px;
            a {
                color: $s_color; }
            .i {
                @include fs(14); } }
        .process {
            padding: 0 20px;
            margin-bottom: 30px;
            .swd {
                left: 0;
                top: 10px;
                margin-left: -14px;
                position: relative;
                border-width: 14px;
                border-style: solid;
                border-color: #FD6639 transparent transparent transparent; }
            .bg {
                position: relative;
                padding: 12px 0; }
            .bar {
                height: 8px;
                background-color: #D8D8D8;
                margin: 0 2px; }
            .level {
                text-align: justify;
                position: absolute;
                width: 100%;
                left: 0;
                top: 50%;
                margin-top: -16px;
                &:after {
                    width: 100%;
                    display: inline-block;
                    *display: inline;
                    height: 0;
                    content: '';
                    overflow: hidden; }
                .c {
                    display: inline-block;
                    *display: inline;
                    width: 28px;
                    height: 28px;
                    @include fs(13);
                    text-align: center;
                    line-height: 30px;
                    color: #909090;
                    background-color: #d8d8d8;
                    border: 2px solid #fff;
                    @include rounded(50%); } }
            .fore {
                width: 0;
                overflow: hidden;
                position: relative;
                top: -32px;
                .inner,.level {
                    width: 660px; }
                .bar {
                    background-color: #FD6639; }
                .c {
                    color: #fff;
                    background-color: #FD6639; } } } } }
.rich-table {
    width: 100%;
    text-align: left;
    th {
        text-align: left;
        padding: 16px 0 16px 20px;
        margin-bottom: 30px;
        background-color: #f6f6f6;
        font-weight: bold;
        border: none;
        @include fs(14); }
    td {
        @include fs(14);
        color: $c_color;
        padding-left: 20px;
        padding: 20px 0 20px 20px;
        border-bottom: 1px solid #F2F2F2; }
    .line {
        td {
            padding: 10px 0;
            border: none; } } }

.rich-pop {
    position: absolute;
    width: 550px;
    padding: 80px 0 40px;
    .title {
        text-align: center;
        @include fs(24);
        color: $t_color;
        padding-bottom: 60px;
        border-bottom: 1px solid #E5E5E5; }
    .rule {
        .title {
            border-bottom: 0; } }
    .scroll {
        // height: 500px
 }        // overflow-y: auto
    ul.list {
        li {
            padding: 30px 0;
            color: $c_color;
            line-height: 20px;
            border-bottom: 1px solid #E5E5E5;
            @include fs(14);
            .q {
                @include fs(18);
                color: $t_color;
                margin-bottom: 15px; } } } }



















