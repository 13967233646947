.report-container {
    width: 700px;
    text-align: left;
    .report-progress-bar {
        margin: 152px 0 70px;
        h1 {
            margin-bottom: 50px;
            @include fs(24); }
        .child {
            text-align: center;
            padding-bottom: 11px;
            border-bottom: 5px #f2f2f2 solid;
            color: #808080;
            @include fs(18); }
        .current {
            border-bottom: 5px $main solid;
            color: $main; } }
    .report-cell-1,.report-cell-3 {
        @include fs(16);
        line-height: 26px;
        margin-bottom: 100px;
        p {
            margin-bottom: 39px; }
        .text {
            margin-bottom: 70px;
            color: #333;
            @include fs(16); } }
    .button {
        text-align: center;
        .btn {
            margin: 0 15px; } }
    .report-cell-2,.report-cell-3 {
        display: none;
        margin-bottom: 100px; }
    .report-cell-2 {
        label,input,textarea,select {
            display: block; }
        input[type=radio] {
            display: inline-block;
            *display: inline; }
        label {
            @include fs(16);
            margin-bottom: 30px;
            span {
                color: $main;
                margin-left: 5px; }
            i {
                @include fs(13);
                color: #808080;
                font-style: normal; } }
        input,textarea,select {
            margin-top: 15px; }
        input[type=text] {
            width: 320px;
            border: 1px #D9D9D9 solid;
            line-height: 38px;
            @include rounded(4px);
            padding: 0 45px 0 15px;
            box-sizing: border-box; }
        textarea {
            @include rounded(4px);
            border: 1px #D9D9D9 solid;
            width: 100%;
            padding: 15px;
            height: 200px; }
        .financing {
            margin-bottom: 30px;
            label {
                margin-bottom: 22px; }
            .f-label {
                display: inline-block;
                margin-right: 140px;
                margin-bottom: 0;
                input {
                    margin-right: 7px;
                    margin-top: 0; } } }
        .input {
            margin-bottom: 50px; }
        .is-radio {
            display: inline-block;
            *display: inline;
            margin-right: 50px;
            .v-radio {
                border: 1px #BCBCBC solid;
                width: 14px;
                display: inline-block;
                *display: inline;
                height: 14px;
                border-radius: 50%;
                text-align: center;
                position: relative;
                top: 3px;
                margin-right: 15px;
                .point {
                    display: none;
                    height: 12px;
                    width: 12px;
                    color: red;
                    background-color: #fd6639;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -6px;
                    // transform: translateY(-50%)
                    @include transformY(-50%); } }
            .current {
                .point {
                    display: inline-block;
                    *display: inline; } } } } }
.dropdown-part {
    display: block;
    position: relative;
    margin: -15px 0  30px 0;
    .dropdown-btn {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #BCBCBC;
        @include rounded(3px);
        display: inline-block;
        *display: inline;
        padding: 7px 10px;
        line-height: 23px;
        font-size: 16px;
        vertical-align: middle;
        font-weight: normal;
        color: #4A4A4A;
        width: 320px;
        padding: 7px 10px 2px 10px; }
    i {
        float: right;
        line-height: 23px; }
    .text {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        *display: inline;
        line-height: normal; }
    .dropdown-list {
        position: absolute;
        display: none;
        width: 318px;
        top: 40px;
        z-index: 11;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.7);
        padding: 5px 0;
        margin: 2px 0 0;
        border: 1px solid rgba(0,0,0,0.2);
        @include rounded(3px);
        -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        -o-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        -moz-background-clip: padding-box;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        font-size: 14px;
        li {
            cursor: pointer;
            margin-bottom: 0 !important;
            width: auto;
            color: #333333;
            border-top: none;
            line-height: 18px;
            word-break: break-all;
            line-height: 18px;
            padding: 10px 20px;
            &:hover {
                background: $main;
                color: #fff; } }
        li.current {
            color: $main;
            &:hover {
                color: #fff; } } } }

.underline {
    margin-bottom: 20px !important;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 20px; }

.checkbox-group {
    margin-bottom: 20px;
    label {
        display: inline-block !important;
        width: 24%;
        margin-bottom: 20px !important;
        input {
            display: inline-block !important;
            margin-right: 5px; } } }

.pic-area {
    margin-bottom: 15px; }

.upload-area {
    width: 110px;
    height: 110px;
    border: 1px solid #E5E5E5;
    background-color: #F2F2F2;
    text-align: center;
    cursor: pointer;
    position: relative;
    &.big {
        width: 200px;
        display: inline-block;
        margin-right: 15px; }
    &.mid {
        width: 148px;
        margin-right: 30px;
        &:last-child {
            margin-right: 0; } }
    i {
        color: #B3B3B3;
        @include fs(24);
        line-height: 110px; }
    input {
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        margin-top: 0 !important; }
    div {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden; }
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        cursor: auto; }
    .sending-bg {
        content: '上传中...';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        color: white;
        line-height: 110px; }
    span {
        position: absolute;
        right: -10px;
        top: -10px;
        color: white;
        background-color: red;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        border-radius: 50%; } }

.product-preview {
    ul {
        li {
            margin: 20px 0;
            span {
                color: #909090;
                display: inline-block;
                width: 165px;
                vertical-align: top; }
            p {
                display: inline-block; }
            .small-pic {
                width: 110px;
                display: inline-block;
                div {
                    width: 110px;
                    height: 110px;
                    border: 1px solid #ccc;
                    img {
                        display: inline-block;
                        width: 100%;
                        height: auto; } } }
            .big-pic {
                display: inline-block;
                width: 470px;
                div {
                    width: 200px;
                    height: 110px;
                    border: 1px solid #ccc;
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    margin: 0 30px 30px 0;
                    vertical-align: top;
                    &:last-child {
                        margin-bottom: 0; }
                    img {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        left: 50%; } } }
            .mid-pic {
                div {
                    width: 148px; } } } } }


.modal-success {
    width: 450px;
    text-align: center !important;
    h2 {
        margin-bottom: 20px;
        font-size: 24px; }
    p {
        margin-bottom: 20px;
        font-size: 16px; } }
