.pro_index {
    * {
        box-sizing: border-box; }
    .fbb {
        font-weight: bold; }
    .cont {
        width: 980px;
        margin: 0 auto;
        text-align: center;
        .tit_img {
            margin-bottom: 40px; }
        hr {
            border: 0;
            border-bottom: 1px solid #C5D0D4;
            margin-bottom: 60px; }
        .slick-dots {
            li {
                margin: 0; }
            button {
                &:before {
                    width: 8px;
                    height: 8px;
                    content: '';
                    // color: $main
                    background-color: $main;
                    border-radius: 50%; } } } }

    .banner {
        height: 600px;
        margin-top: 53px;
        padding-top: 80px;
        background-image: url("/assets/img/banner@2x.png");
        background-size: cover;
        background-position-x: center;
        text-align: center;
        .cont {
            width: 1000px; }
        .title {
            font-size: 3.6rem;
            color: #38454A;
            line-height: 50px;
            margin-bottom: 26px; }
        .intro {
            font-size: 1.8rem;
            color: #A1B0B5;
            line-height: 25px;
            margin-bottom: 40px; }
        .benefit {
            font-size: 0;
            .b_cont {
                padding-bottom: 16px; }
            .li {
                display: inline-block;
                width: 240px;
                height: 290px;
                margin: 0 5px;
                padding: 35px 38px 47px;
                background-color: #FFF;
                vertical-align: top;
                box-shadow: 0 7px 20px rgba(0,0,0,.1);
                p {
                    color: #546369;
                    font-size: 1.3rem;
                    line-height: 18px; } }
            .tit {
                margin-bottom: 5px;
                font-size: 18px;
                color: #1B2326;
                line-height: 25px; }
            .icon {
                display: block;
                margin: 0 auto 22px; } } }
    .find {
        margin-top: -39px;
        padding-bottom: 70px;

        .tit {
            color: #1B2326;
            font-size: 2.4rem;
            line-height: 33px;
            margin-bottom: 12px; }
        .intro {
            color: #1B2326;
            font-size: 1.6rem;
            line-height: 22px;
            margin-bottom: 70px; }
        .datas {
            margin-right: -50px;
            margin-bottom: 80px;
            & > * {
                display: inline-block;
                width: 150px;
                height: 90px;
                padding: 10px 0;
                position: relative;
                text-align: left;
                margin-right: 50px;
                &:last-child {
                    &:after {
                        display: none; } }
                &:after {
                    content: '';
                    height: 90px;
                    width: 1px;
                    transform: rotate(30deg);
                    background-color: #C5D0D4;
                    position: absolute;
                    right: 0;
                    top: 0; } }
            .num {
                font-size: 3.6rem;
                line-height: 50px;
                margin-bottom: 5px; }
            .txt {
                font-size: 1.8rem;
                line-height: 25px; }
            .product {
                color: #E76F46; }
            .company {
                color: #84C065; }
            .organization {
                color: #8F52A8; }
            .entrepreneur {
                color: #3498DB; } }
        .btn_more {
            display: inline-block;
            padding: 0 30px;
            margin-bottom: 30px;
            background-color: #00A4A0;
            border-radius: 100px;
            line-height: 50px;
            color: #fff;
            font-size: 1.4rem; }
        .add {
            color: #A1B0B5;
            font-size: 1.4rem;
            a {
                color: #00A4A0;
                text-decoration: underline; } } }
    .get {
        padding-top: 64px;
        background-color: #F4F5F8;
        height: 885px;
        .cont {
            hr {
                margin-bottom: 112px; } }
        .get_cont_h5 {
            display: none; }
        .servers {
            margin-right: 57px;
            widows: 580px;
            text-align: left;
            li {
                margin-bottom: 40px;
                cursor: pointer;
                &.current {
                    .tit {
                        color: #3498DB; }
                    .icon {
                        border: 1px solid #3498DB; } } }
            .icon {
                text-align: center;
                width: 75px;
                height: 75px;
                padding-top: 21px;
                background-color: #FFF;
                border: 1px solid #EAEAEA;
                border-radius: 50%;
                margin-right: 20px; }
            .tit {
                font-size: 2.4rem;
                line-height: 33px;
                color: #1B2326;
                margin-bottom: 10px; }
            .intro {
                font-size: 1.4rem;
                line-height: 20px;
                color: #666666; } } }
    .learn {
        padding-top: 59px;
        padding-bottom: 80px;
        .tit {
            color: #38454A;
            font-size: 2.4rem;
            line-height: 33px;
            margin-bottom: 12px; }
        .video {
            margin: 0 auto;
            width: 940px;
            position: relative;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 8.4rem;
                margin-left: -42px;
                margin-top: -42px;
                color: #fff;
                cursor: pointer;
                z-index: 2; } }
        .wechat_group {
            padding-top: 5px;
            font-size: 0;
            li {
                margin: 0 10px 20px;
                box-shadow: 0 2px 15px rgba(0,0,0,.1);
                display: inline-block;
                display: inline-block;
                padding: 30px;
                background-color: #FFF;
                img {
                    display: block;
                    margin: 0 auto 20px; }
                .name {
                    font-size: 1.5rem;
                    line-height: 26px;
                    color: #38454A; }
                .text {
                    color: #A1B0B5;
                    font-size: 1.3rem;
                    line-height: 26px; } } }
        .roll {
            .learn_sec {
                color: #1B2326;
                font-size: 1.6rem;
                line-height: 22px;
                margin-bottom: 10px; } } }
    .big_man {
        padding: 80px 0;
        background-color: #34373A;
        .cont {
            width: 840px;
            .slick-prev {
                &:before {
                    font-family: 'icomoon_pro';
                    content: '\e90f'; } }
            .slick-next {
                &:before {
                    font-family: 'icomoon_pro';
                    content: '\e90c'; } } }
        .title {
            display: inline-block;
            padding-bottom: 10px;
            border-bottom: 4px solid #5A5A5A;
            margin-bottom: 40px;
            color: #fff;
            font-size: 2.4rem;
            line-height: 33px; }
        .say {
            width: 600px;
            margin: 0 auto;
            color: #fff;
            font-size: 1.6rem;
            line-height: 22px;
            margin-bottom: 30px; }
        .slick-dots {
            display: none !important; } }
    .big_man,.users {
        .user_cont {
            width: 160px;
            margin: 0 auto;
            height: 188px;
            overflow: hidden;
            .avatar {
                border-radius: 50%;
                display: block;
                margin: 0 auto;
                margin-bottom: 20px; }
            .name {
                font-size: 1.6rem;
                line-height: 22px;
                color: #fff;
                margin-bottom: 10px; }
            .bio {
                color: #7F8283;
                font-size: 1.3rem;
                line-height: 18px; } } }
    .users {
        background: #F4F5F8;
        padding: 80px 0 60px;
        .title {
            margin-bottom: 20px;
            font-size: 2.4rem;
            color: #38454A;
            line-height: 33px; }
        .name_sorting {
            color: #666666;
            font-size: 1.2rem;
            line-height: 22px;
            margin-bottom: 40px; }
        .cont {
            width: 1000px; }
        .users_list {
            font-size: 0;
            text-align: left;
            margin-bottom: 50px; }
        .join_more {
            margin-bottom: 80px;
            padding: 12px 40px;
            background: #E74C3C;
            display: inline-block;
            color: #fff;
            font-size: 1.4rem; }
        .user_cont {
            text-align: center;
            display: inline-block;
            margin: 0 20px;
            margin-bottom: 20px;
            .name {
                color: #38454A; }
            .bio {
                color: #809096; } }
        .contact_lifei {
            margin-bottom: 80px;
            display: block;
            font-size: 1.4rem;
            line-height: 20px;
            color: #38454A;
            text-align: left;
            list-style-type: decimal;
            list-style-position: inside;
            a {
                color: #38454A; } } }
    .bottom_banner {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        background-color: rgba(0,0,0,.8);
        height: 100px;
        .cont {
            width: 980px;
            margin: 0 auto;
            text-align: center; }
        .ie9 {
            border-radius: 25px;
            overflow: hidden;
            margin-top: 29px;
            display: inline-flex; }
        .btn_small {
            display: inline-block;
            float: none;
            border: 0;
            padding: 0 20px;
            font-size: 16px;
            line-height: 42px;
            border-radius: 25px;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FAD961', endColorstr='#F76B1C',GradientType=0 );
            background: -webkit-linear-gradient(#FAD961, #F76B1C);
            background: linear-gradient(#FAD961, #F76B1C);
            cursor: pointer;
            color: #000;
            &.t_center {
                position: absolute;
                left: 50%;
                transform: translateX(-50%); } }
        .text {
            display: inline-block;
            line-height: 100px;
            color: #fff;
            font-size: 20px;
            vertical-align: middle;
            margin-right: 30px; }
        .remind {
            display: inline-block;
            padding: 0 20px;
            line-height: 34px;
            border: 1px solid #F1C40F;
            color: #F1C40F;
            vertical-align: middle; }
        .guid_link {
            padding-top: 7px;
            display: inline-block;
            margin-left: 20px;
            a {
                color: #ebda1e;
                text-decoration: underline;
                margin-top: 10px;
                padding-top: 10px; } } } }
@media (max-width: 760px) {
    .p-header,.p-footer {}

    .pro_index {
        .m_hide {
            display: none; }
        .m_show {
            display: inherit; }
        .cont {
            width: 100% !important;
            .tit_img {
                max-width: 60%; }
            hr {
                margin-bottom: 40px; }
            .slick-dots {
                button {
                    &:before {
                        width: 6px;
                        height: 6px;
                        content: ''; } } } }
        .banner {
            background: none;
            background-color: #F4F5F8;
            padding-top: 34px;
            height: auto;
            margin-top: 0;
            overflow: hidden;
            .title {
                font-size: 2.4rem;
                line-height: 28px;
                margin-bottom: 20px;
                padding: 0 20px;
                font-weight: bold; }
            .intro {
                font-size: 1.4rem;
                margin-bottom: 30px; }
            .benefit {
                z-index: 2;
                white-space: nowrap;
                .b_cont {
                    padding-bottom: 16px;
                    background-color: #FFF;
                    overflow: scroll; } }
            .slick-list {
                overflow: visible !important; } }
        .find {
            margin-top: 40px;
            position: relative;
            z-index: 1;
            padding: 0 6%;
            padding-bottom: 40px;
            .tit {
                font-size: 1.8rem;
                line-height: 25px;
                font-weidght: bold;
                margin-bottom: 20px; }
            .intro {
                font-size: 1.4rem;
                line-height: 20px;
                margin-bottom: 40px; }
            .datas {
                margin-bottom: 50px;
                margin-right: -10px;
                & > * {
                    display: inline-block;
                    width: 60px;
                    height: 20px;
                    padding:  0;
                    position: relative;
                    text-align: left;
                    margin-right: 10px;
                    &:after {
                        height: 36px; } }
                .num {
                    font-size: 1.44rem;
                    margin-bottom: 2px;
                    line-height: 20px; }
                .txt {
                    font-size: 1.2rem;
                    line-height: 10px; } } }


        .get {
            padding-top: 40px;
            height: auto;
            .cont {
                hr {
                    margin-bottom: 30px; } }
            .server_img {
                display: none; }
            .servers {
                float: none;
                width: 100%;
                li {
                    padding: 0 6%; } }
            .get_cont {
                display: none; }
            .get_cont_h5 {
                display: block;
                .severs_h5 {
                    .slick-dots {
                        bottom: 40px; }
                    .icon {
                        text-align: center;
                        width: 76px;
                        height: 76px;
                        padding-top: 21px;
                        background-color: #FFF;
                        border: 1px solid #eaeaea;
                        border-radius: 50%;
                        display: inline-block;
                        margin-bottom: 14px;
                        img {
                            display: inline-block; } }
                    .detail {
                        .tit {
                            font-size: 2rem;
                            color: #1B2326;
                            line-height: 28px;
                            margin-bottom: 11px; }
                        .intro {
                            font-size: 1.4rem;
                            color: #666666;
                            line-height: 20px;
                            margin-bottom: 90px; } } } } }
        .learn {
            padding: 0 6%;
            overflow: hidden;
            .video {
                width: 88%;
                .icon-play {
                    display: none; } }


            .wechat_group {
                white-space: nowrap;
                overflow: scroll; }
            .roll {
                .tit {
                    margin-bottom: 12px;
                    padding-top: 40px; }
                .learn_sec {
                    font-size: 1.4rem;
                    line-height: 20px;
                    margin-bottom: 20px; } } }
        .big_man {
            padding: 40px 6%;
            .slick-prev,.slick-next {
                display: none !important; }
            .say {
                width: 100%; }
            .slick-dots {
                display: block !important; } }
        .users {
            padding: 40px 6% 96px;
            .join_more {
                margin-bottom: 40px; }
            .left_part {
                overflow: hidden; }
            .users_list {
                margin-bottom: 0; }
            .user_cont {
                width: 100%;
                height: auto;
                text-align: left;
                border-bottom: 1px solid #E6E9EA;
                &:last-child {
                    border: 0; }
                .avatar {
                    float: left;
                    width: 60px;
                    height: 60px;
                    margin-right: 20px; } } }
        .bottom_banner {
            height: 96px;
            .cont {
                text-align: center;
                .text {
                    display: none; } }
            .btn_small {
                line-height: 50px;
                float: none;
                margin: 12px auto 0;
                white-space: nowrap; }
            .guid_link {
                display: block; } } } }
