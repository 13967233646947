.p-search {
    width: 580px;
    margin: 0 auto;
    .load-more {
        margin-bottom: 40px; }
    .input-part {
        margin-top: 100px;
        padding-bottom: 50px;
        border-bottom: 1px solid #D8D8D8;
        input {
            border: none;
            color: #C0C0C0;
            line-height: 49px;
            @include fs(36);
            width: 100%; } }
    .result-part {
        min-height: 300px;
        .tit {
            padding-top: 20px;
            border-top: 1px solid #F2F2F2;
            line-height: 22px;
            @include fs(16);
            color: #808080; }
        .author-info .pos {
            width: auto; }
        .part {
            &:first-child {
                .tit {
                    border-top: none; } }
            li {
                border-bottom: 1px #f2f2f2 solid;
                em {
                    color: $orange !important;
                    font-style: normal; }
                &:last-child {
                    border-bottom: none; }
                .author-info {
                    overflow: hidden; }
                .author-avatar {
                    img {
                        @include rounded(50%); }
                    .txt {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        line-height: 38px;
                        background-color: $main_blue;
                        border-radius: 50%;
                        text-align: center;
                        color: #fff;
                        font-size: 18px; } }
                .author-name {
                    @include fs(24);
                    line-height: 34px;
                    color: #333; }
                .product-name {
                    color: $black;
                    @include fs(24);
                    line-height: 33px;
                    margin-bottom: 15px;
                    display: inline-block; }
                .author-cont {
                    display: block;
                    width: auto; }
                .pic-product {
                    margin-right: 30px; }
                .pic {
                    margin-right: 30px; }
                .pos {
                    @include fs(16);
                    height: 24px;
                    line-height: 24px;
                    color: #808080;
                    padding: 0;
                    width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                .intro {
                    @include fs(16);
                    line-height: 24px;
                    color: #808080;
                    padding: 0; }
                .cont {
                    width: 100%; } }
            .see-all {
                margin-bottom: 50px;
                a {
                    color: $orange;
                    &:hover {
                        color: $orange-hover; } } }
            .investment-li {
                padding: 30px 0;
                border-bottom: 1px solid #F2F2F2;
                .left {
                    width: 450px;
                    @extend .border-box;
                    padding-right: 30px;
                    .author-info {
                        padding: 0; }
                    .intro {
                        margin-bottom: 15px; }
                    .txt {
                        @include fs(13);
                        line-height: 15px;
                        color: #B3B3B3; } }
                .right {
                    width: 130px;
                    @extend .border-box;
                    padding: 35px 0 35px 0px;
                    text-align: center;
                    border-left: 1px solid rgba(216, 216, 216, 0.5);
                    color: #808080;
                    .coins {
                        @include fs(24);
                        line-height: 27px;
                        margin-bottom: 14px; }
                    .already {
                        @include fs(14);
                        line-height: 14px;
                        margin-bottom: 21px; } } }
            .novel-li {
                position: relative;
                ul {
                    margin-bottom: 0; }
                li {
                    border: none;
                    margin-bottom: 12px;
                    line-height: 24px;
                    .title {
                        display: inline-block;
                        color: #5187B2;
                        @include fs(16);
                        margin-right: 22px;
                        a {
                            color: #5187B2; } }
                    .time {
                        @include fs(14);
                        color: #94A0B0;
                        line-height: 24px; } }
                .intro {
                    margin-bottom: 20px; }
                .author-name {
                    display: inline-block;
                    margin-bottom: 15px;
                    a {
                        color: #000; } }
                .see-more {
                    display: inline-block;
                    position: absolute;
                    @include fs(14);
                    bottom: 42px;
                    right: 0;
                    color: #5187B2;
                    line-height: 26px;
                    border: 1px solid #F2F2F2;
                    @include rounded(2px);
                    padding: 0 5px; }
                .name,.txt {
                    @include fs(16);
                    line-height: 20px;
                    color: #808080; }
                .txt {
                    color: #b3b3b3; }
                .line {
                    color: #d8d8d8;
                    margin: 0 5px; } }
            .nictation-list {
                li {
                    padding: 30px 0;
                    .title {
                        @include fs(24);
                        line-height: 33px;
                        margin-bottom: 15px;
                        a {
                            color: #333333; } }
                    .main {
                        @include fs(16);
                        line-height: 24px;
                        color: #808080;
                        margin-bottom: 15px;
                        a {
                            color: #808080; }
                        a.orange {
                            color: $orange; } }
                    .info {
                        color: #808080;
                        line-height: 24px;
                        @include fs(14); } } } } } }


.result-list {
    .investment-li {
        padding: 30px 0;
        border-bottom: 1px solid #F2F2F2;
        .left {
            width: 450px;
            @extend .border-box;
            padding-right: 30px;
            .author-info {
                padding: 0; }
            .intro {
                margin-bottom: 15px; }
            .txt {
                @include fs(13);
                line-height: 15px;
                color: #B3B3B3; } }
        .right {
            width: 130px;
            @extend .border-box;
            padding: 35px 0 35px 0px;
            text-align: center;
            border-left: 1px solid rgba(216, 216, 216, 0.5);
            color: #808080;
            .coins {
                @include fs(24);
                line-height: 27px;
                margin-bottom: 14px; }
            .already {
                @include fs(14);
                line-height: 14px;
                margin-bottom: 21px; } } }
    .novel-li {
        position: relative;
        ul {
            margin-bottom: 0; }
        li {
            border: none;
            margin-bottom: 12px;
            line-height: 24px;
            .title {
                display: inline-block;
                color: #5187B2;
                @include fs(16);
                margin-right: 22px;
                a {
                    color: #5187B2; } }
            .time {
                @include fs(14);
                color: #94A0B0;
                line-height: 24px; } }
        .intro {
            margin-bottom: 20px; }
        .author-name {
            display: inline-block;
            margin-bottom: 15px;
            color: #333;
            a {
                color: #000; } }
        .see-more {
            display: inline-block;
            position: absolute;
            @include fs(14);
            bottom: 42px;
            right: 0;
            color: #5187B2;
            line-height: 26px;
            border: 1px solid #F2F2F2;
            @include rounded(2px);
            padding: 0 5px; }
        .name,.txt {
            @include fs(16);
            line-height: 20px;
            color: #808080; }
        .txt {
            color: #b3b3b3; }
        .line {
            color: #d8d8d8;
            margin: 0 5px; } }
    .nictation-li {
        padding: 30px 0;
        .title {
            @include fs(24);
            line-height: 33px;
            margin-bottom: 15px;
            a {
                color: #333333; } }
        .main {
            @include fs(16);
            line-height: 24px;
            color: #808080;
            margin-bottom: 15px;
            a {
                color: #808080; }
            a.orange {
                color: $orange; } }
        .info {
            color: #808080;
            line-height: 24px;
            @include fs(14); } } }

















