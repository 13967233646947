.event-post-list {
    padding-top: 151px;
    h1.title {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 12px;
        @include fs(24);
        font-weight: bold; }
    .bio {
        padding-bottom: 50px;
        border-bottom: 1px solid #E5E5E5; }
    .mod-article-list {
        .cont {
            float: right;
            .intro {
                color: #808080; } }
        .category {
            margin-bottom: 15px;
            span {
                display: inline-block;
                *display: inline;
                padding: 0 5px;
                line-height: 17px;
                @include fs(12);
                background-color: $main;
                color: #fff; } }
        .pic {
            left: 0;
            margin-left: 0;
            position: static;
            float: left;
            @include transformY(0); } } }
.event-detail {
    padding-top: 161px;
    article {
        h1 {
            margin-bottom: 30px; } }
    .more {
        a {
            color: $main;
            text-decoration: underline; } }
    .article-list {
        font-size: 0; }
    .related_articles {
        margin-bottom: 50px; }

    .event-info {
        .pic {
            img {
                margin: 0 !important; } }
        .panel {
            background-color: #F6F6F6;
            padding: 30px;
            margin-bottom: 27px;
            p {
                @include fs(14);
                line-height: 21px;
                margin-bottom: 0; }
            .summary {
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #D8D8D8; }
            .info {
                position: relative;
                p {
                    line-height: 28px;
                    .title {
                        font-weight: bold; }
                    .word {
                        color: $main !important; } }
                .btn {
                    position: absolute;
                    top: 0;
                    right: 20px;
                    display: block;
                    width: 86px;
                    height: 36px;
                    background-color: #178CF2;
                    line-height: 36px;
                    @include fs(14);
                    color: #FFFFFF !important;
                    text-decoration: none !important;
                    border-radius: 0;
                    cursor: pointer;
                    &.disabled {
                        background-color: #999999; } } } } } }

.page-cont {
    &.gray {
        background-color: #F5F5F5; } }
.event-container {
    width: 100%;
    margin: 0 auto;
    padding-top: 61px;
    //分页样式
    .pagination_part {
        background-color: #FFF;
        .pagination {
            padding-bottom: 40px;
            border-top: 0; } }
    .top-events {
        width: 100%;
        margin-top: 92px;
        margin-bottom: 0;
        padding-top: 31px;
        .slick-dots {
            bottom: inherit;
            top: 355px;
            li {
                width: 10px;
                button {
                    &:before {
                        content: ".";
                        font-size: 60px; }
                    &:hover:before, &:focus:before {
                        color: #D93641;
                        opacity: 1; } }
                &.slick-active {
                    button {
                        &:before {
                            color: #D93641;
                            opacity: 1; } } } } }
        .arrow-prev, .arrow-next {
            line-height: 0;
            position: absolute;
            top: 40%;
            padding: 0;
            cursor: pointer;
            z-index: 2;
            transition: transform .6s;
            -webkit-transition: -webkit-transform .6s;
            i {
                color: #fff; } }
        .arrow-prev {
            font-size: 45px;
            left: 0;
            background-position: 0 0;
            transform: translate(-43px,0);
            -webkit-transform: translate(-43px,0); }
        .arrow-next {
            right: 0;
            background-position: -43px 0;
            transform: translate(43px,0);
            -webkit-transform: translate(43px,0);
            i {
                display: block;
                font-size: 45px; } }
        &:hover {
            .arrow-prev {
                left: 1%; }
            .arrow-next {
                right: 1%; }
            &>a {
                transform: translate(0,0);
                -webkit-transform: translate(0,0); } } }
    .top-event {
        width: 100%;
        img {
            width: 100%;
            height: auto;
            margin-bottom: 30px; }
        .hidden-title {
            display: none; }
        .info {
            margin-bottom: 23px;
            font-size: 14px;
            font-size: 1.4rem;
            .line {
                color: #d7d7d7;
                margin: 0 10px; } }
        .intro {
            font-size: 14px;
            font-size: 1.4rem;
            color: #808080;
            line-height: 21px;
            margin-bottom: 30px; } }
    .event-notice, .event-ended {
        max-width: 980px;
        margin: 0 auto; }
    .event-notice {
        margin-bottom: 35px;
        .mod-tit {
            padding-bottom: 20px;
            h2 {
                &.title {
                    font-size: 16px;
                    font-size: 1.6rem;
                    line-height: 18px; } } }
        ul {
            &.event-list {
                li {
                    border-top: 1px solid #E5E5E5;
                    padding: 15px 0;
                    position: relative;
                    &.time {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        display: inline-block;
                        *display: inline;
                        height: 50px;
                        width: 50px;
                        line-height: 50px;
                        background-color: #F4F4F4;
                        text-align: center;
                        border-radius: 50%;
                        color: #D93641;
                        vertical-align: top; }
                    .current {
                        background-color: #D93641;
                        color: #fff; }
                    .events {
                        margin-left: 70px;
                        overflow: hidden;
                        display: inline-block;
                        *display: inline;
                        .title {
                            line-height: 50px;
                            a {
                                color: #333; } } } } } } }
    .event-ended {
        .mod-tit {
            padding-bottom: 20px;
            h2 {
                &.title {
                    font-size: 16px;
                    font-size: 1.6rem;
                    line-height: 18px; } } } }


    .event-inform {
        max-width: 1120px;
        height: 61px;
        overflow: hidden;
        margin: 0 auto;
        background-color: #fff;
        .BreakingNewsController {
            padding: 10px 0;
            .inform-td {
                width: 36px;
                height: 36px;
                background-color: #D93641;
                line-height: 15px;
                text-align: center;
                letter-spacing: 1px;
                font-size: 13px;
                color: #fff;
                padding: 5px 0 0 2px;
                margin-left: 10px; }
            ul {
                color: #d93641;
                li {
                    height: auto;
                    overflow: hidden;
                    span {
                        display: inline-block;
                        float: left;
                        height: auto !important;
                        font-size: 13px;
                        height: auto;
                        line-height: 22px;
                        width: 50%;
                        overflow: hidden;
                        text-overflow: clip;
                        -o-text-overflow: clip;
                        white-space: nowrap; }
                    a.post_a {
                        margin-right: 40px;
                        max-width: 360px; }
                    h3 {
                        display: inline-block;
                        color: #d93641; }
                    h4 {
                        display: inline-block;
                        color: #333333;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -o-text-overflow: ellipsis;
                        white-space: nowrap; } } } }
        .more {
            display: inline-block;
            float: right;
            margin-right: 20px;
            color: #b4b4b4;
            position: relative;
            top: -37px; } }
    .event-block {
        width: 1120px;
        margin: 0 auto;
        margin-top: 30px;
        .event-tit {
            margin-bottom: 20px;
            a {
                display: inline-block;
                font-size: 14px;
                color: #424242;
                &:first-child {
                    padding-right: 20px; }
                &:last-child {
                    padding-left: 20px; }
                &.on {
                    color: #d93641; } } }
        .event-list {
            height: 0;
            overflow: hidden;
            &.on {
                height: auto; } }
        ul {
            padding: 0;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between {
                -ms-flex-pack: justify {
                    justify-content: space-between; } }
            -webkit-box-align: center;
            -webkit-align-items: center {
                -ms-flex-align: center {
                    align-items: center; } }
            -webkit-flex-wrap: wrap {
                -ms-flex-wrap: wrap {
                    flex-wrap: wrap; } }
            li {
                width: 48.95833%;
                background-color: white;
                display: inline-block;
                margin-bottom: 20px;
                overflow: hidden;
                &.full-width {
                    width: 100%;
                    .events {
                        padding: 3.125%; } } }
            .events {
                padding: 6.38298%;
                h2 {
                    color: #333C4E;
                    margin-top: 0;
                    width: 80%;
                    font-size: 17px;
                    font-size: 1.7rem;
                    display: inline-block;
                    a {
                        color: #333C4E;
                        display: inline-block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -o-text-overflow: ellipsis;
                        white-space: nowrap; } }
                i {
                    display: block;
                    font-style: normal;
                    padding: 5px 10px;
                    color: #fff;
                    float: right;
                    &.soon {
                        background-color: #2BBC49; }
                    &.quick {
                        background-color: #008af5; }
                    &.now {
                        background-color: #d93641; }
                    &.over {
                        background-color: #cccccc; } }
                hr {
                    border: none;
                    border-bottom: 1px solid #EEEEEE;
                    width: 100%;
                    height: 2px;
                    margin-top: 27px; }
                .bottom-block {
                    margin-top: 15px;
                    overflow: auto;
                    p {
                        color: #828891;
                        font-size: 13px;
                        font-size: 1.3rem;
                        margin: 0;
                        line-height: 20px;
                        &:first-child {
                            font-weight: bold;
                            color: #333C4E;
                            span {
                                color: #d93641; } }
                        &:last-child {
                            margin-top: 10px;
                            span {
                                float: right; } }
                        span {
                            float: none;
                            margin: 0 5px;
                            color: #828891; } }
                    span,time {
                        color: #828891;
                        font-size: 13px;
                        font-size: 1.3rem;
                        float: right;
                        line-height: 20px; }
                    a {
                        color: #4990e2; } } } }
        .reminder {
            height: 300px;
            line-height: 300px; } }

    .event-part {
        width: 100%;
        margin-top: 55px;
        font-size: 14px;
        text-align: center;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        .part-bg {
            img {
                width: 100%;
                background-color: #000;
                @include blur(10); } }
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; }
        .opacity {
            opacity: 0.6;
            filter: alpha(opacity=60);
            background-color: #000; }
        .part-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 640px;
            margin: 0 auto;
            z-index: 1;
            .part-logo {
                margin-bottom: 40px;
                img {
                    width: 84px;
                    height: 84px;
                    border: 2px solid #fbfbfb;
                    border-radius: 50%;
                    overflow: hidden; } }
            span {
                color: #fff; } } } }



.event-container .event-ended {
    margin-bottom: 100px;
    .mod-tit {
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 28px; }
    .event-list li {
        display: inline-block;
        *display: inline;
        width: 348px;
        margin-bottom: 20px; }
    .title {
        color: #D93641;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 18px;
        margin-bottom: 4px; }
    .time {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 18px;
        color: #B3B3B3; } }

.qrcode_sidebar {
    position: fixed;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    width: 170px;
    background-color: #FFFFFF;
    padding: 20px 15px;
    border: 1px solid rgba(25,164,159,0.30);
    box-shadow: 0 0 6px 0 rgba(25,164,159,0.30);
    .close-btn {
        position: absolute;
        top: -18px;
        right: -18px;
        border: 1px solid rgba(25,164,159,0.50);
        box-shadow: 0 0 4px 0 rgba(25,164,159,0.30);
        display: inline-block;
        width: 36px;
        height: 36px;
        background-color: #FFFFFF;
        text-align: center;
        border-radius: 50%;
        i {
            line-height: 38px;
            font-size: 18px;
            color: #19A49F; } }
    img {
        display: block;
        margin-bottom: 10px; }
    p {
        line-height: 20px;
        font-size: 14px;
        color: #333333; }
    a {
        display: block;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(51,51,51,0.20);
        font-size: 14px;
        color: #19A49F; } }

.pop_up {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-bottom: 40px;
    background-color: rgba(255,255,255,0.98);
    overflow: scroll;
    z-index: 11; }
.qrcode_pop {
    .pop_cont {
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 384px;
        padding: 0;
        text-align: center; }
    .tit {
        margin-top: 50px;
        color: #546369;
        margin-bottom: 30px; }
    .txt {
        color: #38454A;
        line-height: 22px;
        margin-bottom: 6px; }
    .name {
        width: 200px;
        margin: 0 auto;
        line-height: 46px;
        color: #38454A;
        font-size: 2.4rem;
        margin-bottom: 30px;
        background: #fff; }
    .qrcode {
        width: 160px;
        height: 160px;
        margin-bottom: 30px; }
    .remind {
        color: #38454A;
        font-size: 1.8rem;
        line-height: 28px;
        .use_na {
            color: #00A4A0; } }
    .pro {
        margin-top: 10px;
        a {
            display: inline-block;
            padding-top: 5px;
            border-top: 1px solid rgba(51,51,51,0.20);
            font-size: 14px;
            color: #19A49F; } }
    .btn {
        width: auto;
        height: auto;
        display: inline-block;
        padding: 0 15px;
        border: 1px solid transparent;
        border-radius: 3px;
        line-height: 33px;
        font-size: 1.2rem;
        color: #fff;
        background-color: #00A4A0;
        cursor: pointer;
        outline: none; }
    .kown_btn {
        margin-top: 50px;
        margin-bottom: 80px;
        .btn {
            font-size: 1.3rem;
            color: #00A4A0;
            background: #fff;
            border: 1px solid #00A4A0;
            border-radius: 3px;
            padding: 10px 30px;
            line-height: 16px;
            &:hover {
                color: #fff;
                background: #00A4A0; } } } }















