.app-down-container {
    position: relative;
    z-index: 11;
    .section {
        text-align: center;
        position: relative;
        height: 100%;
        background-color: #F9F9F9;
        background-size: cover;
        background-repeat: no-repeat;
        .cont {
            width: 840px;
            margin: 0 auto;
            // padding: 0 300px
            overflow: hidden; }
        .left,.right {
            display: inline-block;
            *display: inline; }
        .left {
            float: left;
 }            // margin-right: 100px
        .right {
            float: right; }
        .intro-text {
            // width: 362px
            margin-top: 100px;
            text-align: left;
            .top {
                margin-bottom: 28px; }
            h3 {
                display: block;
                *display: inline;
                margin-right: 25px;
                margin-bottom: 10px;
                line-height: 44px;
                // @include fs(36)
                font-size: 36px;
                color: #333333;
                font-weight: bold; }
            .tit-img {
                display: block;
                margin-bottom: 20px; }
            .tit-bar {
                display: block;
                width: 60px;
                height: 6px; }
            .list {
                padding-left: 20px;
                li {
                    list-style: disc;
                    // @include fs(18)
                    font-size: 18px;
                    color: #6C6C6C;
                    line-height: 30px;
                    font-weight: bold; } }
            img {
                vertical-align: top; }
            .circle {
                display: inline-block;
                *display: inline;
                width: 48px;
                height: 48px;
                text-align: center;
                line-height: 50px;
                vertical-align: top;
                background-color: #FFF;
                @include rounded(50%);
                border: 1px solid #E5E5E5;
                color: #A0A5A9;
                @include fs(20); }
            .intro {
                color: #666666;
                @include fs(18);
                line-height: 28px; } }
        .app-img {
            img {
                width: 285px;
                height: auto; } }
        .bottom {
            position: absolute;
            bottom: 0;
            left: 50%;
            // transform: translateX(-50%)
            @include transformX(-50%); }
        .icons {
            margin-bottom: 60px;
            a {
                margin: 0 10px;
                color: #02A4A0;
                opacity: 0.5; }
            i {
                @include fs(24); } } }

    .bg-whiter {
        background-color: #fff; }
    .page-1 {
        position: relative;
        background-color: #FFFFFF;
        padding-top: 50px;
        .cont {
            width: 1160px; }
        .apps-tit {
            font-size: 0;
            img {
                width: 535px;
                height: 106px; } }
        .back-btn {
            position: absolute;
            top: 40px;
            right: 60px;
            width: 120px;
            height: 32px;
            border: 1px solid #02A4A0;
            border-radius: 100px;
            a {
                display: inline-block;
                line-height: 33px;
                @include fs(16);
                color: #02A4A0;
                font-weight: bold; } }
        .side-left {
            position: absolute;
            top: 50%;
            left: 100px;
            transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            text-align: center;
            z-index: 13;
            .apps_logo {
                width: 113px;
                height: 113px;
                margin-bottom: 30px; }
            .intro {
                margin-bottom: 10px;
                @include fs(24);
                color: #333333;
                font-weight: bold;
                span {
                    color: #00A4A1; } }
            .noway {
                width: 141px;
 } }                // height: 31px
        .exhibition-72, .exhibition-home {
            display: inline-block;
            position: absolute;
            .list {
                position: absolute;
                background-color: #FFFFFF;
                box-shadow: 0 0 6px 0 rgba(2,164,160,0.60);
                border-radius: 4px;
                padding: 15px 40px;
                li {
                    position: relative;
                    margin-bottom: 12px;
                    @include fs(16);
                    color: #333333;
                    text-align: left;
                    white-space: nowrap;
                    font-weight: bold;
                    &:last-of-type {
                        margin-bottom: 0; }
                    .dot {
                        position: absolute;
                        top: 50%;
                        left: -14px;
                        transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        margin-right: 10px;
                        background-color: #02A4A0;
                        border-radius: 50%;
                        vertical-align: middle; }
                    span {
                        color: #00A4A1; } } } }
        .exhibition-72 {
            display: inline-block;
            position: absolute;
            top: 245px;
            left: 418px;
            img {
                width: 378px;
                height: 482px; }
            .list {
                left: -13px;
                bottom: 135px;
                box-shadow: 0 0 6px 0 rgba(2,164,160,0.60); } }
        .exhibition-home {
            display: inline-block;
            position: absolute;
            top: 122px;
            right: 266px;
            img {
                width: 327px;
                height: 558px; }
            .list {
                top: 133px;
                left: 165px;
                box-shadow: 0 0 6px 0 rgba(0,164,161,0.60); } }
        .question {
            position: absolute;
            left: 60px;
            bottom: 85px;
            img {
                width: 133px;
                height: 96px; } }
        .version-box {
            font-size: 0;
            position: absolute;
            right: 60px;
            bottom: 90px;
            .iphone, .android {
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                img {
                    height: 40px; } }
            .download-qrcode {
                display: inline-block;
                background-color: #FFFFFF;
                vertical-align: top;
                cursor: pointer;
                img {
                    height: 40px; } } }

        .fp-tableCell {
            vertical-align: top; }
        h2 {
            color: #FFFFFF;
            @include fs(60);
            line-height: 62px;
            margin-bottom: 20px; }
        h3 {
            color: #fff;
            @include fs(24);
            line-height: 25px;
            margin-bottom: 35px; }
        .buttons {
            margin-bottom: 50px;
            .btn {
                //margin: 0 15px
                margin: 15px 0;
                color: #fff;
                @include fs(18);
                border-color: #fff;
                display: block;
                i {
                    margin-right: 10px; }
                &:hover {
                    background-color: #fff;
                    color: #21B890; } }
            .icon-iphone,.icon-ipad {
                position: relative;
                top: 1px; } }

        .bottom {
            position: static;
            transform: translateX(0);
            img {
                margin: 0 auto;
                width: 540px;
                height: auto;
                margin-bottom: -80%; } }
        .qrcode {
            display: inline-block;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-right: 15px;
            *display: inline;
            width: 130px;
            padding: 20px;
            background-color: #FFF;
            @include rounded(19px);
            h4 {
                @include fs(18);
                line-height: 19px;
                margin-bottom: 11px; } } }
    .page-2 {
        background-image: url('../css/img/app_down/apps_bg1.png'); }
    .page-3 {
        background-image: url('../css/img/app_down/apps_bg2.png'); }
    .page-4 {
        background-image: url('../css/img/app_down/apps_bg3.png'); }
    .page-5 {
        background-image: url('../css/img/app_down/apps_bg4.png'); } }

@keyframes bg_zoom {

    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }
@-webkit-keyframes bg_zoom {

    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }
@-moz-keyframes bg_zoom {
    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }
@-o-keyframes bg_zoom {

    from {
        // background-size: 100%
        transform: scale(1);
        filter : blur(3px); }
    50% {
        filter : blur(0px); }
    to {
        // background-size: 150%
        transform: scale(1.9);
        filter : blur(0px); } }


@keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
@-webkit-keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
@-moz-keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
@-o-keyframes shift_in {
    from {
        background-position: 0% center; }
    to {
        background-position: 60% center; } }
// .m-app-down
//     background-color: #FFFFFF
//     max-width: 750px
//     margin: 0 auto
//     position: relative
//     overflow: hidden
//     // height: 100%
//     .bg_img
//         width: 100%
//         height: 100%
//         height: 100vh
//         top: 0
//         left: 0
//         position: absolute
//         background-image: url("/public/css/img/app_down/h5-bg.jpg")
//         background-position: center
//         background-size: cover
//         z-index: 1
//         filter : blur(0px)
//         transform: scale(1.9)
//         animation: bg_zoom 15s linear

//     .bg_img1
//         width: 100%
//         height: 100%
//         height: 100vh
//         top: 0
//         left: 0
//         position: absolute
//         background-image: url("/public/css/img/app_down/h5-bg1.jpg")
//         background-position: center
//         background-size: cover
//         z-index: 1

//         // background-size: 150%
//         animation: shift_in 20s linear infinite

//     .page
//         position: relative
//         z-index: 2
//         width: 100%
//         height: 100%
//         height: 100vh
//         text-align: center
//         color: #fff
//         display: table
//         a
//             color: #fff
//     .cont
//         display: table-cell
//         vertical-align: middle
//     img.logo
//         margin-bottom: 30px
//         text-align: center
//     h2
//         @include fs(25)
//         line-height: 25px
//         letter-spacing: 8px
//         color: #fff
//         font-weight: bold
//         text-shadow: 0 2px 0 rgba(0,0,0,.5)
//         margin-bottom: 17px
//     h3
//         @include fs(12)
//         line-height: 15px
//         margin-bottom: 50px
//         &:before,&:after
//             content: ''
//             display: inline-block
//             height: 1px
//             width: 10px
//             background-color: #FFF
//             vertical-align: middle
//             margin: 0 10px
//     .btn
//         display: block
//         margin: 0 auto
//         margin-bottom: 20px
//         font-weight: bold





// @media (max-width: 768px)
.m-app-down {
    background-color: #FFFFFF;
    max-width: 750px;
    margin: 0 auto;
    .section {
        position: relative;
        height: 100vh;
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;
        .apps-logo {
            padding-top: 5%;
            text-align: center; }
        .apps-tit {
            margin-top: 25px;
            padding: 0 39px;
            text-align: center;
            .tit-detail {
                margin-top: 15px;
                .tit-icon {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }
                p {
                    display: inline-block;
                    line-height: 22px;
                    @include fs(18);
                    color: #333333;
                    font-weight: bold; } }
            .tit-intro {
                margin-top: 10px; } }

        .arrow {
            // position: absolute
            bottom: 20px;
            // left: 50%
            // transform: translateX(-50%)
            // -o-transform: translateX(-50%)
            // -ms-transform: translateX(-50%)
            // -moz-transform: translateX(-50%)
            // -webkit-transform: translateX(-50%)
            width: 18px;
            height: 15px;
            background-image: url('../css/img/app_down/arrow_down_m.png');
            background-size: 100%;
            background-repeat: no-repeat;
            border: none;
            // margin-left: 0
            display: none; }
        .app-img {
            font-size: 0;
            position: relative;
            margin-top: 15px;
            text-align: center;
            .app-intro {
                margin-top: 25px;
                @include fs(24);
                color: #FFFFFF;
                font-weight: bold; }
            .app-info {
                margin-top: 10px;
                line-height: 17px;
                @include fs(13);
                color: #FFFFFF; } }
        .app-cont {
            margin-top: 30px;
            text-align: center;
            li {
                position: relative;
                margin-top: 20px;
                &:first-of-type {
                    margin-top: 0; }
                p {
                    position: absolute;
                    top: 50%;
                    left: 150px;
                    transform: translateY(-50%);
                    transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    max-width: 160px;
                    line-height: 20px;
                    text-align: left;
                    @include fs(12);
                    color: #6C6C6C; } } }

        .chaindd-logo {
            display: block;
            margin: 0 auto;
            margin-top: 98px; }
        .chaindd-txt {
            display: block;
            margin: 0 auto;
            margin-top: 50px; }
        .chaindd-intro {
            margin-top: 40px;
            @include fs(24);
            color: #5C636D;
            font-weight: bold; }
        .chaindd-info {
            margin-top: 15px;
            line-height: 17px;
            @include fs(13);
            color: #5C636D; }
        .download-box {
            position: absolute;
            left: 50%;
            bottom: 25px;
            transform: translateX(-50%);
            -o-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            .download-btn {
                display: block;
                width: 120px;
                height: 32px;
                // padding: 5px 20px
                background-color: #FD8216;
                box-shadow: 0 0 4px 0 #FD8216;
                border-radius: 2px;
                border: none;
                text-align: center;
                line-height: 32px;
                &.ios {
                    margin-top: 10px; }
                &.disabled {
                    background-color: #C0C0C0;
                    box-shadow: none; } }
            .download-tip {
                margin-top: 5px;
                @include fs(7);
                color: #5C636D; } } }


    .page-1 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211183911172.png'); }
    .page-2 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211142057441.png');
        // .app-img
 }        //     margin-top: 30px
    .page-3 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211183926795.png');
        // .app-img
 }        //     margin-top: 36px
    .page-4 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211142145289.png');
        // .app-img
 }        //     margin-top: 30px
    .page-5 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211142158623.png');
        // .app-img
 }        //     margin-top: 28px
    .page-6 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211142208294.png'); }
    .page-7 {
        background-image: url('http://images.tmtpost.com/uploads/images/2018/02/20180211183948878.png'); }
    .words {
        width: 300px;
        margin: 20px auto; }
    .get_v1 {
        margin-bottom: 10px;
        a {
            display: none;
            line-height: 20px;
            padding: 0 15px;
            background-color: #fff;
            color: $orange;
            border-radius: 10px; } }
    .w_right {
        p {
            color: #fff; } } }

@media (max-width: 768px) {
    .m-app-down {
        .words {
            text-align: center;
            br {
                display: none; } }
        .w_right {
            display: none; }
        .section .arrow {
            display: inline-block; }

        .get_v1 {
            a {
                display: inline-block; } } } }














