.audio_share_pc,.audio_detail_pc {
    .test_box {
        padding: 80px 0;
        display: flex;
        width: 1020px;
        margin: 0 auto;
        flex-direction: column;
        .top_banner {
            display: flex;
            box-shadow: 0 0 6px 0 rgba(253,138,37,0.40);
            position: relative;
            .banner_img {
                width: 700px;
                height: 360px; }
            .pop_buy {
                background: rgba(0,0,0,0.60);
                padding: 10px 20px;
                border-radius: 5px;
                position: absolute;
                right: 0;
                margin-right: -100px;
                top: -56px;
                z-index: 10;
                span {
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 17px; }
                .three {
                    border-top: 10px solid rgba(0,0,0,0.60);
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    width: 0;
                    position: absolute;
                    bottom: -10px;
                    right: 50%;
                    margin-right: -10px; } } }
        .buy_code {
            padding: 0 20px;
            text-align: center;
            position: relative;
            .real_price {
                margin: 40px 0;
                color: #333333;
                font-size: 14px;
                display: flex;
                justify-content: center;
                .red {
                    color: #FC1261;
                    font-size: 36px; }
                .putong {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    img {
                        width: 46px;
                        height: 14px; }
                    span {
                        text-decoration: line-through; }
 } }                    // tex
            .buy_img {
                cursor: pointer;
                width: 68px;
                height: 68px;
                position: absolute;
                right: 0;
                top: 0; } }
        .code_img {
            display: flex;
            img,canvas {
                width: 140px;
                height: 140px;
                margin-right: 10px; }
            .qrcode {
                margin-right: 10px; }
            .introduction {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                p {
                    text-align: left;
                    color: #333333;
                    font-size: 14px;
                    line-height: 20px; }
                .red {
                    font-size: 18px;
                    line-height: 20px;
                    color: #FC1261; } } }
        .share_box {
            position: relative;
            padding: 15px 0;
            margin-top: 25px;
            border-top: 1px dashed $main;
            display: flex;
            justify-content: space-between;
            .shares {
                display: flex;
                .circle-wechat {
                    background: #50B674;
                    .icon-weixin {
                        top: 8px;
                        left: 8px; } }
                .circle-weibo {
                    background: #EA5D5C;
                    .icon-weibo {
                        top: 8px;
                        left: 8px; } }
                .circle-tencent {
                    background: #5EAADE;
                    .icon-tencent {
                        top: 8px;
                        left: 11px; } }
                a {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                    position: relative;
                    .icon-weixin,.icon-tencent,.icon-weibo {
                        position: absolute;
                        color: #ffffff; } } }
            .play {
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px; }
                span {
                    font-size: 14px;
                    color: $main; } }
            .share_qcode {
                position: absolute;
                bottom: 62px;
                margin-left: -50px;
                width: 88px;
                padding: 16px 16px 10px;
                background: #fff;
                border: 1px solid rgba(26,53,71,0.12);
                border-radius: 4px;
                box-shadow: 0 1px 2px rgba(26,53,71,0.1);
                transition: 0.25s cubic-bezier(0.3, 0, 0, 1.3);
                &:before {
                    content: "";
                    position: absolute;
                    right: -webkit-calc(50% - 9px);
                    width: 13px;
                    height: 7px;
                    z-index: 4;
                    background: url(/public/css/img/dropdown-menu-arrow.svg);
                    top: initial;
                    bottom: -7px;
                    transform: rotate(180deg); }
 } }                // .wx_qrcode
        .code72 {
            width: 320px;
            .real_price {
                margin: 20px auto 10px; }
            .code_img {
                flex-direction: column;
                align-items: center;
                .qrcode,canvas {
                    width: 140px;
                    height: 140px;
                    margin-right: 0; }
                .introduction {
                    margin-top: 15px; } }
            .share_box {
                margin-top: 15px; } }
        .content_box {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .content_left {
                display: flex;
                flex-direction: column;
                margin-right: 60px;
                section {
                    margin-top: 30px;
                    .tit {
                        display: flex;
                        padding-bottom: 20px;
                        border-bottom: 1px solid #E5E5E5;
                        margin-bottom: 15px;
                        width: 640px; }
                    .left_green {
                        width: 4px;
                        height: 24px;
                        display: inline-block;
                        margin-right: 10px;
                        background: $main; }
                    .tit_text {
                        font-size: 20px;
                        color: $main;
                        line-height: 22px; }
                    .text {
                        font-size: 16px;
                        color: #333333;
                        line-height: 28px;
                        margin-bottom: 10px;
                        text-align: left;
                        p,a {
                            font-size: 16px;
                            color: #333333;
                            line-height: 28px;
                            margin-bottom: 10px;
                            text-align: left; }
                        img {
                            width: 100%;
                            height: auto; }
                        a {
                            color: $main; } } }
                .bg_class {
                    position: relative;
                    .li_pop_buy {
                        background: rgba(0,0,0,0.60);
                        padding: 16px 52px;
                        border-radius: 10px;
                        position: absolute;
                        border: 1px solid #000000;
                        left: 50%;
                        margin-left: -213px;
                        top: 50%;
                        z-index: 10;
                        span {
                            font-size: 20px;
                            color: #FFFFFF;
                            line-height: 17px; } }
                    .tit {
                        padding: 20px 0;
                        background-color: rgba(253,138,37,0.1);
                        border: none; }
                    .tit72 {
                        padding: 20px 0;
                        display: flex;
                        border-bottom: 1px solid rgba(253,138,37,0.3); }
                    .text {
                        width: 640px;
                        li {
                            color: #666666;
                            font-size: 18px;
                            line-height: 30px;
                            padding: 20px 0;
                            border-bottom: 1px solid #E5E5E5;
                            i {
                                color: rgba(253,138,37,0.6);
                                font-weight: bold;
                                font-size: 15px; }
                            a {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                color: #333333;
                                span {
                                    color: #333333;
                                    font-size: 18px;
                                    padding-right: 10px; } } }
                        .tiwen72 {
                            font-size: 16px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 640px;
                            span {
                                cursor: pointer; }
                            .tiwen_box {
                                display: flex;
                                justify-content: flex-start;
                                width: 212px;
                                align-items: center;
                                a {
                                    width: 40px;
                                    height: 40px;
                                    display: inline-block;
                                    img {
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 50%; } }
                                .num {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    background: #D9DADB;
                                    color: #ffffff;
                                    font-size: 16px;
                                    text-align: center;
                                    line-height: 40px; }
                                a,span {
                                    margin-left: 5px; } } } } } }
            .content_right {
                width: 320px;
                flex: none;
                .author {
                    height: auto;
                    background: #FAFAFA;
                    box-shadow: 0 0 2px 0 rgba(253,138,37,0.50);
                    margin-bottom: 60px; }
                .right_top {
                    height: 155px;
                    background-image: linear-gradient(-180deg, #7D71F6 0%, #9D6FF2 100%);
                    text-align: center;
                    padding-top: 25px;
                    position: relative;
                    .img {
                        width: 100px;
                        height: 100px;
                        background: rgba(255,255,255,0.10);
                        border: 1px solid rgba(255,255,255,0.30);
                        border-radius: 50%;
                        padding: 5px;
                        margin: auto;
                        img {
                            width: 100px;
                            height: 100px;
                            border-radius: 50%; } }
                    .name {
                        display: inline-block;
                        padding: 9px 40px;
                        color: #333;
                        font-size: 20px;
                        line-height: 24px;
                        background: #fff;
                        border-radius: 100px;
                        box-shadow: 0 0 3px 0 rgba(253,138,37,0.30);
                        position: absolute;
                        left: 50%;
                        margin-left: -60px;
                        bottom: 0;
                        margin-bottom: -20px;
                        white-space: nowrap; } }
                .right_bottom {
                    padding: 50px 20px 30px;
                    .text {
                        font-size: 14px;
                        line-height: 24px;
                        color: #333333; }
                    .open_close {
                        color: $main;
                        cursor: pointer;
                        .ic_sp {
                            position: absolute;
                            margin-top: 2px; }
                        .ic_sp_open {
                            transform: rotate(180deg); }
                        .ic_sp_down {
                            transform: rotate(0deg); } } }
                .similar {
                    h3 {
                        font-size: 20px;
                        line-height: 24px;
                        color: $main;
                        text-align: center; }
                    .ask72 {
                        .tit {
                            font-size: 18px;
                            color: #333333;
                            line-height: 22px;
                            margin-top: 40px;
                            padding-bottom: 13px;
                            text-align: left;
                            border-bottom: 2px solid #EAEAEA; }
                        .line {
                            width: 88px;
                            height: 2px;
                            background: $main;
                            display: inline-block;
                            position: absolute;
                            margin-top: -2px; } }
                    .tanbai {
                        position: relative;
                        .ul72 {
                            margin-top: 52px; }
                        .sp_icon,.dw_icon {
                            cursor: pointer;
                            position: absolute;
                            left: 50%;
                            margin-left: -12px;
                            margin-top: 20px;
                            color: $main;
                            i {
                                font-size: 20px;
                                font-weight: bold; } }
                        .dw_icon {
                            bottom: 0;
                            margin-bottom: -40px;
                            transform: rotate(180deg); } }
                    .ul72 {
                        .li72 {
                            margin-top: 20px;
                            // margin: 10px auto
                            // padding: 10px 0
                            width: 320px;
                            height: 190px;
                            box-shadow: 0 0 2px 0 rgba(253,138,37,1); } }
                    .slick {
                        .li72 {
                            margin-top: 0;
                            box-shadow: 0 0 2px 0 #ffffff;
                            padding: 10px 0;
                            a {
                                box-shadow: 0 0 2px 0 rgba(253,138,37,1); }
                            // img
 } }                            //  // margin: 10px autobac
                    button {
                        background: $main;
                        // background: #000000
                        width: 14px;
                        height: 21px;
                        position: absolute;
                        left: 50%;
                        &:before {
                            color: #ffffff;
                            opacity: 1; } }
                    .slick-next {
                        width: 17px;
                        height: 25px;
                        top: -30px;
                        transform: rotate(-90deg);
                        &:before {
                            font-size: 36px;
                            line-height: 6px;
                            position: absolute;
                            left: -9px; } }
                    .slick-prev {
                        width: 17px;
                        height: 25px;
                        top: 640px;
                        transform: rotate(-90deg);
                        &:before {
                            font-size: 36px;
                            line-height: 6px;
                            position: absolute;
                            left: -9px; } } } } } } }
