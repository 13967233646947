.message-container {
    width: 700px;
    text-align: left;
    .top {
        margin: 110px 0 0;
        .title {
            @include fs(24);
            line-height: 25px;
            margin-bottom: 50px; } }
    .notification-cont {
        margin-bottom: 70px;
        .load-more {
            margin-top: 0;
            padding-top: 50px;
            border-top: 1px #f2f2f2 solid; } }
    .message-list {
        // margin-bottom: 50px
        border-top: 1px #E5E5E5 solid;
        padding-top: 5px;

        li {
            border-bottom: 1px #f2f2f2 solid;
            position: relative;
            padding: 20px 15px;
            &:last-child {
                border-bottom: none; } }
        .un-read {
            .disc {
                display: inline-block;
                *display: inline;
                width: 6px;
                height: 6px;
                background-color: $main;
                position: absolute;
                @include rounded(50%);
                left: 0;
                top: 27px; } }
        .reminder {
            font-size: 16px;
            font-size: 1.6rem;
            color: #b3b3b3;
            line-height: 21px;
            text-align: center;
            margin-top: 60px; }
        .cont {
            @include fs(14);
            margin-bottom: 8px;
            color: #333;
            a {
                color: $orange;
                &:hover {
                    color: $orange-hover; } } }
        .info {
            color: #B3B3B3;
            @include fs(13);
            line-height: 16px; }
        .comment {
            position: relative;
            margin-bottom: 11px;
            margin-top: 15px;
            padding: 15px 20px;
            color: #808080;
            background-color: #F6F6F6;
            @include fs(14);
            .triangle {
                position: absolute;
                display: inline-block;
                *display: inline;
                border: 15px solid transparent;
                border-bottom-color: #F6F6F6;
                top: -25px; } } } }


.notice-new {
   width: 750px;
   margin-top: 30px;
   h1 {
      font-size: 16px;
      color: #333;
      height: 22px;
      line-height: 22px;
      font-weight: 500;
      text-align: center; }
   .notice-tag {
      margin-top: 20px;
      font-size: 0;
      border-bottom: 1px solid #EEEEEE;
      span {
         font-size: 14px;
         font-weight: 400;
         color: #666;
         margin-right: 30px;
         display: inline-block;
         padding-bottom: 15px;
         height: 20px;
         line-height: 20px;
         cursor: pointer;
         position: relative;
         &#active, &:hover {
            color: #FD8A25;
            font-weight: 500;
            &:after {
               content: '';
               position: absolute;
               left: 0;
               bottom: 0;
               width: 100%;
               height: 2px;
               background: #FD8A25; } } } }
   .notice-hdxx {
      margin-top: 20px;
      li {
         margin-bottom: 30px;
         .list-user {
            height: 36px;
            display: flex;
            align-items: center;
            img {
               width: 36px;
               height: 36px;
               border-radius: 50%; }
            div {
               margin-left: 10px;
               a {
                  height: 17px;
                  line-height: 17px;
                  font-size: 12px;
                  color: #000;
                  font-weight: 400; }
               span {
                  display: block;
                  margin-top: 2px;
                  height: 17px;
                  line-height: 17px;
                  font-size: 12px;
                  color: #999;
                  font-weight: 300; } } }
         .time {
            display: block;
            float: right;
            margin-top: -36px;
            font-size: 12px;
            color: #aaa;
            height: 14px;
            line-height: 14px; }
         .list-content {
            margin-top: 15px;
            line-height: 24px;
            color: #333;
            font-size: 14px;
            span {
               color: #999; } }
         .list-articleLink {
            margin-top: 15px;
            background: #F6F6F6;
            padding: 15px;
            color: #333;
            line-height: 25px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            &:hover {
               color: #FD8A25;
               box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1); } } } }
   .notice-xtxx {
      display: none;
      margin-top: 35px;
      li {
         margin-bottom: 30px; }
      h5 {
         font-size: 16px;
         color: #333;
         height: 22px;
         line-height: 22px;
         font-weight: 500;
         margin: 0; }
      p {
         margin-top: 10px;
         font-size: 14px;
         color: #999;
         line-height: 24px; }
      span {
         display: block;
         margin-top: 10px;
         font-size: 12px;
         color: #aaa;
         height: 14px;
         line-height: 14px; } }
   .no-data {
      margin: 100px 0;
      text-align: center;
      display: none;
      img {
         width: 300px; }
      p {
         font-size: 12px;
         color: #999;
         height: 17px;
         line-height: 17px;
         margin-top: -37px; } }
   .no-more {
      display: none;
      text-align: center;
      color: #aaa;
      font-size: 12px;
      margin-bottom: 50px; } }
