//pc版、手机版通用，最大宽度
.universal-container {
    max-width: 280px;
    width: 100%;
    padding-bottom: 100px; }
.inner-container {
    padding: 5%; }
.main_cont-cn {}
.container {
    min-width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    .container-wrapper {
        padding-top: $pc_nav_height;
 }        // padding-bottom: $pc_footer_height
    .container-wrapper-cn {
        padding-top: 60px; }
    .container-wrapper-en {
        padding-top: 188px;
        padding-bottom: 97px; } }
.login,.find_password,.register {
    .container {
        .container-wrapper {
            padding-top: 0; } } }
.post_new {
    .container .container-wrapper {
        padding-bottom: 0; } }
.page-cont {
    width: 1000px;
    position: relative;
    margin: 0 auto; }

.all_reminder {
    background-color: #FFF2F2;
    border: 1px #FFDBDB solid;
    color: $error_red;
    display: inline-block;
    *display: inline;
    @include rounded(5px);
    font-size: 13px;
    line-height: 35px;
    padding: 0 95px 0 20px;
    position: relative;
    .close-btn {
        position: absolute;
        right: 10px;
        color: #b3b3b3;
        font-size: 1.4rem;
        i {
            font-size: 15px;
            color: $error_red; } } }

//错误提示
.error-reminder {
    @extend .all_reminder;
    color: $error_red;
    .close-btn {
        i {
            color: $error_red; } } }

//成功提示
.success-reminder {
    @extend .all_reminder;
    background-color: #F0F7E6;
    border: 1px #DDE7D2 solid;
    color: #76A238;
    .close-btn {
        i {
            font-size: 15px;
            color: #76A238; } } }
// 链接
a.link {
    color: $orange;
    &:hover {
        color: $orange_hover; } }
a.key-link {
    color: $red-link;
    text-decoration: underline;
    margin-left: 5px; }

// 分页
.pagination {
    padding-top: 50px;
    border-top: 1px solid #f2f2f2;
    color: #b3b3b3;
    text-align: center;
    margin-bottom: 20px;
    .page-rl {
        display: inline-block;
        *display: inline; }
    .page {
        color: #b3b3b3;
        display: inline-block;
        *display: inline;
        width: 30px;
        height: 30px;
        @include fs(14);
        line-height: 30px;
        text-align: center;
        margin-left: 5px;
        &.current {
            background-color: #F2F2F2;
            @include rounded(50px);
            a {
                color: $black; } }
        &:hover {
            background-color: #F2F2F2;
            @include rounded(50px); }
        a {
            display: block;
            width: 100%;
            height: 100%;
            color: #b3b3b3; } }
    .btn {
        margin: 0 5px; } }



//弹窗相关样式
.popup_background {
    background: #fff !important;
    background: rgba(255,255,255,0.97);
    background: -webkit-linear-gradient(top, #fff, rgba(255,255,255,0.97)) !important;
    background: -moz-linear-gradient(top, #fff, rgba(255,255,255,0.97)) !important;
    background: -o-linear-gradient(top, #fff, rgba(255,255,255,0.97)) !important;
    background: -ms-linear-gradient(top, #fff, rgba(255,255,255,0.97)) !important;
    background: linear-gradient(to bottom, #fff, rgba(255,255,255,0.97)) !important;
 }    //background-color: rgba(0,0,0,0.3) !important
#login_popup_wrapper {
    z-index: 100002 !important; }
#login_popup_background {
    background: #000 !important;
    z-index: 100002 !important; }
.popup_wrapper {
    .title {
        @include fs(24);
        line-height: 34px;
        margin-bottom: 10px; }
    .reminder {
        @include fs(16);
        line-height: 22px;
        margin-bottom: 30px;
        color: #808080; }
    .buttons {
        .btn {
            margin: 0 5px; } }
    .esc-close {
        position: fixed;
        left: 20px;
        top: 20px;
        color: #b3b3b3;
        font-size: 13px;
        transform: translate(0,0) translateZ(0);
        -webkit-transform: translate(0,0) translateZ(0); }
    .close-btn {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10;
        // top: -13%
        // right: -8%
        color: #A0A0A0;
        @include fs(30);
        cursor: pointer;
        transform: translate(0,0) translateZ(0);
        -webkit-transform: translate(0,0) translateZ(0);
        &.login_popup_close {
            position: absolute;
            top: 10px;
            right: 15px; } }
    .comment-list {
        width: 580px;
        padding-top: 25px;
        border-top: 1px #e5e5e5 solid;
        text-align: left;
        li {
            padding-bottom: 10px; }
        .avatar img {
            margin-right: 12px !important; }
        .comment-info {
            .fr {
                line-height: 40px; } } }
    .app-down-c {
        width: 792px;
        .right {
            width: 292px;
            text-align: left;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -105px; } }
    #qrcodeTable {
        margin-top: 20px; } }
#new-help-pop,#how-recommend-pop {
    .title {
        margin-bottom: 60px;
        @include fs(24);
        line-height: 34px; }
    .t {
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        @include fs(16);
        line-height: 22px; }
    .list {
        text-align: left;
        padding-top: 30px;
        margin-bottom: 50px;
        margin-left: 16px;
        list-style-type: decimal;
        li {
            @include fs(14);
            line-height: 21px;
            margin-bottom: 10px;
            .ps {
                color: #808080; } } }
    .list-style-none {
        list-style: none;
        margin-left: 0; }
    .part {
        padding-top: 30px;
        text-align: left;
        p {
            margin-bottom: 15px;
            @include fs(14);
            line-height: 21px; }
        .pl {
            padding-left: 10px; } }
    .load-more {
        border-top: none; } }
.popup-large {
    vertical-align: top !important;
    position: relative;
    .title {
        margin: 50px 0; }
    .reminder {
        @extend .tc;
        @include fs(20);
        border-bottom: none; }
    .search-part {
        background-color: rgba(255,255,255,1);
        padding: 60px 0 0px 0;
        text-align: left;
        width: 740px;
        top: 0;
        left: 50%;
        position: fixed;
        transform: translate(-50%,0) translateZ(0);
        -webkit-transform: translate(-50%,0) translateZ(0);
        z-index: 2; }

    // .top-fixed
    //     background-color: rgba(255,255,255,1)
    //     position: fixed
    //     padding: 25px 0 0 0
    //     // border-bottom: 1px solid #F2F2F2
    //     transition: all 0.5 ease
    .top-search {
        width: 100%;
        background-color: transparent;
        @include fs(36);
        line-height: 47px;
        border : none;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 10px; }
    .mod-article-list {
        text-align: left;
        margin-top: 130px;
        .reminder {
            @include fs(14);
            padding-top: 60px; }
        li {
            border-bottom: 1px solid #f2f2f2;
            padding: 30px 0 20px 0;
            min-height: 150px;
            &:last-child {
                border-bottom: none; }
            .cont {
                width: 580px; }
            .right-part {
                overflow: hidden; }
            .intro {
                color: #808080; }
            .point {
                margin: 0 5px; }
            .left-img {
                @extend .fl;
                position: relative;
                margin-right: 30px; }
            .title {
                margin-top: 0px; }
            .text {
                display: block;
                position: absolute;
                top: 50%;
                text-align: center;
                background: white;
                width: 100%;
                line-height: 40px;
                // transform: translateY(-50%)
                @include transformY(-50%);
                opacity: 0.9;
                font-size: 17px; }
            em {
                color: $main;
                font-style: normal; } } }
    .load-more {
        margin: 0 0 100px;
        border-top: 1px solid #f2f2f2;
        padding-top: 50px; } }
#login_popup {
    width: 100%; }
#show-detail {
    .list-page {
        padding-top: 80px;
        width: 580px;
        .tit-info {
            color: $t_color; }
        .author-info {
            padding: 20px 0;
            .pos {
                width: 360px; } }
        .recommend-list {
            // height: 445px
 } }            // overflow-y: auto
    .r-author-list {
        .author-avatar {
            width: 40px; }
        .author-cont .btn {
            right: 0px; } }
    .user-fans {
        min-height: auto !important; } }
#show-all-result {
    vertical-align: top !important;
    padding-top: 80px;
    .result-list {
        margin-bottom: 70px; }
    .cont {
        width: 648px; }

    .tit {
        color: #333;
        @include fs(16);
        line-height: 22px;
        padding-bottom: 20px;
        border-bottom: 1px #E5E5E5 solid; }
    em {
        font-style: normal; }
    li {
        border-bottom: 1px #f2f2f2 solid;
        &:last-child {
            border-bottom: none; }
        .author-name {
            @include fs(24);
            line-height: 34px; }
        .pic {
            margin-right: 30px; }
        .author-cont {
            display: inline-block;
            width: 65%;
            float: none;
            vertical-align: top; }
        .pic-product {
            margin-right: 30px; }
        .author-avatar {
            img {
                @include rounded(50%); } }
        .product-name {
            color: $black;
            @include fs(24);
            line-height: 33px;
            margin-bottom: 15px;
            display: inline-block; }
        .pos {
            @include fs(16);
            height: 24px;
            line-height: 24px;
            color: #808080;
            padding: 0 125px 0 0; }
        .intro {
            @include fs(16);
            line-height: 24px;
            color: #808080;
            padding: 0; }
        img {
            max-width: none; } } }
#pics_popup {
    .pop_pics {
        margin: 5vh 0;
        display: block;
        text-align: center;
        width: 1000px;
        .slick-slide {
            width: 1000px !important;
            height: 90vh;
            position: relative;
            img {
                position: absolute;
                display: inline-block;
                max-width: 1000px;
                height: auto;
                max-height: 90vh;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%); } } }
    .slick-prev {
        margin-left: -443px;

        &::before {
            content: "\e604"; } }
    .slick-next {
        margin-left: 393px;
        &::before {
            content: "\e605"; } }
    .slick-prev,.slick-next {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        &::before {
            display: inline-block;
            font-family: 'icomoon';
            background-color: #FFF;
            color: $main;
            box-shadow: 0 1px 3px rgba(0,0,0,.5);
            width: 50px;
            height: 50px;
            @include rounded(50%);
            text-align: center;
            line-height: 50px;
            @include fs(20); } }

    .slick-dots {
        display: none !important; } }

//404页面
.cont-404 {
    @extend .tc;
    display: table;
    height: 100%;
    width: 100%;
    .container {
        display: table-cell;
        vertical-align: middle;
        h1 {
            color: #000;
            @include fs(120);
            line-height: 137px;
            margin-bottom: 31;
            vertical-align: middle; }
        .text {
            color: $orange;
            margin-bottom: 100px;
            @include fs(16);
            line-height: 18px; } }
    .m-header {
        display: none; } }

// 无hover效果
.no-pointer {
    cursor: initial !important; }

.gap-point-large {
    margin: 0 8px; }
.gap-point {
    margin: 0 5px; }
.rotete {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); }
.server-error {
    padding: 200px 0;
    .error-msg {
        width: 320px;
        background-color: #F6F6F6;
        padding: 10px 0;
        @include fs(16);
        margin: 0 auto; } }
.browser-msg {
    padding: 30px 0;
    background-color: #F2F2F2;
    position: fixed;
    bottom: 0;
    width: 100%;
    *left: 0;
    .txt {
        margin-bottom: 27px;
        @include fs(18);
        line-height: 19px; }
    li {
        width: 130px;
        display: inline-block;
        *display: inline;
        img {
            margin: 0 auto 5px; }
        .name {
            color: #666666;
            @include fs(14);
            line-height: 17px; } } }
.global-top-msg {
    position: fixed;
    top: 0;
    left: 50%;
    max-width: 640px;
    @include transformX(-50%);
    // transform: translateX(-50%)
    // -ms-transform: translateX(-50%)     /* IE 9 */
    // -moz-transform: translateX(-50%)    /* Firefox */
    // -webkit-transform: translateX(-50%)  /* Safari 和 Chrome */
    // -o-transform: translateX(-50%)
    padding: 12px 70px 12px 25px;
    @include border-radius(0px,5px,5px,0);
    z-index: 100001;
    background-color: #FFF;
    color: #000;
    background: rgba(255,255,255,0.97);
    box-shadow: 0 1px 1px rgba(0,0,0,0.25),0 0 1px rgba(0,0,0,0.35);
    border-radius: 0 0 4px 4px;
    transition: top 700ms, opacity 700ms;
    @include fs(13);
    a {
        color: $orange;
        margin-left: 5px; }
    .close-icon {
        color: #000;
        position: absolute;
        right: 20px;
        top: 16px;
        @include fs(13);
        cursor: pointer;
        line-height: 13px; } }


@media screen and (max-width: 640px) {
    .cont-404 {
        .m-header {
            display: block;
            position: absolute;
            width: 100%;
            padding: 0 2%;
            box-sizing: border-box;
            a {
                float: left;
                margin-top: 25px; } }
        .container {
            h1 {
                @include fs(100);
                color: #d7d7d7;
                font-weight: normal; }
            .text {
                color: #aaa;
                padding: 0 10%;
                line-height: 1.5em; }
            .button {
                a {
                    background-color: $orange;
                    border-color: $orange;
                    color: white;
                    width: 60%; } } } } }
#popup-ad,#popup-new,#popup-tip,#popup-notice,#popup-vc {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    bottom: 0;
    z-index: 100;
    .cont,.cont-m {
        text-align: center;
        position: absolute;
        width: 800px;
        height: 480px;
        overflow: hidden;
        top: 50%;
        left: 50%;
        margin: -200px 0 0 -400px;
        background-color: #FFF;
        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 16px;
            color: #333;
            cursor: pointer;
            background-color: #fff;
            display: inline-block;
            height: 20px;
            width: 20px;
            border-radius: 50%; } }
    .ad-img {
        text-align: center;
        img {
            width: 800px;
            height: 480px; } } }


#popup-tip,#popup-notice {
    .cont {
        min-height: 100vh;
        top: 0;
        width: 600px;
        margin: 0 0 0 -300px;
        color: #333;
        padding: 0 100px;
        position: relative;
        display: table;
        overflow: scroll;
        .cell {
            display: table-cell;
            vertical-align: middle; }
        .title {
            font-size: 24px;
            line-height: 25px;
            margin-bottom: 25px; }
        .intro {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 15px;
            color: #666;
            text-align: left; }
        .qrcode {
            margin: 18px auto 30px;
            width: 220px;
            display: block; }

        .btn-bordered {
            color: #FF3D00;
            font-size: 20px;
            line-height: 46px;
            border-color: #FF3D00;
            padding: 0 40px;
            background-color: #FFF;
            @include rounded(4px);
            display: inline-block; } }
    .cont-m {
        min-height: 100vh;
        top: 0;
        width: 100%;
        margin: 0 0 0 -50%;
        color: #333;
        padding: 10%;
        position: relative;
        overflow: scroll;
        display: block;
        .qrcode {
            margin: 20px auto; } }
    .cell {
        display: block; } }

#popup-notice {
    .cont {
        width: 94%;
        margin-left: -47%;
        padding: 100px 60px;
        display: block;
        overflow: scroll;
        .content {
            text-align: left;
            font-size: 14px;
            line-height: 24px;
            color: #666;
            margin-bottom: 43px;
            a {
                color: #0F8AFF; } } } }

#popup-new {
    .cont {
        width: 800px;
        height: 460px;
        padding-top: 65px;
        .close-btn {
            color: #B3B3B3; }
        .post-con {
            margin-top: 40px;
            color: #808080;
            line-height: 20px;
            .post_btn {
                color: #00A4A0;
                text-decoration: underline; } } }
    .part {
        display: inline-block;
        margin: 0 30px;
        position: relative;
        img {
            display: block; } }
    .position {
        position: absolute;
        bottom: 0px;
        left: 50%;
        margin-left: -48px;
        h5 {
            color: #333;
            @include fs(16);
            &.white {
                color: #fff; } } } }
#popup-vc {
    .cont,.cont-m {
        background-color: transparent;
        width: 50%;
        height: auto;
        margin: 0 0 0 -25%;
        @include transformY(-50%);
        img {
            width: 100%; }
        .close-btn {
            position: absolute;
            top: 10%;
            right: 10%;
            font-size: 30px;
            color: #fff;
            cursor: pointer;
            background-color: rgba(0,0,0,.5);
            display: inline-block;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            line-height: 52px; } }

    .cont-m {
        width: 94%;
        margin: 0 0 0 -47%;
        overflow: visible;
        .close-btn {
            top: -25px;
            right: 15px; } } }

.dropdown-part {
    display: block;
    position: relative;
    margin: -15px 0  30px 0;
    .dropdown-btn {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #BCBCBC;
        @include rounded(3px);
        display: inline-block;
        *display: inline;
        padding: 7px 10px;
        line-height: 23px;
        font-size: 16px;
        vertical-align: middle;
        font-weight: normal;
        color: #4A4A4A;
        width: 320px;
        padding: 7px 10px 2px 10px; }
    i {
        float: right;
        line-height: 23px; }
    .text {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        *display: inline;
        line-height: normal; }
    .dropdown-list {
        position: absolute;
        display: none;
        width: 318px;
        top: 40px;
        z-index: 11;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.7);
        padding: 5px 0;
        margin: 2px 0 0;
        border: 1px solid rgba(0,0,0,0.2);
        @include rounded(3px);
        -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        -o-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        -moz-background-clip: padding-box;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        font-size: 14px;
        li {
            cursor: pointer;
            margin-bottom: 0 !important;
            width: auto;
            color: #333333;
            border-top: none;
            line-height: 18px;
            word-break: break-all;
            line-height: 18px;
            padding: 10px 20px;
            &:hover {
                background: $main;
                color: #fff; } }
        li.current {
            color: $main;
            &:hover {
                color: #fff; } } } }

