html {
    min-width: 1200px; }
.index_cont {
    // 文章通用标签
    overflow: hidden;
    // 货币
    .home-markets {
        width: 1200px;
        height: 80px;
        margin: 30px auto 0;
        overflow: hidden;
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 2px;
            height: 100%;
            background-color: #fff;
            position: absolute;
            left: 1098px;
            top: 0;
            z-index: 99; }
        .markets-item {
            width: 1100px;
            height: 100px;
            position: relative;
            overflow-x: scroll;
            overflow-y: hidden;
            ul {
                position: absolute;
                top: 0;
                left: 0;
                white-space: nowrap;
                font-size: 0;
                margin-left: -20px;
                li {
                    display: inline-block;
                    width: 120px;
                    padding: 0 20px;
                    height: 80px;
                    @include fs(18);
                    position: relative;
                    vertical-align: top;
                    a {
                        color: #000; }
                    &::after {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 68px;
                        background-color: #eee;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        @include transformY(-50%); }
                    &:last-child::after {
                        display: none; }
                    // &:nth-child(7n+1),&:first-child
                    //     width: 140px
                    //     padding-left: 0
                    p {
                        line-height: 25px;
                        &.coin-name {
                            margin-bottom: 5px; }
                        &.coin-exchange {
                            font-weight: bold; }
                        &.fall {
                            color: $fall; }
                        &.rise {
                            color: $rise; } }
                    &.rise {
                        .coin-exchange {
                            animation: marketsRise 0.5s;
                            -webkit-animation: marketsRise 0.5s; }
                        // .coin-degree
 }                        //     color: $rise
                    &.fall {
                        .coin-exchange {
                            animation: marketsFall 0.5s;
                            -webkit-animation: marketsFall 0.5s; }
                        // .coin-degree
 } } } }                        //     color: $fall
        .arrow-box {
            font-size: 0;
            .arrow-btn {
                display: inline-block;
                width: 18px;
                height: 30px;
                font-size: 28px;
                text-indent: -4px;
                vertical-align: middle;
                color: #ccc;
                cursor: pointer;
                &.current {
                    color: $main_blue; } }
            .line {
                display: inline-block;
                width: 2px;
                height: 68px;
                background-color: #eee;
                vertical-align: middle;
                margin: 0 10px; }
            .height {
                display: inline-block;
                width: 0;
                height: 80px;
                vertical-align: middle; } } }

    .post_tag {
        position: absolute;
        top: 0;
        left: 0;
        border-left: 4px solid $main_blue;
        padding: 0 10px;
        background-color: #000;
        @include fs(14);
        line-height: 24px;
        color: #fff;
        z-index: 3; }
    .top_posts {
        .post_tag {
            top: auto;
            left: auto;
            padding: 0;
            bottom: 15px;
            right: 241px;
            border: 1px solid #fff;
            border-radius: 2px;
            display: inline-block;
            width: 42px;
            height: 20px;
            text-align: center;
            @include fs(12);
            line-height: 20px; } }
    .pro_icon {
        display: inline-block;
        line-height: 20px;
        padding: 0 6px;
        background-color: #B880F6;
        color: #fff;
        @include fs(13);
        @include rounded(30px);
        margin-bottom: 10px;
        font-style: italic; }

    .post_data {
        a {
            color: #fff; }
        i {
            color: $dd_main_color;
            margin-right: 8px; }
        .like_num,.comment_num,.read_num {
            margin-right: 22px; }
        .like_num {
            cursor: pointer;
            .icon-Shape9 {
                display: none; }
            &.current {
                .icon-Shape9 {
                    display: inline-block; }
                .icon-Shape10 {
                    display: none; } } } }
    .top_article {
        background-color: #121212;
        margin-bottom: 30px;
        padding-top: 55px;
        .first {
            position: relative;
            margin-bottom: 30px; }
        .inner,.column,.articles {
            .options {
                @extend .fr;
                a {
                    display: inline-block;
                    *display: inline;
                    // line-height: 50px
                    // color: rgba(255, 255, 255, 0.5)
                    color: #fff;
                    &:hover,&.current {
                        color: $main;
                        text-decoration: none;
                        .icon {
                            // border-color: $main
                            // background-color: $main
                            color: $main; } }
                    &.current {
                        .icon-Shape9 {
                            display: inline-block;
                            *display: inline;
                            -webkit-animation: scalefade 300ms;
                            animation: scalefade 300ms; }
                        .icon-like-1 {
                            display: none; } }
                    .icon-Shape9 {
                        display: none; } }
                .icon {
                    vertical-align: middle;
                    text-align: center;
                    display: inline-block;
                    *display: inline;
                    // width: 50px
                    // height: 50px
                    // border: 1px solid #fff
                    margin-right: 10px;
                    // @include rounded(50%)
                    // @include fs(20)
                    @include fs(16);
 }                    // border-color: rgba(255, 255, 255, 0.5)
                .s-like {
                    margin-right: 6px; }
                .icon-comment {
                    position: relative;
                    top: 2px; }
                .num {
                    @include fs(13);
                    position: relative;
                    top: 2px; }
                .like {
                    margin-right: 30px; } } }
        .inner {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            &:hover .overlay {
                opacity: 0.7;
                filter: alpha(opacity=70);
                -ms-filter: alpha(opacity=70);
                zoom: 1; }
            .post {
                width: 530px;
                height: 339px;
                padding: 86px 70px 0;
                position: relative;
                a {
                    color: #fff; }
                // // 阴影
                // background-image: -webkit-gradient(linear,0 0,0 100%,from(rgba(0,0,0,.01) 40%),to(rgba(0,0,0,.7) 80%))
                // background-image: -webkit-linear-gradient(top,rgba(0,0,0,.01) 40%,rgba(0,0,0,.7) 80%)
                // background-image: -moz-linear-gradient(top,rgba(0,0,0,.01) 40%,rgba(0,0,0,.7) 80%)
                // background-image: linear-gradient(top,rgba(0,0,0,.01) 40%,rgba(0,0,0,.7) 80%)
 }                // background-repeat: repeat-x
            .overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                transition: all 0.2s ease;
                background-image: -webkit-linear-gradient(180deg, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.95) 100%);
                background-image: -moz-linear-gradient(180deg, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.95) 100%);
                background-image: linear-gradient(180deg, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.95) 100%); }
            .post-cont {
                position: absolute;
                bottom: 10px;
                width: 530px; }
            h2 {
                margin-bottom: 25px; }
            .info {
                @include fs(13);
                color: #fff;
                margin-bottom: 56px; }

            .title {
                @include fs(36);
                line-height: 1.2em; }
            .author {
                width: 310px;
                height: 100%;
                position: relative;
                background-color: #020000;
                background-color: rgba(0,0,0,.7);

                .a-inner {
                    padding: 63px 35px 57px;
                    text-align: center;
                    position: relative;
                    z-index: 2;
                    .btn {
                        &.hover {
                            background-color: $orange;
                            color: #fff;
                            &:hover {
                                background-color: $orange_hover; } } }
                    img {
                        @include rounded(50%);
                        border: 2px solid #fff; }
                    p {
                        @include fs(14);
                        color: #fff;
                        color: rgba(255,255,255,.5);
                        margin-bottom: 30px; }
                    .pic {
                        display: block;
                        margin-bottom: 20px; }
                    .name {
                        @include fs(18);
                        color: #fff;
                        display: block;
                        margin-bottom: 30px;
                        padding-bottom: 16px;
                        border-bottom: 1px solid rgba(255,255,255,.2); } } } }
        .first {
            width: 980px;
            margin: 0 auto;
            overflow: hidden;
            // box-shadow: 0px 0px 2px #000
            // display: inline-block
            img.bg {
                filter: blur(2px); } }
        .inner {
            &:hover .overlay {
                opacity: 1; }
            .post {
                width: 670px;
                padding: 0;
                height: 100%;
                display: table; }
            .post-cont {
                position: static;
                width: 670px;
                display: table-cell;
                vertical-align: middle; }
            .summary {
                color: #fff;
                opacity: .8;
                @include fs(16);
                padding-bottom: 30px;
                border-bottom: 1px solid rgba(255,255,255,.2);
                margin-bottom: 30px; }
            h2 {
                margin-bottom: 20px; }
            .tag {
                background-color: $orange;
                display: inline-block;
                line-height: 23px;
                padding: 0 5px;
                color: #fff;
                @include fs(16);
                margin-bottom: 20px; }
            .overlay {
                background-image: -webkit-linear-gradient(to right, #121212, rgba(18,18,18,.2), #121212);
                background-image: -o-linear-gradient(to right, #121212, rgba(18,18,18,.2), #121212);
                background-image: linear-gradient(to right, #121212, rgba(18,18,18,.2), #121212); }
            .author {
                background-color: transparent;
                display: table;

                .a-inner .name {
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 23px; }
                .a-inner {
                    padding: 0;
                    display: table-cell;
                    vertical-align: middle;
                    p {
                        margin-bottom: 0;
                        padding: 0 35px; } }
                .follow {
                    display: none; } }
            .info {
                opacity: .8;
                margin-bottom: 10px; }
            .options {
                opacity: .8;
                float: none;
                display: block; } } }
    .top_posts {
        width: 800px;
        height: 352px;
        // margin-top: 80px
        margin-bottom: 20px !important;
        // white-space: nowrap
        overflow: hidden;
        background-color: #333;
        &:hover {
            .slick-arrow:before {
                display: block; } }
        .slick-track {
            height: 100%;
            &:before,&:after {
                display: none; } }
        .part {
            display: inline-block;
            position: relative;
            // overflow: hidden
            font-size: 12px;
            .bg,.shadow {
                width: 110%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                filter: blur(20px); }
            .bg {
                background-size: cover;
                background-position: center; }
            .shadow {
                background-color: rgba(0,0,0,.6); }
            &.slick-active {
                z-index: 999; } }
        .part-cont {
            width: 100%;
            min-width: 800px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            a {
                display: block;
                overflow: hidden; }
            img {
                position: relative;
                z-index: 19; }
            .post_tag {
                position: static;
                display: inline-block;
                width: auto;
                height: auto;
                padding: 2px 10px;
                line-height: 20px;
                font-size: 14px;
                border: 0;
                background-color: $main;
                border-radius: 4px;
                margin-bottom: 3px;
                &.post_tag_ad {
                    position: absolute;
                    top: 10px;
                    left: 20px;
                    z-index: 25;
                    width: 46px;
                    height: 20px;
                    padding: 0;
                    border: 1px solid $main;
                    border-radius: 4px;
                    color: $main;
                    background-color: transparent; } }
            .banner-title {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 21;
                width: 760px;
                padding: 20px;
                padding-top: 0;
                font-size: 0;
                .text {
                    display: -webkit-box;
                    line-height: 33px;
                    // font-weight: bold
                    font-size: 24px;
                    color: #fff;
                    white-space: normal;
                    max-height: 66px;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis; } }
            .shadows {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 20;
                width: 100%;
                height: 100%;
                background: -webkit-linear-gradient(transparent, #000);
                background: -o-linear-gradient(transparent, #000);
                background: -moz-linear-gradient(transparent, #000);
                background: linear-gradient(transparent, #000);
                opacity: 0.3; } }
        .bottom_banner {
            position: absolute;
            top: 0;
            left: 500px;
            width: 300px;
            height: 100%;
            color: #fff;
            overflow: hidden;
            .cont {
                width: 100%;
                height: 100%;
                padding: 20px 20px 13px;
                margin: 0 auto;
                position: relative;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box; }
            .title {
                margin-bottom: 15px;
                @include fs(20);
                line-height: 28px;
                white-space: normal;
                text-align: justify;
                max-height: 84px;
                overflow: hidden;
                word-break: break-word;
                a {
                    color: #fff; } }
            .desc {
                p {
                    white-space: normal;
                    @include fs(14);
                    line-height: 20px;
                    max-height: 100px;
                    overflow: hidden;
                    text-align: justify;
                    font-weight: 100;
                    a {
                        color: #fff; } } }
            .info {
                width: 100%;
                color: #f2f2f2;
                @include fs(14);
                position: absolute;
                bottom: 0;
                left: 0;
                a {
                    color: #fff;
                    &.margin_0 {
                        margin-right: 0; } }
                .gap_point {
                    margin: 0 5px; }
                .post_data {
                    padding: 20px 20px 10px;
                    font-size: 0;
                    position: relative; } }
            .mask {
                width: 120%;
                height: 120%;
                background-color: #000;
                opacity: 0.6;
                position: absolute;
                top: -10%;
                left: -10%;
                filter: blur(10px); }
            .bg {
                width: 320px;
                max-width: none;
                left: -10px; }
            .fun-box {
                display: inline-block;
                @include fs(12);
                color: #aaa;
                line-height: 17px;
                text-align: center;
                vertical-align: bottom;
                margin-right: 50px;
                position: relative;
                .icon {
                    display: block;
                    width: 21px;
                    height: 16px; }
                .num {
                    position: absolute;
                    left: 26px;
                    top: 50%;
                    @include transformY(-50%); } }
            .likes {
                margin-bottom: 4px;
                .icon {
                    background: url(/public/css/img/icon_pc_likes_fff.png) no-repeat;
                    background-size: 100% 100%; }
                &.current {
                    color: $main_blue;
                    .icon {
                        background: url(/public/css/img/icon_pc_likes_curr.png) no-repeat;
                        background-size: 100% 100%; } } }
            .number-reads {
                position: absolute;
                bottom: 13px;
                right: 20px;
                margin: 0;
                cursor: auto;
                .icon {
                    background: url(/public/css/img/icon_pc_read_fff.png) no-repeat;
                    background-size: 100% 100%;
                    float: left;
                    margin-right: 5px; }
                .num {
                    position: static; } }
            .comment {
                padding: 0 20px 0px;
                margin: 0;
                position: absolute;
                bottom: 13px;
                left: 50%;
                @include transformX(-50%);
                .icon {
                    width: 18px;
                    height: 24px;
                    background: url(/public/css/img/icon_pc_share_fff.png) no-repeat;
                    background-size: 100% 100%; }
                &:hover {
                    .share-box {
                        display: block; } } } }
        .slick-dots {
            width: 100px;
            height: 10px;
            top: 16px;
            right: 20px;
            font-size: 0;
            text-align: right;
            // &::after
            //     content: ""
            //     display: block
            //     width: 100%
            //     height: 100%
            //     position: absolute
            //     bottom: 0
            //     left: 0
            //     background: url(/public/css/img/banner_bg.png) no-repeat
            //     background-size: 150% 150%
            //     background-position: center center
            //     opacity: 0.35
            li {
                width: 10px;
                height: 10px;
                margin: 0;
                margin-right: 10px;
                position: relative;
                z-index: 99;
                &:last-child {
                    margin: 0; }
                button {
                    width: 10px;
                    height: 10px;
                    background-color: #fff;
                    font-size: 0;
                    margin: 0;
                    &:before {
                        display: none !important; } }
                &.slick-active {
                    width: 20px;
                    button {
                        width: 20px;
                        background-color: $main_blue; } } } }
        .slick-list {
            height: 100%; }
        .slick-arrow {
            z-index: 1;
            width: 20px;
            height: 34px;
            &::before {
                display: none;
                content: "";
                width: 20px;
                height: 34px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                opacity: 1 !important; }
            &.slick-prev {
                left: 20px;
                &::before {
                    background: url(/public/css/img/slick_prev@2x.png) no-repeat;
                    background-size: 20px 34px; } }
            &.slick-next {
                right: 20px;
                &::before {
                    background: url(/public/css/img/slick_next@2x.png) no-repeat;
                    background-size: 20px 34px; } } } }
    .column {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        width: 1020px;
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 40px;
        .part {
            width: 320px;
            height: 200px;
            position: relative;
            overflow: hidden; }
        .bottom_banner {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px 20px 22px;
            // background-color: #171717
            color: #fff;
            z-index: 2;
            .title {
                margin-bottom: 10px;
                @include fs(16);
                line-height: 24px;
                a {
                    color: #fff; } } }
        .shadow {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(0,0,0,.5); }

        .novel {
            img {
                transform: translateY(-25%); } }
        .novel_info {
            position: absolute;
            bottom: 0;
            padding: 0 20px 20px;
            z-index: 2;
            .title {
                color: $main_current;
                @include fs(18);
                line-height: 26px;
                margin-bottom: 10px;
                max-width: 280px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                a {
                    color: $main_current; } }
            .summary {
                color: #fff;
                @include fs(16);
                line-height: 24px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(44,255,255,0.5);
                margin-bottom: 10px; }
            .chapter {
                @include fs(14);
                .novel_state {
                    color: $main_current; }
                i {
                    color: rgba(44,255,255,.8);
                    margin-right: 5px;
                    vertical-align: middle; }

                a {
                    color: #fff; } } } }
    .home-index-ad {
        width: 1020px;
        margin: 0 auto; }
    .main_cont {
        width: 1200px;
        margin: 0 auto;
        //overflow: hidden
        .list-cont {
            width: 800px;
            position: relative;
            margin-top: 20px; }
        // 最热文章
        .hot-post-box {
            overflow: hidden;
            margin-bottom: 20px;
            max-height: 173px;
            ul {
                margin-right: -20px;
                overflow: hidden; }
            li {
                width: 185px;
                margin-right: 20px;
                float: left;
                .pic {
                    width: 185px;
                    height: 120px;
                    margin-bottom: 10px; }
                .desc {
                    display: block;
                    font-size: 16px;
                    color: #000;
                    line-height: 22px;
                    max-height: 44px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; } } }

        .nav {
            position: absolute;
            z-index: 10;
            &.fixed {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 4;
                width: 100%;
                background-color: #fff;
                .nav_inner {
                    width: 1020px;
                    margin: 0 auto; }
                .nav_cont {
                    width: 640px; } }
            .nav_inner {
                height: 55px;
                overflow: hidden; }
            .nav_cont {
                width: 800px;
                height: 54px;
                box-sizing: border-box;
                padding-right: 0;
                position: relative;
                border-bottom: 1px solid #eee;
                .navBox {
                    height: 75px;
                    padding-right: 65px;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    position: relative; } }
            .nav_arrow {
                width: 45px;
                position: absolute;
                right: 0;
                top: -1px;
                height: 54px;
                line-height: 54px;
                padding-left: 20px;
                z-index: 3;
                background-color: #FFF;
                span {
                    display: inline-block;
                    border: 6px solid transparent;
                    cursor: pointer; }
                .arrow_prev {
                    border-right-color: $main_blue;
                    opacity: 0.5;
                    margin-right: 5px;
                    &.current {
                        opacity: 1; } }
                .arrow_next {
                    border-left-color: $main_blue;
                    opacity: 0.5;
                    &.current {
                        opacity: 1; } } } }
        .navUl {
            font-size: 0;
            padding-top: 14px;
            white-space: nowrap;
            //overflow: hidden
            position: absolute;
            z-index: 2;
            padding-right: 23px;
            .nav_title {
                display: inline-block; }
            a {
                position: relative;
                bottom: 0;
                display: inline-block;
                padding-bottom: 15px;
                color: $black;
                line-height: 22px;
                font-weight: bold;
                @include fs(16);
                margin-right: 42px;
                border-bottom: 4px solid transparent;
                &.current {
                    color: $main; }
                &.current:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 4px;
                    background-color: $main;
                    position: absolute;
                    bottom: -3px;
                    left: 0; } } }
        .article-list {
            margin-bottom: 13px;
            padding-top: 55px;
            .pic {
                float: left;
                margin-right: 20px;
                img {
                    width: 100%;
                    min-height: 100%; } }
            .cont {
                overflow: hidden;
                .title {
                    @include fs(20);
                    line-height: 28px;
                    color: #000;
                    display: block;
                    margin-bottom: 10px;
                    max-height: 56px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; }
                .title:hover {
                        color: $main_blue; } }
            .info {
                color: #999;
                font-size: 0;
                position: absolute;
                margin-bottom: 20px;
                left: 220px;
                bottom: 0;
                width: 570px;
                a {
                    color: #999;
                    &.tag {
                        color: $main_blue; } }
                .gap_point {
                    margin: 0 3px; }
                .author {
                    font-size: 12px; }
                .tag {
                    margin-right: 13px; }
                .views {
                    font-size: 12px;
                    float: right; } }
            .options {
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                .fun-box {
                    position: absolute;
                    right: 0;
                    @include fs(12);
                    color: #aaa;
                    line-height: 17px;
                    text-align: center;
                    .icon {
                        display: block;
                        width: 21px;
                        height: 16px; }
                    .num {
                        position: absolute;
                        top: -17px;
                        left: 50%;
                        @include transformX(-50%); } }
                .likes {
                    top: 50%;
                    margin-top: -47px;
                    .icon {
                        background: url(/public/css/img/icon_pc_likes.png) no-repeat;
                        background-size: 100% 100%; }
                    &.current {
                        color: $main_blue;
                        .icon {
                            background: url(/public/css/img/icon_pc_likes_curr.png) no-repeat;
                            background-size: 100% 100%; } } }
                .number-reads {
                    top: 50%;
                    // margin-top: -16px
                    cursor: auto;
                    .icon {
                        background: url(/public/css/img/icon_pc_read.png) no-repeat;
                        background-size: 100% 100%; } }
                .comment {
                    padding: 0 20px 15px;
                    top: 50%;
                    margin-top: 31px;
                    right: -20px;
                    .icon {
                        width: 18px;
                        height: 24px;
                        background: url(/public/css/img/icon_pc_share.png) no-repeat;
                        background-size: 100% 100%; }
                    &:hover {
                        .share-box {
                            display: block; } } } }
            .summary {
                color: #666;
                @include fs(16);
                line-height: 23px;
                text-align: justify;
                max-height: 69px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; }
            .tag {
                font-size: 12px;
                color: $main;
                i {
                    font-size: 1.8rem;
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -4px;
                    margin-right: 10px; }
                a {
                    vertical-align: top;
                    color: $main; } }
            > li {
                padding: 20px 0 20px 0;
                border-bottom: 1px solid #F2F2F2;
                position: relative;
                &:last-child {
                    border: none; } }
            .group {
                font-size: 0;
                margin-right: -6px;
                white-space: nowrap;
                position: relative;
                .group_cell {
                    position: relative;
                    display: inline-block;
                    width: 210px;
                    height: 180px;
                    margin-right: 6px;
                    vertical-align: top; }
                .tag_info {
                    position: absolute;
                    top: 0;
                    text-align: center;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 1.8rem;
                    color: #fff;
                    a {
                        line-height: 240px; } }
                .bottom_banner {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 10px 15px; }
                .title {
                    color: #fff;
                    @include fs(16);
                    line-height: 24px;
                    white-space: normal; } }
            .shadow {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: rgba(0,0,0,.2); }
            .post_part {
                position: relative;
                .pic {
                    position: relative;
                    width: 200px;
                    height: 160px;
                    overflow: hidden; }
                .pro_icon {
                    position: absolute;
                    top: 5px;
                    left: 5px; } }
            .authors_part {
                .group_cell {
                    height: auto;
                    padding-top: 30px;
                    border: 1px solid #E3E4E7;
                    text-align: center;
                    margin-right: 0.13%; }
                img {
                    border-radius: 50%;
                    margin-bottom: 12px;
                    display: inline-block !important; }
                .title {
                    color: #333333;
                    @include fs(18);
                    line-height: 24px; }
                .bottom_banner {
                    position: static;
                    padding: 0;
                    margin-bottom: 8px; }
                .fans_num {
                    color: $main;
                    margin-bottom: 29px;
                    @include fs(13);
                    line-height: 17px; } }
            .recommend_post {
                width: 800px;
                padding-right: 0;
                img {
                    width: 100%; }
                .cont {
                    position: relative; }
                .bottom_banner {
                    position: absolute;
                    bottom: 0;
                    padding: 0 32px 20px;
                    color: #fff;
                    width: 100%; }
                .title {
                    color: #fff;
                    display: inline-block;
                    white-space: nowrap;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &.bottom_border {
                        // padding-bottom: 20px
                        margin-bottom: 10px;
                        width: 100%;
 } }                        // border-bottom: 1px solid rgba(277,277,277,.42)
                .info {
                    display: none;
                    color: #fff;
                    margin-bottom: 0;
                    .gap_point {
                        color: #C0C0C0; }
                    a {
                        color: #fff; } } }
            .group_tit {
                font-size: 1.6rem;
                font-size: 16px;
                line-height: 22px;
                padding-left: 10px;
                border-left: 4px solid $main_blue;
                margin-bottom: 17px; }
            .slick-arrow {
                z-index: 2;
                width: 32px;
                height: 46px; }
            .slick-next {
                right: -32px; }
            .slick-prev {
                left: -35px; }
            .slick-prev:before, .slick-next:before {
                color: $main_blue;
                // opacity: .4
                font-size: 16px;
                font-family: 'icomoon'; }
            .slick-prev:before {
                content: "\e604"; }
            .slick-next:before {
                content: "\e605";
                padding-top: 20px; } }
        .interest_list,.categorie_list {
            .loading {
                margin-top: 100px;
                text-align: center;
                @include fs(16);
                color: #D8D8D8; } }
        .categorie_list {
            padding-top: 20px; }
        .interest_list {
            padding-top: 75px;
            .loading {
                margin-top: 55px; }
            .reminder {
                color: #B3B3B3;
                margin-bottom: 30px;
                @include fs(14);
                a {
                    color: $orange;
                    &:hover {
                        color: $orange-hover; } } }
            .recommend-tags {
                padding-top: 20px;
                @include fs(0);
                li {
                    display: inline-block;
                    margin: 0 15px 20px 0;
                    a {
                        color: #909090; } } }
            .r-author-list {
                li {
                    border-bottom: 1px solid #f2f2f2;
                    .author-avatar {
                        width: 40px; }
                    .author-cont .btn {
 } } }                        //right: 50px
            .latest-list {
                padding-top: 5px;
                li {
                    border-bottom: 1px solid #f2f2f2;
                    padding: 20px 0;
                    &:last-child {
                        border-bottom: none; }
                    h3 {
                        margin-bottom: 10px; } }
                .title {
                    @include fs(18);
                    color: $t_color;
                    margin-bottom: 8px; }
                .time {
                    color: $i_color;
                    @include fs(13);
                    i {
                        padding-right: 5px; } } } } }
    .load_more,.load_end {
        display: inline-block;
        color: #ccc;
        text-align: center;
        @include fs(16);
        padding: 11px 42px;
        margin-bottom: 50px;
        cursor: pointer;
        margin-left: 50%;
        transform: translateX(-50%);
        line-height: 16px;
        &.hide {
            display: none; }
        span {
            margin-top: -2px; }
        &.load_more_btn {
            color: #000;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            .loader {
                display: none; } } }
    .sidebar {
        width: 360px;
        float: right;
        position: relative;
        margin-top: 20px;
        .bottom {
            padding: 0 15px;
            height: 48px;
            line-height: 48px;
            margin-bottom: 10px;
            background-color: #f6f6f6; }
        .part {
            margin-bottom: 40px;
            // @include border-shadow(0,0,4px,2px,rgba(0,0,0,0.1))
            border: 1px solid #eee; }
        .w3-part {
            padding: 0; }
        .more {
            color: #666;
            @include fs(16);
            line-height: 48px;
            i {
                margin-left: 5px;
                color: #C0C0C0;
                @include fs(12); } }
        .title {
            position: relative;
            display: inline-block;
            font-size: 16px;
            i {
                margin-right: 10px;
                color: #272636;
                &.markets_icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background-image: url(/public/css/img/markets_icon@2x.png);
                    background-size: cover;
                    margin-top: -3px;
                    vertical-align: center; } } }
        li {
            @include fs(14);
            a {
                color: #000; } }
        .see_world {
            ul {
                padding: 0 0 0 15px; }
            li {
                display: block;
                padding: 15px 15px 15px 0;
                margin-left: 23px;
                border-bottom: 1px solid #eee;
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: url(/public/css/img/icon_group.png) no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    left: -23px;
                    top: 17px; }
                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: #eee;
                    position: absolute;
                    top: 16px;
                    left: -17px; }
                &:last-child:before {
                    display: none; }
                .point {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    @include rounded(50%);
                    background-color: $main; }
                .cont {
                    display: block; }
                .text {
                    font-size: 14px;
                    line-height: 23px;
                    max-height: 46px;
                    overflow: hidden;
                    display: block; }
                time {
                    display: block;
                    @include fs(12);
                    line-height: 17px;
                    color: #666;
                    margin-bottom: 10px; }
                .br {
                    padding-bottom: 15px;
                    border-bottom: 1px solid #EDEDED; }
                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                    .br {
                        border-bottom: none;
                        padding-bottom: 0; } }
                &:first-child {
                    a {
                        border-top: none; } }
                &.show {
                    transition: height .5s ease;
                    -moz-transition: height .5s ease;
                    -webkit-transition: height .5s ease;
                    -o-transition: height .5s ease;
                    .text {
                        height: auto; }
                    .point {
                        @include rounded(0);
                        height: 100%;
                        border: 2px solid #fff;
                        width: 8px;
                        border-bottom: 34px solid #FAFAFA;
                        transition: height .5s ease;
                        -moz-transition: height .5s ease;
                        -webkit-transition: height .5s ease;
                        -o-transition: height .5s ease; } } }
            .more {
                display: inline-block;
                height: 100%;
                .icon {
                    display: inline-block;
                    width: 8px;
                    height: 14px;
                    background: url(/public/css/img/icon_arrow_ri.png);
                    background-size: 100% 100%;
                    vertical-align: middle;
                    margin-top: -2px;
                    margin-left: 2px; } } }
        .hot_tags {
            li {
                display: inline-block; }
            .tag {
                display: inline-block;
                padding: 4px 10px 3px 10px;
                border: 1px solid #f2f2f2;
                color: gray;
                font-size: 14px;
                font-size: 1.4rem;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                margin: 0 7px 10px 0; } }
        .hot_posts,.reports {
            ol {
                list-style-type: decimal;
                list-style-position: ouside;
                margin-left: 20px; }
            li {
                margin-bottom: 20px;
                padding-left: 5px;
                &:last-child {
                    margin-bottom: 0; }
                &:first-child {
                    a {
                        border-top: none;
                        padding-top: 0; } } } }
        .recommend_product,.partners,.authors {
            li {
                padding: 35px 27px 30px 28px;
                background-color: #fff;
                border-bottom: 1px solid #eee;
                margin-top: 50px;
                position: relative; }
            // .pic
            //     width: 40px
            //     height: 40px
            //     position: absolute
            //     top: 0
            //     left: 50%
            //     transform: translate(-50%,-50%)
            // .cont
            //     overflow: hidden
            //     h3
            //         text-align: center
            //     a
            //         display: inline-block
            //         @include fs(16)
            //         color: #000
            //         line-height: 22px
            //         margin-bottom: 4px
            //     p
            //         @include fs(13)
            //         color: #808080
            //         line-height: 18px
 }            //         margin-top: 20px
        .authors {
            .bottom {
                margin-bottom: 0; }
            .more {
                cursor: pointer;
                &.current {
                    .icon {
                        animation: keepRotate 1s infinite linear; } }
                .icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background: url(/public/css/img/icon_refresh.png) no-repeat;
                    background-size: 100% 100%;
                    vertical-align: middle;
                    margin-top: -2px;
                    margin-right: 5px; } }
            ol {
                padding-left: 15px;
                li {
                    margin: 0;
                    padding: 15px 15px 15px 0;
                    &:last-child {
                        border: 0; }
                    .pic {
                        display: inline-block;
                        width: 30px;
                        height: 30px; }
                    .name {
                        font-size: 14px;
                        line-height: 30px;
                        margin-left: 7px;
                        color: #000; }
                    .cont {
                        font-size: 12px;
                        line-height: 17px;
                        color: #666;
                        margin-top: 15px;
                        max-height: 34px;
                        overflow: hidden; }
                    .follow_style {
                        top: 19px;
                        right: 15px; } } } }

        .partners {
            .cont {
                a {
                    color: #808080;
                    @include fs(13);
                    line-height: 18px; } } }
        .authors {
            img {
                width: 100%;
                height: 100%;
                @include rounded(50%); } }
        .overflow_box {
            overflow: hidden;
            .cont_box {
                margin-top: -30px; }
            .tradingview-widget-container {
                margin-top: -30px; } } }
    .side_option {
        position: fixed;
        right: 4%;
        bottom: 100px;
        a {
            display: block;
            width: 50px;
            height: 50px;
            margin-bottom: 15px;
            @include rounded(50%);
            background-color: $main;
            color: #fff;
            text-align: center;
            line-height: 50px; } } }











// @media (max-width: 1440px)
//     .index_cont
//         .top_posts
//             height: 480px


// @media (max-width: 1200px)
//     .index_cont
//         .top_posts
//             .part-cont
//                 img
//                     min-height: 100%




.loader {
    display: inline-block;
    margin-top: 2px;
    margin-right: 10px;
    border-top: 1px solid #222;
    border-right: 1px solid #222;
    border-bottom: 1px solid #999;
    border-left: 1px solid #999;
    -webkit-animation: load1 1.1s infinite linear;
    animation: load1 1.1s infinite linear; }

.loader,.loader:after {
    border-radius: 50%;
    width: 10px;
    height: 10px; }
.load-more-end {
    @include fs(16);
    color: #909090;
    text-align: center;
    margin: 15px 0;
    display: none; }
@-webkit-keyframes load1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }

@keyframes load1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }



//得得号首页
.pc-ddh-list {
    .list-cont {
        margin: 0 auto !important; }
    .index_cont {
        .main_cont {
            margin-top: 40px;
            .article-list {
                padding-top: 0; } }
        .column {
            margin-bottom: 0; }
        .top_posts {
            width: 100%;
            // height: 520px
            height: auto !important;
            // margin-top: $pc_nav_height
            margin-bottom: 0;
            .part {
                height: 100%; }
            .part-cont {
                img {
                    width: 100%;
                    height: auto !important;
                    // position: absolute
                    // top: 50%
                    // left: 50%
 } }                    // @include transform(-50%,-50%)
            .bottom_banner {
                height: auto;
                right: auto;
                top: auto;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 30px 0 50px;
                background-color: rgba(0,0,0,0.4);
                color: #fff;
                .cont {
                    width: 900px;
                    height: auto;
                    padding: 0;
                    margin: 0 auto;
                    .title {
                        @include fs(30);
                        line-height: 46px;
                        margin-bottom: 20px;
                        max-height: 92px; }
                    .info {
                        position: static; } } } } } }

.banner-share-box {
    position: fixed !important; }
.share-box,.banner-share-box {
    display: none;
    width: 100px;
    @include border-shadow(0,0,4px,2px,rgba(0,0,0,0.1));
    position: absolute;
    top: 36px;
    left: 50%;
    @include transformX(-50%);
    background-color: #fff;
    z-index: 99;
    &::after {
        content: "";
        position: absolute;
        top: -7px;
        right: calc(50% - 7px);
        right: -webkit-calc(50% - 7px);
        right: -moz-calc(50% - 7px);
        width: 13px;
        height: 7px;
        z-index: 4;
        background: url(/public/css/img/dropdown-menu-arrow.svg); }
    .li {
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: #000;
        font-size: 12px;
        span {
            vertical-align: middle;
            i {
                line-height: 18px; } } }
    .icon1 {
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #ccc;
        font-size: xx-small;
        margin-right: 2px;
        margin-left: 10px;
        @include border-radius(50%,50%,50%,50%);
        &.circle-wechat {
            color: #44B549;
            border-color: #44B549; }
        &.circle-weibo {
            color: #e74c3c;
            border-color: #e74c3c; } } }

@keyframes marketsRise {
    from {
        color: $rise; }
    to {
        color: #000; } }
@-webkit-keyframes marketsRise {
    from {
        color: $rise; }
    to {
        color: #000; } }

@keyframes marketsFall {
    from {
        color: $fall; }
    to {
        color: #000; } }
@-webkit-keyframes marketsFall {
    from {
        color: $fall; }
    to {
        color: #000; } }

@keyframes keepRotate {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg); }
    to {
        transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
        -o-transform: rotate(-360deg); } }
@-webkit-keyframes keepRotate {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg); }
    to {
        transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
        -o-transform: rotate(-360deg); } }

// 新版得得作者首页
.author-listCon {
    font-size: 16px;
    color: #000;
    .author-listTitle {
        padding-top: 3px;
        display: flex;
        justify-content: space-between;
        p {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px; }
        div {
            margin-top: 3px;
            span {
                display: inline-block;
                margin-left: 40px;
                line-height: 22px;
                padding-bottom: 10px;
                cursor: pointer;
                &.active {
                    color: $main;
                    border-bottom: 4px solid $main; } } } }
    .author-list {
        margin-top: 20px;
        min-height: 500px;
        display: flex;
        flex-wrap: wrap;
        .list {
            width: 386px;
            height: 241px;
            box-sizing: border-box;
            border: 1px solid #eee;
            border-radius: 2px;
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 20px;
            &:nth-child(3n) {
                margin-right: 0; }
            .list-title {
                height: 56px;
                display: flex;
                justify-content: space-between;
                img {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%; }
                div {
                    flex: 1;
                    margin: 0 10px;
                    .name {
                        margin-top: 7px;
                        font-weight: 500;
                        a {
                            color: #000; } }
                    .identify {
                        display: inline-block;
                        padding: 0 2px 0 4px;
                        background: url(/public/css/img/ddh_tabel_icon1.png) left center no-repeat;
                        background-size: 4px 100%;
                        font-size: 12px;
                        color: $main;
                        vertical-align: middle;
                        em {
                            display: block;
                            height: 14px;
                            line-height: 14px;
                            border: 1px solid #fd8a25;
                            border-left: 0;
                            i {
                                display: block;
                                font-style: normal;
                                transform: scale(0.8, 0.8); } } } }
                button {
                    margin-top: 9px;
                    box-sizing: border-box;
                    width: 56px;
                    height: 22px;
                    border-radius: 11px;
                    font-size: 12px;
                    cursor: pointer;
                    &.status1 {
                        background: #FD8A25;
                        border: none;
                        color: #fff; }
                    &.status2 {
                        background: #fff;
                        border: 1px solid #999;
                        color: #999999; } } }
            .list-content {
                margin: 11px 0;
                height: 88px;
                font-size: 14px;
                color: #666;
                line-height: 20px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden; }
            .list-footer {
                padding-top: 16px;
                border-top: 1px solid #eee;
                font-size: 0;
                span {
                    display: inline-block;
                    width: 33.3%;
                    box-sizing: border-box;
                    text-align: center;
                    color: #000;
                    font-size: 12px;
                    border-right: 1px solid #eee;
                    &:last-child {
	                    border: none; } } } } }
    .author-footer {
        margin-top: 20px;
        margin-bottom: 120px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
            width: 118px;
            height: 34px;
            background: #FD8A25;
            border-radius: 35px;
            color: #fff;
            font-size: 16px;
            line-height: 34px;
            text-align: center;
            text-decoration: none; }
        div {
            flex: 1;
            text-align: right;
            button {
                background: #fff;
                width: 65px;
                height: 30px;
                margin-left: 20px;
                font-size: 12px;
                cursor: pointer;
                &.prev, &.next {
                    color: #000;
                    border: 1px solid #999; }
                &.s1 {
                    color: #999;
                    border: 1px solid #999; }
                &.s2 {
                    color: #000;
                    border: 1px solid #999; } } } } }

.web-main {
    padding-top: 30px;
    .main-left {
        width: 750px;
        margin-right: 30px;
        float: left; }
    .main-right {
        width: 360px;
        float: left; } }

/*行情*/
.home-markets {
    height: 88px;
    margin-bottom: 31px;
    overflow: hidden;
    position: relative;
    .markets-item {
        width: 700px;
        height: 108px;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        ul {
            position: absolute;
            top: 0;
            left: 0;
            white-space: nowrap;
            font-size: 0;
            // margin-left: -15px
            li {
                display: inline-block;
                width: 145px;
                // padding: 0 15px
                padding-right: 30px;
                @include fs(18);
                position: relative;
                vertical-align: top;
                a {
                    display: inline-block;
                    &.fall {
                        color: $fall;
                        i {
                            background-image: url(/public/css/pc-new/fall.png);
                            background-size: cover; } }
                    &.rise {
                        color: $rise;
                        i {
                            background-image: url(/public/css/pc-new/rise.png);
                            background-size: cover; } }
                    .coin-name {
                        display: flex;
                        align-items: center;
                        img {
                            width: 15px; }
                        span {
                            margin: 0 5px;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 600;
                            flex: 1; }
                        i {
                            float: right;
                            width: 14px;
                            height: 14px; } }
                    .coin-exchange {
                        margin-top: 7px;
                        font-weight: 600;
                        span {
                            font-size: 18px;
                            line-height: 25px;
                            padding-right: 5px; }
                        em {
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px; } } } } } }
    .arrow-box {
        font-size: 0;
        margin-top: 32px;
        .arrow-btn {
            display: inline-block;
            width: 14px;
            height: 24px;
            font-size: 22px;
            text-indent: -14px;
            vertical-align: middle;
            color: #ccc;
            cursor: pointer;
            &.current {
                color: #393E46; } }
        .line {
            display: inline-block;
            width: 10px; } } }

/*行情*/
/*轮播图*/
.home-slide {
    height: 330px;
    margin-bottom: 40px;
    .top_posts {
        width: 750px;
        height: 330px;
        overflow: hidden;
        background-color: #333;
        &:hover {
            .slick-arrow:before {
                display: block; } }
        .slick-track {
            height: 100%;
            &:before,&:after {
                display: none; } }
        .part {
            display: inline-block;
            position: relative;
            // overflow: hidden
            font-size: 12px;
            .bg,.shadow {
                width: 110%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                filter: blur(20px); }
            .bg {
                background-size: cover;
                background-position: center; }
            .shadow {
                background-color: rgba(0,0,0,.6); }
            &.slick-active {
                z-index: 999; } }
        .part-cont {
            width: 100%;
            min-width: 750px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            a {
                display: block;
                overflow: hidden;
                &:hover img {
                    transition: all .5s;
                    transform: scale(1.05); } }
            img {
                position: relative;
                z-index: 19; }
            .post_tagnew {
                position: absolute;
                top: 10px;
                left: 20px;
                display: inline-block;
                padding: 1px 10px;
                line-height: 20px;
                font-size: 14px;
                border: 0;
                background-color: $main;
                color: #fff;
                border-radius: 1px;
                z-index: 30; }
            .post_tag {
                position: absolute;
                top: 10px;
                left: 20px;
                display: inline-block;
                padding: 1px 10px;
                line-height: 20px;
                font-size: 14px;
                border: 0;
                background-color: $main;
                color: #fff;
                border-radius: 1px;
                &.post_tag_ad {
                    position: absolute;
                    top: 10px;
                    left: 20px;
                    z-index: 25;
                    width: 48px;
                    height: 22px;
                    line-height: 22px;
                    border-radius: 0;
                    padding: 0;
                    color: #333;
                    background-color: #fff;
                    text-align: center; } }
            .banner-title {
                position: absolute;
                bottom: 20px;
                left: 0;
                z-index: 21;
                width: 710px;
                padding: 0 20px;
                font-size: 0;
                .text {
                    display: -webkit-box;
                    line-height: 30px;
                    font-size: 24px;
                    color: #fff;
                    white-space: normal;
                    max-height: 60px;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis; } }
            .shadows {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 20;
                width: 100%;
                height: 100%;
                background: -webkit-linear-gradient(transparent, #000);
                background: -o-linear-gradient(transparent, #000);
                background: -moz-linear-gradient(transparent, #000);
                background: linear-gradient(transparent, #000);
                opacity: 0.3; } }
        .bottom_banner {
            position: absolute;
            top: 0;
            left: 500px;
            width: 300px;
            height: 100%;
            color: #fff;
            overflow: hidden;
            .cont {
                width: 100%;
                height: 100%;
                padding: 20px 20px 13px;
                margin: 0 auto;
                position: relative;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box; }
            .title {
                margin-bottom: 15px;
                @include fs(20);
                line-height: 28px;
                white-space: normal;
                text-align: justify;
                max-height: 84px;
                overflow: hidden;
                word-break: break-word;
                a {
                    color: #fff; } }
            .desc {
                p {
                    white-space: normal;
                    @include fs(14);
                    line-height: 20px;
                    max-height: 100px;
                    overflow: hidden;
                    text-align: justify;
                    font-weight: 100;
                    a {
                        color: #fff; } } }
            .info {
                width: 100%;
                color: #f2f2f2;
                @include fs(14);
                position: absolute;
                bottom: 0;
                left: 0;
                a {
                    color: #fff;
                    &.margin_0 {
                        margin-right: 0; } }
                .gap_point {
                    margin: 0 5px; }
                .post_data {
                    padding: 20px 20px 10px;
                    font-size: 0;
                    position: relative; } }
            .mask {
                width: 120%;
                height: 120%;
                background-color: #000;
                opacity: 0.6;
                position: absolute;
                top: -10%;
                left: -10%;
                filter: blur(10px); }
            .bg {
                width: 320px;
                max-width: none;
                left: -10px; }
            .fun-box {
                display: inline-block;
                @include fs(12);
                color: #aaa;
                line-height: 17px;
                text-align: center;
                vertical-align: bottom;
                margin-right: 50px;
                position: relative;
                .icon {
                    display: block;
                    width: 21px;
                    height: 16px; }
                .num {
                    position: absolute;
                    left: 26px;
                    top: 50%;
                    @include transformY(-50%); } }
            .likes {
                margin-bottom: 4px;
                .icon {
                    background: url(/public/css/img/icon_pc_likes_fff.png) no-repeat;
                    background-size: 100% 100%; }
                &.current {
                    color: $main_blue;
                    .icon {
                        background: url(/public/css/img/icon_pc_likes_curr.png) no-repeat;
                        background-size: 100% 100%; } } }
            .number-reads {
                position: absolute;
                bottom: 13px;
                right: 20px;
                margin: 0;
                cursor: auto;
                .icon {
                    background: url(/public/css/img/icon_pc_read_fff.png) no-repeat;
                    background-size: 100% 100%;
                    float: left;
                    margin-right: 5px; }
                .num {
                    position: static; } }
            .comment {
                padding: 0 20px 0px;
                margin: 0;
                position: absolute;
                bottom: 13px;
                left: 50%;
                @include transformX(-50%);
                .icon {
                    width: 18px;
                    height: 24px;
                    background: url(/public/css/img/icon_pc_share_fff.png) no-repeat;
                    background-size: 100% 100%; }
                &:hover {
                    .share-box {
                        display: block; } } } }
        .slick-dots {
            width: 170px;
            height: 2px;
            bottom: 10px;
            left: 20px;
            font-size: 0;
            text-align: left;
            // &::after
            //     content: ""
            //     display: block
            //     width: 100%
            //     height: 100%
            //     position: absolute
            //     bottom: 0
            //     left: 0
            //     background: url(/public/css/img/banner_bg.png) no-repeat
            //     background-size: 150% 150%
            //     background-position: center center
            //     opacity: 0.35
            li {
                width: 30px;
                height: 2px;
                margin: 0;
                margin-right: 5px;
                position: relative;
                z-index: 99;
                &:last-child {
                    margin: 0; }
                button {
                    padding: 0;
                    width: 30px;
                    height: 2px;
                    background-color: #fff;
                    font-size: 0;
                    margin: 0;
                    &:before {
                        display: none !important; } }
                &.slick-active {
                    width: 30px;
                    button {
                        width: 30px;
                        background-color: $main_blue; } } } }
        .slick-list {
            height: 100%; }
        .slick-arrow {
            z-index: 1;
            width: 20px;
            height: 34px;
            &::before {
                display: none;
                content: "";
                width: 20px;
                height: 34px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                opacity: 1 !important; }
            &.slick-prev {
                left: 20px;
                &::before {
                    background: url(/public/css/img/slick_prev@2x.png) no-repeat;
                    background-size: 20px 34px; } }
            &.slick-next {
                right: 20px;
                &::before {
                    background: url(/public/css/img/slick_next@2x.png) no-repeat;
                    background-size: 20px 34px; } } } } }

/*轮播图*/
/*热门文章*/
.hot-news {
    margin-bottom: 40px;
    h2 {
        padding-top: 10px;
        font-size: 24px;
        line-height: 33px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 14px; }
    .first-pic {
        display: block;
        margin-bottom: 25px;
        width: 750px;
        height: 270px;
        overflow: hidden;
        position: relative;
        img {
            width: 750px;
            height: 270px;
            transition: all .5s; }
        &:hover img {
            transform: scale(1.1); }
        span {
            position: absolute;
            top: 20px;
            left: 20px;
            display: inline-block;
            width: 46px;
            height: 21px;
            line-height: 21px;
            background: #FC6243;
            border-radius: 1px;
            color: #fff;
            font-size: 12px;
            text-align: center;
            z-index: 1; }
        p {
            position: absolute;
            left: 20px;
            bottom: 20px;
            width: 710px;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            z-index: 1; }
        div {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, #000, #000);
            opacity: 0.56; } }
    ul li {
        padding: 15px 0 20px;
        border-bottom: 1px solid #eee;
        height: 163px;
        display: flex;
        &:last-child {
            border: none;
            padding-bottom: 0; }
        section {
            width: 204px;
            height: 163px;
            overflow: hidden;
            position: relative;
            span {
                position: absolute;
                top: 5px;
                left: 5px;
                display: inline-block;
                width: 46px;
                height: 21px;
                line-height: 21px;
                background: #FC6243;
                border-radius: 1px;
                color: #fff;
                font-size: 12px;
                text-align: center; } }
        img {
            width: 204px;
            height: 163px;
            border-radius: 2px;
            transition: all .5s;
            &:hover {
                transform: scale(1.1); } }
        div {
            flex: 1;
            margin-left: 20px;
            position: relative;
            h4 a {
                max-height: 64px;
                line-height: 32px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-bottom: 10px;
                &:hover {
                    color: $main; } }
            .hotnews-content {
                font-size: 12px;
                color: #666;
                text-align: justify;
                max-height: 63px;
                line-height: 21px;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                &:hover {
                    color: $main; } }
            .hotnews-info {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 0;
                color: #999;
                line-height: 17px;
                span {
                    font-size: 12px; }
                .tag {
                    font-size: 12px;
                    color: $main;
                    padding-right: 10px; }
                .authors {
                    font-size: 12px;
                    padding-right: 5px;
                    color: #999; }

                span:nth-last-child(2) {
                    &:before {
                        display: inline-block;
                        vertical-align: 2px;
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ccc;
                        margin-right: 5px; } }
                span:last-child {
                    float: right; } } } }
    button {
        display: block;
        border: none;
        margin: 40px auto 125px;
        width: 184px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        background: $main_blue;
        border-radius: 1px;
        color: #ffffff;
        font-size: 16px; } }
.main-leftTitle {
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 3px solid #393E46;
    b {
        font-size: 24px;
        line-height: 33px;
        color: #333;
        font-weight: 500; }
    a {
        color: #999;
        font-size: 14px;
        font-weight: 400;
        &:hover {
            color: $main;
            i {
                background-image: url(/public/css/pc-new/new-icon5hover.png); } }
        i {
            display: inline-block;
            width: 10px;
            height: 11px;
            margin-left: 5px;
            background-image: url(/public/css/pc-new/new-icon5.png);
            background-size: cover; } }
    /*热门文章*/ }
/*中间位置广告*/
.center-ad {
    margin-bottom: 40px;
    overflow: hidden;
    a {
        display: block;
        float: left;
        width: 365px;
        height: 146px;
        overflow: hidden;
        &:first-child {
            margin-right: 20px; }
        img {
            border-radius: 2px;
            transition: all .5s;
            &:hover {
                transform: scale(1.1); } } } }
/*中间位置广告*/
/*独家*/
.exclusive-news {
    margin-bottom: 40px;
    ul {
        overflow: hidden;
        li {
            float: left;
            width: 365px;
            margin-right: 20px;
            padding: 15px 0 20px;
            border-bottom: 1px solid #eee;
            box-sizing: border-box;
            position: relative;
            height: 173px;
            &:nth-child(2n) {
                margin: 0; }
            &:last-child, &:nth-last-child(2) {
                border: none;
                padding-bottom: 0; }
            h4 a {
                line-height: 26px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-bottom: 14px;
                &:hover {
                    color: $main; } }
            .exclusivenews-content a {
                height: 45px;
                font-size: 12px;
                color: #666;
                line-height: 21px;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                &:hover {
                    color: $main; } }
            .exclusivenews-info {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 20px;
                font-size: 0;
                color: #999;
                line-height: 17px;
                .authors {
                    font-size: 12px;
                    color: #999;
                    padding-right: 5px; }
                span {
                    font-size: 12px; }
                span:nth-last-child(2) {
                    &:before {
                        display: inline-block;
                        vertical-align: 2px;
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ccc;
                        margin-right: 5px; } }
                span:last-child {
                    float: right; } } } } }
/*独家*/
/*专题*/
.special-news {
    margin-bottom: 40px;
    ul {
        display: flex;
        margin-top: 15px;
        li {
            width: 236px;
            margin-right: 21px;
            padding-bottom: 20px;
            box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
            border-radius: 1px;
            &:last-child {
                margin: 0; }
            a {
                display: block; }
            div {
                width: 236px;
                height: 146px;
                overflow: hidden;
                img {
                    width: 236px;
                    height: 146px;
                    transition: all .5s;
                    &:hover {
                        transform: scale(1.1); } } }
            p {
                margin: 15px 15px 0;
                color: #333;
                font-size: 16px;
                font-weight: 500;
                height: 44px;
                line-height: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                &:hover {
                    color: $main; } } } } }
/*专题*/
.dialogue-news ul li {
    width: 172px;
    padding-bottom: 20px;
    div {
        width: 172px;
        height: 123px;
        img {
            width: 172px;
            height: 123px; } }
    p {
        margin: 10px 15px 0;
        font-size: 14px; } }
/*底部广告*/
.bottom-ad {
    margin-bottom: 40px;
    a {
        display: block;
        width: 750px;
        height: 146px;
        overflow: hidden;
        img {
            transition: all .5s;
            &:hover {
                transform: scale(1.1); } } } }
/*底部广告*/
/*吐槽大会*/
.row-news {
    margin-bottom: 40px;
    display: flex;
    h3 {
        height: 30px;
        b {
            font-size: 22px; } }
    .row-con {
        width: 50%;
        &:first-child {
            margin-right: 20px; }
        ul li {
            height: 44px;
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            &:last-child {
                border: none; }
            a {
                color: #333;
                height: 44px;
                line-height: 22px;
                font-weight: 500;
                font-size: 16px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                &:hover {
                    color: $main; } } } } }
/*吐槽大会*/
/*CHAINDD News*/
.chaindd-news {
    margin-bottom: 40px;
    div {
        display: flex;
        padding: 15px 0;
        .chaindd-imgnews {
            display: block;
            width: 235px;
            height: 203px;
            overflow: hidden;
            img {
                transition: all .5s;
                width: 235px;
                height: 203px;
                &:hover {
                    transform: scale(1.1); } } }
        section {
            margin-left: 20px;
            flex: 1;
            p {
                font-size: 16px;
                color: #333;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                line-height: 22px;
                height: 44px;
                &:hover {
                    color: $main; } }
            ul li {
                margin-top: 15px;
                a {
                    font-size: 14px;
                    color: #333;
                    display: flex;
                    align-items: baseline;
                    &:hover {
                        color: $main; }
                    &:before {
                        content: '';
                        display: inline-block;
                        margin-right: 10px;
                        width: 8px;
                        height: 8px;
                        background: $main; }
                    span {
                        flex: 1;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        line-height: 19px;
                        height: 38px; } } } } } }
/*CHAINDD News*/
/*风险提示*/
.index-warning {
    display: block;
    border: 1px solid #eee;
    border-top: none;
    padding: 13px 15px 24px;
    margin-bottom: 30px;
    margin-top: 3px;
    position: relative;
    &:before {
        content: '';
        display: block;
        height: 3px;
        width: 360px;
        background-color: #333;
        position: absolute;
        top: -3px;
        left: -1px; }
    div {
        height: 21px;
        line-height: 21px;
        img {
            width: 16px;
            vertical-align: bottom; }
        b {
            font-weight: 500;
            font-size: 16px;
            color: #333; }
        span {
            float: right;
            font-size: 12px;
            color: #999;
            line-height: 20px;
            &:hover {
                color: $main; }
            &:before {
                content: "";
                display: inline-block;
                height: 1px;
                width: 25px;
                background: #ddd;
                vertical-align: 4px;
                margin-right: 5px; } } }
    p {
        margin-top: 10px;
        font-size: 14px;
        color: #333;
        line-height: 20px;
        letter-spacing: 0.26px;
        &:hover {
            color: $main; } } }
/*风险提示*/
/*右侧广告位*/
.right-ad {
    margin-bottom: 40px;
    overflow: hidden;
    .articleLink {
        display: inline-block;
        float: left;
        width: 170px;
        height: 170px;
        position: relative;
        overflow: hidden;
        img {
            transition: all .5s; }
        &:hover img {
            transform: scale(1.1); }
        div {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, #000, #000);
            opacity: 0.56; }
        p {
            position: absolute;
            left: 10px;
            bottom: 10px;
            width: 150px;
            line-height: 18px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden; } }
    .articleLinkleft {
        margin-right: 20px; }
    .rightAD {
        display: inline-block;
        margin-top: 20px;
        width: 360px;
        height: 140px;
        overflow: hidden;
        img {
            transition: all .5s;
            width: 360px;
            height: 140px; }
        &:hover img {
            transform: scale(1.1); } } }
/*右侧广告位*/
/*快捷入口*/
.simple-entrance {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    a {
        width: 175px;
        height: 46px;
        line-height: 46px;
        box-sizing: border-box;
        border: 1px solid $main;
        border-radius: 1px;
        color: $main;
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
        &:nth-child(2n) {
            margin-left: 10px; }
        i {
            display: inline-block;
            vertical-align: -3px;
            width: 18px;
            height: 18px;
            margin-right: 5px;
            background-image: url(/public/css/pc-new/new-icon1.png);
            background-size: cover; }
        &:nth-child(2) i {
            background-image: url(/public/css/pc-new/new-icon2.png); }
        &:nth-child(3) i {
            background-image: url(/public/css/pc-new/new-icon3.png); }
        &:nth-child(4) i {
            background-image: url(/public/css/pc-new/new-icon4.png); }
        &:hover {
            background: $main;
            color: #fff;
            transition: all .5s;
            i {
                transition: all .5s; } }
        &:nth-child(1):hover i {
            background-image: url(/public/css/pc-new/new-icon1hover.png); }
        &:nth-child(2):hover i {
            background-image: url(/public/css/pc-new/new-icon2hover.png); }
        &:nth-child(3):hover i {
            background-image: url(/public/css/pc-new/new-icon3hover.png); }
        &:nth-child(4):hover i {
            background-image: url(/public/css/pc-new/new-icon4hover.png); } } }
/*快捷入口*/
// BTC减半
@font-face {
    font-family: Akrobat;
    src: url(/public/css/pc-new/Akrobat-ExtraBold.otf); }
.transition {
	-webkit-transition: top 400ms linear {
    -moz-transition: top 400ms linear; }
	-ms-transition: top 400ms linear {
    -o-transition: top 400ms linear; }
	transition: top 400ms linear; }
.timeTo {
    height: 77px;
    padding: 8px 15px 0px;
    font-family: Akrobat;
    line-height: 108%;
    font-weight: 800; }
.timeTo-white div {
    color: $main; }
.timeTo div {
    position: relative;
    display: inline-block;
    overflow: hidden; }
.timeTo ul {
    position: absolute; }
.btc-halve {
    h3 span {
        font-size: 14px;
        color: #999; }
    .btc-halve-tip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        padding: 0 37px;
        span {
            color: #999;
            font-size: 14px;
            line-height: 20px; } }
    .btc-halve-list li {
        height: 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #666;
        padding: 0 15px;
        &:nth-child(2n) {
            margin-bottom: 20px; }
        &:last-child {
            margin-bottom: 15px; }
        b {
            font-style: normal;
            font-weight: 400;
            color: #333; } } }
// BTC减半
/*热点快讯*/
.common-container {
    margin-top: 3px;
    margin-bottom: 43px;
    border: 1px solid #eee;
    border-top: none;
    position: relative;
    &:before {
        content: '';
        display: block;
        height: 3px;
        width: 360px;
        background-color: #333;
        position: absolute;
        top: -3px;
        left: -1px; } }
.nictaion-list {
    ul {
        padding: 0 15px;
        li {
            padding: 15px 0 14px;
            border-bottom: 1px solid #eee;
            &:last-child {
                border: none; }
            a {
                display: block;
                &:hover p {
                    color: $main; } }
            span {
                font-size: 12px;
                color: #666;
                line-height: 17px; }
            p {
                margin-top: 2px;
                font-size: 14px;
                color: #333;
                line-height: 24px;
                height: 48px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden; } } } }
.main-rightTitle {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom: 1px solid #eee;
    b {
        font-size: 16px;
        color: #333;
        font-weight: 500; }
    a {
        color: #999;
        font-size: 14px;
        font-weight: 400;
        i {
            display: inline-block;
            width: 10px;
            height: 11px;
            margin-left: 5px;
            background-image: url(/public/css/pc-new/new-icon5.png);
            background-size: cover; }
        &:hover {
            color: $main;
            i {
               background-image: url(/public/css/pc-new/new-icon5hover.png); } } } }
/*热点快讯*/
/*最新文章*/
.uptodate-news {
    ul {
	    padding: 0 15px; }
    li {
        padding: 15px 0;
        border-bottom: 1px solid #eee;
        &:last-child {
            border: none; }
        a {
            display: block;
            height: 79px;
            display: flex;
            &:hover p {
                color: $main; } }
        section {
            width: 100px;
            height: 79px;
            overflow: hidden; }
        img {
            width: 100px;
            height: 79px;
            border-radius: 2px;
            transition: all .5s;
            &:hover {
                transform: scale(1.1); } }
        div {
            flex: 1;
            margin-left: 15px;
            p {
                font-size: 14px;
                color: #333;
                height: 44px;
                line-height: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-bottom: 12px; }
            span {
                font-size: 12px;
                color: #999;
                line-height: 23px; } } } }
/*最新文章*/
/*右侧广告*/
.right-center-ad {
    margin-bottom: 40px;
    a {
        display: block;
        width: 360px;
        height: 270px;
        overflow: hidden;
        img {
            transition: all .5s;
            &:hover {
                transform: scale(1.1); } } } }
/*右侧广告*/
/*得得号作者*/
.ddh-authors {
    h3 {
        a {
            i {
                display: inline-block;
                vertical-align: -2px;
                width: 14px;
                height: 14px;
                margin-right: 5px;
                background-image: url(/public/css/pc-new/new-icon6.png);
                background-size: cover; }
            &:hover {
                color: #999;
                i {
                    background-image: url(/public/css/pc-new/new-icon6.png); } } } }
    ul {
        padding: 0 15px;
        li {
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            height: 60px;
            display: flex;
            &:last-child {
                border: none; }
            a {
                display: block;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    transition: all .5s;
                    border-radius: 50%;
                    &:hover {
                        transform: scale(1.1); } } }
            div {
                margin-left: 10px;
                width: 258px;
                p:first-child {
                    height: 20px;
                    margin-top: 6px;
                    span {
                        display: inline-block;
                        width: 70%;
                        overflow: hidden;
                        font-size: 14px;
                        color: #333;
                        line-height: 20px;
                        &:hover {
                            color: $main; } } }
                p:first-child button {
                    float: right;
                    width: 60px;
                    height: 25px;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid;
                    border-radius: 2px;
                    background: #fff;
                    margin-top: -7px;
                    cursor: pointer;
                    &:hover {
                        background: $main;
                        color: #fff; } }
                .unfollow {
                    color: #999;
                    border-color: #aaa;
                    &:hover {
                        background: $main;
                        color: #fff; } }
                .follow {
                    color: $main;
                    border-color: $main; }
                p:last-child {
                    width: 263px;
                    margin-top: 10px;
                    font-size: 12px;
                    color: #666;
                    line-height: 17px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:hover {
                        color: $main; } } } } } }
/*得得号作者*/
/*活动*/
.activity-list {
    ul {
        padding: 0 15px;
        li {
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            &:last-child {
                border: none; }
            .activity-list-link {
                display: block;
                height: 48px;
                line-height: 24px;
                font-size: 14px;
                color: #333;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                &:hover {
                    color: $main; } }
            p {
                margin-top: 5px;
                font-size: 12px;
                line-height: 17px;
                color: #666;
                a {
                    color: #666; }
                span:last-child {
	                float: right; } } } } }
/*活动*/
/*相关信息*/
.relative-information {
    div {
        height: 50px;
        line-height: 50px;
        padding: 0 15px; }
    a {
        color: #333;
        font-size: 14px;
        &:last-child:before {
            content: '';
            display: inline-block;
            width: 2px;
            height: 16px;
            margin: 0 10px;
            background: #aaa;
            vertical-align: -3px; }
        &:hover {
            color: $main; } } }
/*相关信息*/
/*合作*/
.cooperation-ways {
    h3 {
        margin-bottom: 15px; }
    p {
        height: 21px;
        line-height: 21px;
        padding: 0 15px;
        font-size: 0;
        color: #333;
        margin-bottom: 15px;
        span {
            display: inline-block;
            width: 62px;
            font-size: 14px; }
        a {
            font-size: 16px;
            font-weight: bold;
            color: #333;
            display: inline-block;
            margin-left: 8px; }
        em {
            font-style: normal;
            font-size: 14px; } } }
/*合作*/
