.account_content {
    width: 700px;
    margin: 0 auto;
    padding-top: 156px;
    padding-bottom: 80px;
    background-color: #FFF;
    min-height: 100vh;
    .title {
        @include fs(24);
        margin-bottom: 80px; }
    &.hidden {
        max-height: 100vh;
        overflow: hidden; }
    .part {
        margin-bottom: 30px;
        .state_pannel {
            background: #FFFFFF;
            border-radius: 3px;
            color: #596679;
            line-height: 28px;
            overflow: hidden;
            padding-bottom: 30px;
            border-bottom: 1px solid #F2F2F2;
            .notice {
                p {
                    font-size: 1.6rem;
                    color: #666666;
                    line-height: 28px;
                    .notice_time {
                        font-weight: bold; } } }
            .state {
                line-height: 56px; }
            .red_border {
                display: inline-block;
                margin-right: 20px;
                width: 2px;
                height: 11px;
                background: #FD7A6C;
                box-shadow: 2px 0 4px 0 rgba(253,122,108,0.50); }
            .text {
                font-size: 1.4rem;
                line-height: 28px;
                margin-right: 20px; }
            .value {
                font-size: 20px;
                color: #21B890;
                line-height: 28px; } }
        .no_open {
            text-align: center;
            border: 1px solid #F2F2F2;
            border-radius: 2px;
            .no_open_txt {
                margin-bottom: 30px;
                font-size: 2.0rem;
                color: #333333;
                letter-spacing: 1.67px;
                line-height: 32px;
                padding-top: 40px; }
            .no_open_btn {
                padding: 15px 30px;
                background: #00A4A0;
                border-radius: 3px;
                line-height: 16px;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 24px;
                display: inline-block;
                margin-bottom: 40px; } }
        .mod-tit {
            font-size: 1.8rem;
            color: #333333;
            margin-bottom: 20px;
            line-height: 20px; }
        .serve {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &:after {
                content: "";
                width: 340/1080*100%; }
            li {
                position: relative;
                padding: 29px 0 28px 40px;
                margin-bottom: 20px;
                background: #FFFFFF;
                border: 1px solid #E6E9EA;
                border-radius: 3px;
                width: 340/1080*100%;
                min-width: 180px;
                &:hover {
                    .left_border {
                        display: block; }
                    .small_border {
                        display: none; } }
                .left_border {
                    display: none;
                    position: absolute;
                    width: 2px;
                    height: 60px;
                    background: $main;
                    box-shadow: 2px 0 4px 0 rgba(33,184,143,0.50);
                    top: 10px;
                    left: 0; }
                .small_border {
                    position: absolute;
                    width: 2px;
                    height: 12px;
                    background: $main;
                    box-shadow: 2px 0 4px 0 rgba(33,184,143,0.50);
                    top: 34px;
                    left: 0; }
                a {
                    color: #38454A;
                    i {
                        margin-right: 20px;
                        font-size: 20px; }
                    .i_text {
                        font-size: 1.4rem;
                        line-height: 14px;
                        vertical-align: super; } } } } }

    .account {
        .account_box {
            padding-bottom: 30px;
            &.no_bottom {
                padding-bottom: 0; }
            .no_result {
                padding: 45px 0;
                font-size: 14px;
                color: #546369; }
            .mod_tit {
                margin-bottom: 15px;
                font-size: 1.8rem; }
            .account_banner {
                border-radius: 3px;
                overflow: hidden;
                padding-top: 50px;
                .infor {
                    li {
                        font-size: 1.6rem;
                        color: #666666;
                        line-height: 29px;
                        overflow: hidden;
                        margin-bottom: 15px;
                        &:first-child {
                            padding-top: 0; }
                        &:last-child {
                            padding-bottom: 0;
                            border-bottom: none; }
                        label, span {
                            font-size: 1.6rem;
                            color: #666666;
                            font-weight: normal; }
                        .input_profile {
                            width: 500px;
                            // height: 20px
                            border: none;
                            outline: none;
                            margin-bottom: 0; }
                        .icon-arrow-r {
                            display: none; }
                        .btn_box {
                            float: right;
                            button {
                                background-color: #fff;
                                border-radius: 3px;
                                font-size: 1.3rem;
                                &.btn {
                                    // width: 56px
                                    // height: 30px
                                    padding: 7px 14px;
 }                                    // line-height: 30px
                                &.edit_btn {
                                    border: 1px solid #d8d8d8;
                                    color: #909090;
                                    font-size: 14px;
                                    border-radius: 40px;
                                    line-height: 14px;
                                    height: auto; }
                                &.save_btn {
                                    // margin-right: 20px
                                    // background-color: $main
                                    border: 1px solid #00A4A0;
                                    color: #00A4A0;
                                    font-size: 1.4rem;
                                    border-radius: 40px;
                                    line-height: 14px;
                                    height: auto;
                                    width: auto;
                                    display: inline-block;
                                    float: left;
                                    margin-right: 20px; }
                                &.cancel_btn {
                                    // border: 1px solid #8CA4AF
                                    // color: #8CA4AF
                                    border: 1px solid #d8d8d8;
                                    color: #909090;
                                    font-size: 1.4rem;
                                    border-radius: 40px;
                                    line-height: 14px;
                                    height: auto;
                                    width: auto;
                                    display: inline-block; } } } } }
                .consume {
                    li {
                        padding: 17px 0;
                        border-bottom: 1px solid #d9d9d9;
                        font-size: 0;
                        &:last-child {
                            padding-bottom: 0;
                            border-bottom: none; }
                        &.title {
                            padding-bottom: 20px;
                            border-bottom-color: #d9d9d9;
                            margin-bottom: 0;
                            .line_style {
                                color: #666666 !important; }
                            span {
                                line-height: inherit;
                                color: #808080; } }
                        span {
                            display: inline-block;
                            line-height: 30px;
                            font-size: 1.3rem;
                            color: #808080; }
                        .order_id {
                            display: none; }
                        .time {
                            width: 22%;
                            color: #808080; }
                        .project {
                            width: 30%; }
                        .money {
                            width: 17%; }
                        .down_link {
                            width: 18%;
                            a {
                                color: #00A4A0;
                                text-decoration: underline; } }
                        .invoice {
                            width: 13%;
                            button {
                                border: 1px solid $main;
                                border-radius: 3px;
                                background: #fff;
                                color: $main;
                                &.btn {
                                    width: 82px;
                                    height: 30px;
                                    padding: 0 14px;
                                    line-height: 30px;
                                    font-size: 13px; } } } } }
                .consume_mobile {
                    display: none; } } } }
    .reminder {
        font-size: 1.4rem;
        color: #666666;
        line-height: 24px;
        a {
            color: #546369; } } }
.dashboard {
    .top {
        .first {
            color: #546369 !important;
            line-height: 20px; } } }
.account_pop {
    .invoice_application {
        width: 720px;
        margin: 0 auto;
        margin-top: 50px;
        h2 {
            padding-bottom: 15px;
            line-height: 24px;
            font-size: 2.4rem;
            color: #38454A; }
        p {
            padding-bottom: 10px;
            font-size: 1.4rem;
            color: #546369;
            &.lang {
                padding-bottom: 40px; } }
        .invoice_card_box {
            padding-top: 20px;
            border-top: 1px solid #CCD7DB;
            .title {
                display: block;
                padding-bottom: 30px;
                font-size: 1.4rem;
                color: #546369; }
            .invoice_card {
                position: relative;
                label {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 20px;
                    &.radio_la {
                        width: auto;
                        margin-right: 40px; }
                    b {
                        display: inline-block;
                        width: 103/720*100%;
                        margin-right: 42px;
                        text-align: right;
                        line-height: 36px;
                        font-size: 1.4rem;
                        color: #546369;
                        font-weight: normal;
                        &.align {
                            vertical-align: top; }
                        &.noright {
                            margin-right: 0; } }
                    input[type="radio"] {
                        margin-right: 5px; }
                    input[type="text"] {
                        width: 555/720*100%;
                        height: 36px;
                        background: #FFFFFF;
                        padding-left: 10px;
                        border: 1px solid #D0D4D9;
                        border-radius: 1px;
                        color: #8CA4AF;
                        &.recipients {
                            width: 160/720*100%; }
                        &.mobile {
                            width: 200/720*100%; }
                        &.area {
                            width: 300/720*100%;
                            cursor: pointer; }
                        &.email {
                            width: 280/720*100%; } }
                    textarea {
                        width: 550/720*100%;
                        height: 80px;
                        //resize: none
                        padding: 10px;
                        background: #FFFFFF;
                        border: 1px solid #D0D4D9;
                        border-radius: 1px;
                        color: #8CA4AF; }
                    .star {
                        margin-left: 5px;
                        margin-right: 30px;
                        vertical-align: top;
                        line-height: 36px;
                        color: #E73140; }
                    .btn_box {
                        width: 30%;
                        display: inline-block;
                        .save_invoice_btn {
                            width: 138px;
                            height: 38px;
                            border: 1px solid $main;
                            border-radius: 3px;
                            line-height: 33px;
                            font-size: 1.2rem;
                            color: #fff;
                            background-color: #00A4A0;
                            cursor: pointer;
                            outline: none; } }
                    .goods_rare {
                        width: 160/720*100%;
                        height: 36px;
                        margin-bottom: 0; }
                    .icon-down4 {
                        position: relative;
                        left: -45px;
                        font-size: 2rem;
                        vertical-align: middle;
                        color: #8E9EA4;
                        cursor: pointer;
                        z-index: 1; } } } } }
    .invoice_confirm {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        p {
            padding-bottom: 110px;
            font-size: 1.6rem;
            color: #546369;
            font-weight: bold; }
        .btn {
            width: 86px;
            height: 38px;
            line-height: 38px; } } }

.pop_up {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-bottom: 40px;
    background-color: rgba(255,255,255,0.98);
    overflow: scroll;
    z-index: 11;
    .close {
        position: absolute;
        top: 30px;
        right: 10%;
        font-size: 2rem;
        color: #00A4A0;
        cursor: pointer; } }
@media screen and (max-width: 760px) {
    .dashboard {
        .top {
            display: none; }
        .part {
            padding-top: 20px;
            .mod-tit {
                display: none; }
            .mod-pannel.state_pannel {
                position: relative;
                padding-bottom: 55px !important;
                .state.fr {
                    position: absolute;
                    float: none;
                    bottom: 18px;
                    width: 87%; } }
            .state_pannel p {
                line-height: 26px; } }
        .part.pa_ser {
            padding-top: 4px;
            margin-bottom: 20px;
            .serve {
                padding: 0 10/300*100%;
                li {
                    min-width: 125px;
                    width: 125/300*100%;
                    padding: 25px 14px 10px 14px;
                    background: #FFFFFF;
                    box-shadow: 0 0 6px 0 rgba(33,184,143,0.40);
                    border-radius: 1px;
                    &:hover {
                        .left_border {
                            display: none; }
                        .small_border {
                            display: block; } }
                    .left_border {
                        width: 100/125*100%;
                        height: 2px;
                        bottom: 0px;
                        top: inherit;
                        left: auto;
                        background: $main;
                        box-shadow: 0 -2px 4px 0 rgba(33,184,143,0.20); }
                    .small_border {
                        width: 18px;
                        height: 2px;
                        bottom: 0;
                        top: inherit;
                        background: $main;
                        box-shadow: 0 -2px 4px 0 rgba(33,184,143,0.30);
                        left: 53.5/125*100%; }

                    a {
                        color: #344750;
                        i,.i_text {
                            margin-right: 0;
                            display: block;
                            text-align: center; }
                        i {
                            margin-bottom: 20px; } } } } }
        .back_tmt {
            background: #FFFFFF;
            border: 1px solid $main;
            border-radius: 3px;
            padding: 8px 14/320*100%;
            width: 112/300*100%;
            display: block;
            margin: 0 auto 20px;
            text-align: center;
            white-space: nowrap; }
        .footer_bottom {
            display: block;
            width: 112/300*100%;
            margin: 0 auto;
            text-align: center;
            font-size: 1.2rem;
            color: #666666;
            letter-spacing: 0; }
        .container {
            .mod-pannel.state_pannel {
                padding: 20/300*100%; } } } }

@media (max-width: 760px) {
    .container_a {
        padding-left: 20/320*100%;
        padding-right: 20/320*100%;
        padding-top: 1px;
        .mod_tit {
            font-size: 1.6rem;
            line-height: 20px;
            color: #546369;
            margin-bottom: 10px;
            margin-top: 20px;
            .mod_tit_right {
                display: none; } }
        .mod_pannel {
            background-color: #fff;
            color: #000;
            padding: 30px;
            margin-bottom: 20px; } }
    .pop_up {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin-bottom: 40px;
        background-color: rgba(255,255,255,.98);
        overflow: scroll;
        z-index: 11;
        .close {
            position: absolute;
            top: 30px;
            right: 10%;
            font-size: 2.0rem;
            color: $main;
            cursor: pointer; }
        .pop_cont {
            width: 720px;
            margin: auto;
            padding: 160px 0; } }
    .account_content {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        .top {
            display: none; }
        .part {
            margin-top: 20px;
            margin-bottom: 30px;
            .remain_pannel {
                p.remain {
                    color: #E73140;
                    font-size: 1.4rem;
                    margin-bottom: 5px; }
                label {
                    line-height: 20px; }
                .clean {
                    // overflow: hidden
                    line-height: 20px;
                    list-style-type: decimal;
                    padding-left: 15px;
                    li {
                        margin-bottom: 10px; } }
                .nums {
                    color: #E73140;
                    font-size: 3.2rem;
                    font-weight: bold;
                    margin-bottom: 20px; }
                .option {
                    margin-bottom: 20px;
                    .knowlege {
                        margin-top: 20px;
                        font-weight: bold;
                        a {
                            text-decoration: underline; } } } }
            .state_pannel {
                padding: 20px;
                background: #FFFFFF;
                border: 1px solid #E6E9EA;
                border-radius: 3px;
                color: #596679;
                line-height: 28px;
                overflow: hidden;
                .notice {
                    float: none;
                    p {
                        font-size: 1.4rem;
                        color: #596679;
                        line-height: 26px; } }
                .option {
                    margin-top: 20px; }
                .state {
                    float: none;
                    position: relative;
                    display: block;
                    margin-top: 20px;
                    line-height: 28px;
                    .red_border {
                        display: inline-block;
                        margin-right: 20px;
                        width: 2px;
                        height: 11px;
                        background: #FD7A6C;
                        box-shadow: 2px 0 4px 0 rgba(253,122,108,0.50); }
                    .text {
                        font-size: 1.4rem;
                        line-height: 28px;
                        margin-right: 20px; }
                    .value {
                        position: absolute;
                        right: 0;
                        font-size: 20px;
                        color: $main;
                        line-height: 28px; } } }
            .no_open {
                .no_open_txt {
                    font-size: 1.4rem;
                    line-height: 20px;
                    margin-bottom: 15px;
                    padding: 0; }
                .no_open_btn {
                    padding: 10px 20px;
                    font-size: 12px;
                    line-height: 16px;
                    border-radius: 0;
                    margin-bottom: 0; } }
            .mod-tit {
                font-size: 1.4rem;
                color: #546369;
                margin-bottom: 10px;
                line-height: 20px; }
            .serve {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                &:after {
                    content: "";
                    width: 340/1080*100%; }
                li {
                    position: relative;
                    padding: 29px 0 28px 40px;
                    margin-bottom: 20px;
                    background: #FFFFFF;
                    border: 1px solid #E6E9EA;
                    border-radius: 3px;
                    width: 340/1080*100%;
                    min-width: 180px;
                    &:hover {
                        .left_border {
                            display: block; } }
                    .left_border {
                        display: none;
                        position: absolute;
                        width: 2px;
                        height: 60px;
                        background: $main;
                        box-shadow: 2px 0 4px 0 rgba(33,184,143,0.50);
                        top: 10px;
                        left: 0; }
                    .small_border {
                        position: absolute;
                        width: 2px;
                        height: 12px;
                        background: $main;
                        box-shadow: 2px 0 4px 0 rgba(33,184,143,0.50);
                        top: 34px;
                        left: 0; }
                    a {
                        color: #38454A;
                        i {
                            margin-right: 20px;
                            font-size: 20px; }
                        .i_text {
                            font-size: 1.4rem;
                            line-height: 14px;
                            vertical-align: super; } } } } }

        .account {
            .account_box {
                padding-bottom: 30px;
                &.no_bottom {
                    padding-bottom: 0; }
                .account_banner {
                    background-color: #F3F5F7;
                    border: none;
                    padding: 0;
                    overflow: hidden;
                    .infor {
                        background-color: #FFFFFF;
                        padding: 0 10px;
                        border: 1px solid #E6E9EA;
                        border-radius: 3px;
                        li {
                            padding: 27px 10px;
                            border-bottom: 1px solid #CCD7DB;
                            line-height: 30px;
                            overflow: hidden;
                            &:first-child {
                                padding-top: 27px; }
                            &:last-child {
                                padding-bottom: 27px;
                                border-bottom: none; }
                            label, span {
                                font-size: 1.4rem;
                                color: #38454A; }
                            .input_profile {
                                width: 500px;
                                // height: 20px
                                border: none;
                                outline: none;
                                margin-bottom: 0; }
                            .icon-arrow-r {
                                display: block;
                                float: right;
                                line-height: 30px;
                                font-size: .8rem; }
                            .btn_box {
                                display: none;
                                float: right;
                                button {
                                    background-color: #fff;
                                    border-radius: 3px;
                                    font-size: 1.3rem;
                                    &.btn {
                                        width: 56px;
                                        height: 30px;
                                        padding: 0 14px;
                                        line-height: 30px; }
                                    &.edit_btn {
                                        border: 1px solid $main;
                                        color: $main; }
                                    &.save_btn {
                                        margin-right: 20px;
                                        background-color: $main; }
                                    &.cancel_btn {
                                        border: 1px solid #8CA4AF;
                                        color: #8CA4AF; } } } } }
                    .consume {
                        display: none; }
                    .consume_mobile {
                        display: block;
                        background-color: #FFFFFF;
                        margin-bottom: 11px;
                        border: 1px solid #E6E9EA;
                        border-radius: 3px;
                        .title {
                            background: #F7F9FA;
                            padding: 25px 18px;
                            border-bottom: none;
                            border-radius: 3px 3px 0 0;
                            line-height: 18px;
                            font-size: 1.6rem;
                            color: #38454A;
                            font-weight: bold;
                            margin-bottom: 0; }
                        ul {
                            padding: 0 10px;
                            li {
                                padding: 27px 10px;
                                border-bottom: 1px solid #CCD7DB;
                                line-height: 30px;
                                overflow: hidden;
                                a {
                                    color: #00A4A0;
                                    text-decoration: underline; }
                                &:last-child {
                                    border-bottom: none; }
                                .order_id {
                                    display: none; }
                                span {
                                    letter-spacing: 1px;
                                    font-size: 1.4rem;
                                    color: #38454A;
                                    font-weight: bold; }
                                button {
                                    border: 1px solid $main;
                                    border-radius: 3px;
                                    background: #fff;
                                    color: $main;
                                    font-size: 1.3rem;
                                    &.btn {
                                        width: auto;
                                        height: 30px;
                                        padding: 0 14px;
                                        line-height: 30px; } } } } } } } }
        .reminder {
            display: none; } }

    .account_pop {
        .user_infor {
            margin-top: 50px;
            padding: 20px;
            h2 {
                text-align: center;
                font-size: 2rem;
                color: #38454A; }
            .user_card {
                margin-top: 30px;
                label {
                    b {
                        display: block;
                        width: 100%;
                        font-size: 1.4rem;
                        color: #546369; }
                    input {
                        width: 100%;
                        height: 36px;
                        margin-top: 10px;
                        padding-left: 10px; }
                    .save_btn {
                        display: block;
                        width: 86px;
                        height: 38px;
                        background-color: #fff;
                        margin: 0 auto;
                        margin-top: 30px;
                        border: 1px solid $main;
                        border-radius: 3px;
                        font-size: 1.3rem;
                        color: $main; } } } }
        .invoice_application {
            width: 100%;
            margin-top: 50px;
            padding: 20px;
            h2 {
                padding-bottom: 20px;
                text-align: center;
                line-height: 24px;
                font-size: 2rem;
                color: #38454A; }
            p {
                padding-bottom: 10px;
                text-align: center;
                font-size: 1.4rem;
                color: #546369;
                &.lang {
                    padding-bottom: 40px; } }
            .invoice_card_box {
                padding-top: 20px;
                border-top: none;
                .title {
                    display: none;
                    padding-bottom: 30px;
                    font-size: 1.4rem;
                    color: #546369; }
                .invoice_card {
                    position: relative;
                    &.address {
                        margin-top: -20px; }

                    label {
                        //position: absolute
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 20px;
                        &.radio_la {
                            text-align: center;
                            margin-right: 0;
                            width: 45%; }
                        b {
                            display: inline;
                            width: 100%;
                            margin-bottom: 13px;
                            text-align: left;
                            line-height: 14px;
                            font-size: 1.4rem;
                            color: #546369;
                            font-weight: normal;
                            &.align {
                                vertical-align: middle; }
                            &.seat {
                                display: none; } }
                        .star {
                            display: inline;
                            vertical-align: middle; }
                        input[type="text"] {
                            width: 100%;
                            height: 36px;
                            &.recipients {
                                width: 100%; }
                            &.mobile {
                                width: 100%; }
                            &.area {
                                width: 100%;
                                cursor: pointer; }
                            &.email {
                                width: 100%;
                                margin-top: 5px; } }
                        textarea {
                            width: 100%;
                            height: 80px;
                            resize: none; }
                        .btn_box {
                            position: relative;
                            left: 50%;
                            transform: translate(-50%, 0);
                            width: 138px;
                            height: 38px;
                            display: inline-block;
                            margin: 0 auto;
                            margin-top: 30px;
                            .save_invoice_btn {
                                width: 100%;
                                height: 100%;
                                background-color: #FFFFFF;
                                border: 1px solid $main;
                                border-radius: 3px;
                                font-size: 1.3rem;
                                color: $main; } }
                        .icon-down4 {
                            position: relative;
                            top: -30px;
                            left: 88%;
                            font-size: 2rem;
                            vertical-align: middle;
                            color: #8E9EA4;
                            cursor: pointer;
                            z-index: 1; } } } } }
        .invoice_confirm {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            p {
                padding-bottom: 110px;
                line-height: 22px;
                font-size: 1.6rem;
                color: #546369; } } } }
._citys {
    width: 300px !important;
    background-color: #FFF;
    border: 1px solid #D0D4D9;
    box-shadow: 0 -1px 2px rgba(0,0,0,.1);
    #cColse {
        display: none; }
    ._citys0 {
        border-bottom: 1px solid #CCD7DB;
        height: 45px;
        padding-bottom: 11px;
        li {
            margin-bottom: 11px; }
        li.citySel {
            background-color: $main; } }
    ._citys1 a {
        width: 78px;
        background-color: transparent;
        color: #546369;
        height: 20px;
        line-height: 20px;
        font-size: 1.4rem;
        margin-top: 6px;
        padding: 0 8px;
        text-align: left;
        &:hover {
            background-color: #D3F1E9;
            color: #546369; } } }
.m_confirm_order {
    ._citys {
        width: 88vw !important; } }
