.tag-trend {
    width: 980px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    .see-all {
        text-align: center;
        clear: both;
        a {
            color: #909090;
            @include fs(14);
            line-height: 14px; } }
    .trend-list {
        width: 460px;
        text-align: center;
        margin-bottom: 80px;
        color: $black;
        .tag-list,.th {
            text-align: left; }
        .tit {
            @include fs(24);
            line-height: 25px;
            margin-bottom: 23px; }
        .intro {
            color: #b3b3b3;
            @include fs(14);
            line-height: 15px;
            margin-bottom: 40px; }
        .th {
            font-size: 0;
            background-color: #F6F6F6;
            padding-left: 20px;
            span {
                @include fs(14);
                line-height: 46px;
                display: inline-block; }
            .tt {
                width: 135px; }
            .ta {
                width: 130px; }
            .tf {
                width: 175px; } }
        .tag-list li {
            line-height: 60px;
            border-bottom: 1px #F2F2F2 solid;
            padding: 0 0 0 20px;
            font-size: 0;
            span {
                display: inline-block;
                @include fs(14);
                color: #808080;
                text-overflow: ellipsis;
                a {
                    color: #808080; } }
            .tag-name {
                width: 140px; }
            .f-num {
                width: 120px; }
            .options {
                width: 180px;
                .btn {
                    margin-right: 15px; }
                span.follow-num {
                    @include fs(12); } } } } }


.all-tags {
    width: 980px;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
    .title {
        @include fs(24);
        line-height: 25px;
        margin-bottom: 53px; }
    nav {
        background-color: #F6F6F6;
        display: inline-block;
        padding: 0 1px;
        margin-bottom: 50px;
        display: none;
        a {
            display: inline-block;
            text-decoration: none;
            color: #8D8D8D;
            @include fs(14);
            line-height: 46px;
            padding: 0 44px;
            cursor: pointer; }
        .current {
            color: $orange;
            &:hover {
                color: $orange-hover; } }
        .gap-link {
            display: inline-block;
            height: 13px;
            width: 1px;
            background-color: #D7D7D7;
            vertical-align: middle; } }

    .tag-tree {
        text-align: left;
        width: 700px;
        margin: 0 auto;
        .tit {
            padding-bottom: 10px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 25px;
            span {
                border-left: 4px $orange solid;
                line-height: 21px;
                display: inline-block;
                padding-left: 11px;
                color: $orange;
                @include fs(16); } }
        .tit-nav {
            padding-bottom: 20px;
            a {
                color: #8d8d8d;
                @include fs(16);
                line-height: 18px;
                margin-right: 45px;
                &.current,&:hover {
                    color: $orange; } } }
        .tag-group {
            margin-bottom: 30px; }
        .list {
            font-size: 0;
            margin-left: -15px; }
        li {
            display: inline-block;
            font-size: 0;
            margin: 0 0 19px 15px;
            a {
                @include fs(14); } }
        .load-more {
            text-align: center;
            margin-bottom: 70px; } }
    .left-part {
        width: 280px;
        .tags-part {
            margin-bottom: 50px;
            text-align: left;
            .tit {
                @include fs(16);
                line-height: 22px;
                padding-bottom: 16px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 20px; }
            .intro {
                color: #666666; } }
        li {
            line-height: 43px;
            i {
                margin-right: 10px;
                vertical-align: middle; }
            span {
                display: inline-block; }
            .name {
                @include fs(13);
                width: 150px;
                white-space: nowrap;
                text-overflow: ellipsis; }
            .btn {
                margin-top: 6px; } } } }
.home-tags {
    width: 640px;
    margin: 80px auto 0;
    padding-top: 62px;
    .title {
        text-align: center;
        padding-bottom: 53px;
        @include fs(24);
        line-height: 25px;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 30px; }
    .following-tags {
        font-size: 0;
        margin-left: -20px;
        li {
            display: inline-block;
            margin: 0 0 20px 20px; } }
    .reminder {
        font-size: 16px;
        font-size: 1.6rem;
        color: #b3b3b3;
        line-height: 21px;
        margin: 60px 0 200px;
        text-align: center; } }


.lang-tags {
    width: 640px;
    margin: 0 auto;
    padding-top: 30px;
    .title {
        @include fs(24);
        line-height: 25px;
        padding-bottom: 53px;
        border-bottom: 1px solid #F2F2F2; }
    .mod-article-list {
        li {
            min-height: 135px;
            .award-status {
                display: inline-block;
                padding: 2px 8px;
                background: $main;
                border-radius: 50px;
                color: white;
                margin-left: 10px;
                &.ended {
                    background-color: #b2b2b2; } } }

        .cont {
            width: 410px;
            h2 {
                @include fs(24);
                a {
                    color: $black; } }
            .intro {
                color: #808080; }

            p {
                line-height: 1.6em;
                margin-bottom: 15px;
                @include fs(16);
                color: #808080; } } } }
.pc-columns {
    // padding-top: 165px
    .colu-hr-top {
        border: 1px solid $main_blue;
        width: 300px;
        margin-top: 60px; }
    .colu-company {
        font-size: 2.4rem;
        color: #333333;
        line-height: 30px;
        text-align: center;
        margin: 30px auto 20px;
        padding-bottom: 0;
        border-bottom: 0px; }
    .colu-p {
        font-size: 1.6rem;
        color: #666666;
        line-height: 24px;
        text-align: center;
        padding-bottom: 30px; }
    .colu-hr-bot {
        border: 1px solid $main_blue;
        width: 300px;
        margin-bottom: 40px; } }









