.main-container {
    width: 1140px;
    margin: 0 auto; }
$mainTop: #fff;
/*头部*/
.home-index-ad {
    display: block;
    width: 100%;
    height: 149px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #000;
    background-size: cover;
    img {
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: 538px;
        width: 12px;
        padding: 10px; } }
header .header {
    position: relative;
    height: 60px;
    background: #393E46;
    font-size: 0;
    .logo {
        height: 60px;
        float: left;
        a {
            display: block;
            margin-top: 18px;
            img {
                width: 77px; } } }
    .header-right {
        display: inline-block;
        margin-left: 64px;
        width: 999px;
        .nav-link {
            float: left;
            li {
                float: left;
                height: 60px;
                line-height: 60px;
                margin-right: 30px;
                .nav-link-a {
                    display: inline-block;
                    color: #D7D8DA;
                    font-size: 16px;
                    font-weight: 500; }
                &:hover {
                    .nav-link-a {
                        color: $mainTop; }
                    .zixun-down {
                        display: block; } } }
            .current {
                box-sizing: border-box;
                border-bottom: 3px solid $main;
                .nav-link-a {
                    color: $mainTop; } } }
        // .zixun
        //     i
        //         display: inline-block
        //         vertical-align: 1px
        //         margin-left: 5px
        //         width: 9px
        //         height: 7px
        //         background-image: url(/public/css/pc-new/new-down1.png)
        //         background-size: contain
        //     &:hover
        //         .zixun-down
        //             display: block
        //         i
        //             background-image: url(/public/css/pc-new/new-down2.png)
        // .kuaixun:hover .kuaixun-down
        //     display: block
        .user-con {
            float: right;
            .open-ddh {
                float: left;
                display: inline-block;
                // margin-left: 132px
                margin-top: 16px;
                width: 98px;
                height: 26px;
                line-height: 24px;
                border-radius: 2px;
                font-size: 14px;
                text-align: center;
                color: $main;
                border: 1px solid $main;
                &:hover {
                    color: #fff;
                    background: $main; } }
            .go-write {
                float: left;
                display: inline-block;
                // margin-left: 174px
                margin-top: 16px;
                width: 58px;
                height: 26px;
                line-height: 26px;
                border-radius: 2px;
                font-size: 14px;
                text-align: center;
                color: $main;
                border: 1px solid $main;
                &:hover {
                    color: #fff;
                    background: $main; } }
            .search {
                float: left;
                margin-left: 30px;
                margin-top: 21px;
                width: 16px;
                cursor: pointer; }
            .user-opt {
                float: left;
                height: 60px;
                line-height: 60px;
                display: inline-block;
                margin-left: 20px;
                color: #999;
                a {
                    font-size: 14px;
                    color: #999;
                    &:hover {
                        color: #fff; } }
                .login:after {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 10px;
                    background: #999;
                    height: 16px;
                    width: 1px; } }
            .user-info {
                float: left;
                height: 60px;
                margin-left: 25px;
                display: flex;
                align-items: center;
                color: #999;
                cursor: pointer;
                position: relative;
                .user-pic {
                    width: 36px;
                    border-radius: 50%; }
                .hasnew {
                    position: absolute;
                    top: 16px;
                    left: 30px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: $main;
                    display: none; }
                div.user-info-right {
                    margin-left: 5px;
                    color: #999;
                    p {
                        font-size: 12px;
                        line-height: 17px;
                        margin-bottom: 2px; }
                    span {
                        font-size: 10px;
                        color: $main; }
                    .user-status {
                        width: 61px; } } }
            .language {
                margin-left: 30px;
                float: right;
                height: 60px;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                .nowlanguage {
                    width: 29px;
                    height: 20px; }
                i {
                    display: inline-block;
                    margin-left: 6px;
                    width: 8px;
                    height: 6px;
                    background-image: url(/public/css/pc-new/new-down1.png);
                    background-size: contain; }
                &:hover i {
                    background-image: url(/public/css/pc-new/new-down2.png); }
                &:hover ul {
                    display: block; }
                ul {
                    position: absolute;
                    top: 60px;
                    left: -21px;
                    z-index: 11;
                    width: 121px;
                    height: 86px;
                    background: #fff;
                    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
                    padding: 15px;
                    box-sizing: border-box;
                    display: none;
                    li {
                        font-size: 14px;
                        color: #333;
                        margin-bottom: 15px;
                        &:hover {
                            color: $main; }
                        img {
                            width: 30px;
                            vertical-align: middle;
                            margin-right: 5px; } } } } } }
    .search-con {
        display: none;
        margin-left: 275px;
        width: 435px;
        margin-top: 15px;
        form {
            display: flex; }
        input {
            width: 358px;
            height: 30px;
            padding: 5px 10px;
            box-sizing: border-box;
            background: #5C636D;
            border: none;
            color: #D7D8DA;
            font-size: 14px;
            border-bottom-left-radius: 1px;
            border-top-left-radius: 1px; }
        button {
            height: 30px;
            width: 77px;
            box-sizing: border-box;
            background: $main;
            color: #fff;
            border: none;
            cursor: pointer;
            border-top-right-radius: 1px;
            border-bottom-right-radius: 1px;
            img {
                width: 14px;
                vertical-align: -2px;
                margin-right: 3px; } } } }
.zixun-down {
    box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.05);
    position: absolute;
    width: 100%;
    background: #ffffff;
    top: 60px;
    left: 0;
    z-index: 10;
    padding: 25px 0;
    line-height: normal;
    display: none;
    section {
        overflow: hidden;
        margin-left: 141px;
        .nav-link2 {
            display: inline-block;
            &:hover {
                .nav-link3 {
                    display: block; }
                .nav-link2-a {
                    color: $main;
                    font-weight: 500;
                    i {
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-bottom: 7px solid $main;
                        border-top: none; } } }
            .nav-link2-a {
                display: inline-block;
                margin-right: 60px;
                height: 22px;
                line-height: 22px;
                font-size: 16px;
                color: #333;
                i {
                    display: inline-block;
                    margin-left: 5px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 7px solid #666;
                    border-bottom: none;
                    vertical-align: 3px; } } }
        .nav-link3 {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            padding-top: 45px;
            z-index: -1;
            width: 100%;
            padding-bottom: 15px;
            background: #fff;
            box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.05);
            section {
                margin-top: 20px;
                left: 0;
                top: 42px;
                width: 600px;
                display: flex;
                flex-wrap: wrap; }
            a {
                display: inline-block;
                width: 70px;
                float: left;
                margin-right: 30px;
                margin-bottom: 10px;
                line-height: 20px;
                font-size: 14px;
                color: #666;
                &:hover {
                    color: $main; } } }
        .cur {
            color: $main;
            font-weight: 500; } } }
.kuaixun-down {
    box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.05);
    position: absolute;
    height: 59px;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    top: 60px;
    left: 0;
    z-index: 10;
    display: none;
    div {
        height: 59px;
        display: flex;
        align-items: center; }
    a {
        display: inline-block;
        box-sizing: border-box;
        height: 59px;
        line-height: 59px;
        margin-right: 40px;
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        i {
            display: inline-block;
            vertical-align: -3px;
            margin-right: 4px;
            width: 18px;
            height: 18px;
            background-size: cover; }
        &:first-child i {
            background-image: url(/public/css/pc-new/new-icon11.png); }
        &:nth-child(2) i {
            background-image: url(/public/css/pc-new/new-icon12.png); }
        &:last-child i {
            background-image: url(/public/css/pc-new/new-icon13.png); }
        &:first-child:hover i, &.cur1 i {
            background-image: url(/public/css/pc-new/new-icon11hover.png); }
        &:nth-child(2):hover i, &.cur2 i {
            background-image: url(/public/css/pc-new/new-icon12hover.png); }
        &:last-child:hover i, &.cur3 i {
            background-image: url(/public/css/pc-new/new-icon13hover.png); }
        &:hover {
            color: $main;
            border-bottom: 5px solid $main; } }
    .cur {
        color: $main;
        border-bottom: 5px solid $main; } }
/*头部*/


.first-nav-bom {
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.08) !important;
    padding: 0 !important;
    height: 46px !important;
    line-height: 46px !important;
    ul {
        height: 46px !important;
        line-height: 44px !important; }
    .main_cont {
        width: 1200px;
        margin: 0 auto;
        height: auto; }
    li {
        margin-right: 80px !important; }
    .deNum {
        float: right;
        .reference {
            height: 46px !important; }
        .post-edit {
            color: #fff; }
        .ddh-entrance {
            margin-right: 0px !important; }
        .btn.btn-x-small.orange.post-edit {
            margin-left: 30px !important; }
        .orange.btn {
            background: #fff;
            color: $dd_main_color;
            border: 1px solid $dd_main_color; } } }
.first-nav-bom-en {
    height: 46px !important;
    background: #EFC364 !important;
    padding: 0 0 !important;
    .main_cont {
        position: relative;
        padding-top: 0px !important;
        height: 100% !important;
        .first_nav_box {
            height: 100% !important; }
        ul {
            height: 100% !important;
            line-height: 59px !important; }
        li {
            line-height: 46px !important;
            height: 100% !important;
            margin-right: 40px !important;
            a {
                font-size: 16px !important;
                color: #2D2D2D !important; } }
        li.current a {
            color: #AA5910 !important; }
        li.current::after {
            background-color: #AA5910 !important; }
        .deNum {
            position: absolute;
            right: 0px;
            top: 0;
            height: 46px;
            .ddh-entrance {
                height: 46px;
                line-height: 46px;
                &:after {
                    display: none; }
                .reference {
                    height: 46px; }
                .btn {
                    background: #2D2D2D !important;
                    font-size: 12px; } }
            .btn.btn-x-small.orange.post-edit {
                margin-top: -12px;
                background: #2D2D2D !important;
                color: #fff;
                font-size: 12px; } } } }
.first-nav-cn {
    padding: 0 !important;
    height: 66px !important;
    .options {
        margin-left: 30px !important;
        position: absolute !important;
        right: 177px !important; }
    .get_app {
        margin-left: 30px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        color: #b3b3b3;
        font-size: 0;
        line-height: 30px;
        vertical-align: middle;
        float: right;
        cursor: pointer !important;
        height: 66px;
        line-height: 63px;
        position: absolute;
        right: 80px;
        span {
            font-size: 16px;
            color: #666;
            vertical-align: middle;
            font-weight: 400; }
        i {
            vertical-align: middle;
            color: $main_blue;
            margin-right: 10px;
            font-size: 20px; }
        .goAppIcon {
            display: inline-block;
            width: 14px;
            height: 21px;
            background: url(/public/css/img/go-app-icon@2x.png) no-repeat;
            background-size: 14px 21px; }
        .app_qrcode {
            position: absolute;
            top: 62px;
            right: -55px;
            background-color: transparent;
            width: 151px;
            height: 180px;
            background: #fff;
            .qrcode_box {
                width: 150px;
                height: 181px;
                background-color: #fff;
                box-shadow: 0 1px 4px rgba(0,0,0,0.14);
                text-align: center;
                position: relative;
                z-index: 1;
                .arrow {
                    display: block;
                    width: 13px;
                    height: 7px;
                    background: url(/public/css/img/dropdown-menu-arrow.svg);
                    position: absolute;
                    top: -7px;
                    left: 50%;
                    transform: translateX(-50%); }
                .code_img {
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    margin: 20px 0; }
                .a_tit {
                    margin: 0;
                    padding: 0;
                    line-height: 18px;
                    @include fs(16);
                    color: $dd_main_color;
                    &.down {
                        @include fs(12); } } } }
        &:hover {
            .app_qrcode {
                display: block; } } }
    .language-box {
        display: inline-block !important;
        margin-left: 30px !important;
        vertical-align: top !important;
        font-size: 0 !important;
        float: right !important;
        height: 66px !important;
        line-height: 66px !important;
        position: absolute;
        right: 0px;
        a {
            width: auto !important;
            display: inline-block;
            line-height: 66px !important;
            height: 66px !important;
            vertical-align: middle;
            text-align: center;
            font-size: 16px !important;
            color: #666666;
            font-weight: 400;
            border: 0 !important; } }

    .logo-part {
        line-height: 64px !important; }
    .main_conts {
        width: 1200px;
        margin: 0 auto;
        height: 66px;
        position: relative; } }
.first-nav-en {
    height: 142px !important;
    padding: 0 0px !important;
    .main_conts {
        height: 100%;
        position: relative;
        width: 1200px;
        margin: 0 auto; }
    .logo-part {
        margin-right: 86px !important;
        a {
            margin-top: 42px; } } }
.chaindd-part {
    position: absolute;
    right: 0;
    top: 0;
    .language-box {
        height: 51px !important;
        display: block !important;
        a {
            border: 0 !important;
            width: auto !important;
            height: 22px !important;
            float: right;
            padding-top: 27px; } }
    .app_down_box {
        display: block !important;
        height: auto !important;
        margin-right: 0px !important;
        line-height: 20px !important;
        padding-top: 10px;
        position: relative;
        &::after {
            display: none;
            content: "";
            background: url("/public/css/img/icon/white.png");
            background-size: 100% 100%;
            width: 108px;
            height: 113px;
            position: absolute;
            top: 39px;
            left: 26px;
            z-index: 1; }
        &:hover {
            &::after {
                display: block; } } }
    .submit-press {
        color: #fff;
        font-size: 14px;
        color: #fff;
        line-height: 20px;
        padding-top: 10px;
        text-align: right; } }
.part-login {
    .common-nav {
        .first-nav {
            ul {
                li {
                    height: 40px !important;
                    line-height: 40px !important;
                    a {
                        color: #333 !important;
                        font-size: 14px !important; } } } } }
    .dropdown-menu-part {
        position: absolute;
        left: 870px;
        height: 142px !important;
        line-height: 142px !important;
        .user-dropdown {
            // width: 123px !important
            left: -0px !important; }
        .dropdown-menu {
            border-radius: 0 !important;
            box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.1) !important;
            top: 108px !important;
            height: 175px !important; }
        .dropdown-menu::before {
            width: 0 !important; }
        .user-dropdown {
            padding: 5px 0 0 0; }
        .setting {
            height: 142px !important;
            line-height: 147px !important;
            .avatar {
                .new {
                    right: 2px !important;
                    top: -11px !important; }
                img {
                    width: 42px !important;
                    height: 42px !important; } }
            .userDdh {
                .nameBox {
                    position: relative;
                    display: table;
                    &:after {
                        content: "";
                        width: 0;
                        height: 0;
                        border: 4px solid transparent;
                        border-top-color: #fff;
                        position: absolute;
                        top: 6px;
                        right: -10px;
                        margin-left: 5px; } }
                i {
                    font-size: 14px !important;
                    max-width: 76px !important;
                    overflow: inherit !important;
                    position: relative; }
                i.table {
                    background: none !important;
                    border: 1px solid #EFC364 !important;
                    color: #EFC364 !important;
                    border-radius: 0 !important;
                    padding: 4px 5px !important;
                    font-size: 12px !important; }
                i.reviewTable {
                    border: 0 !important;
                    color: #E63C51 !important;
                    padding: 5px 0 0 !important; } } }
        .unlogin {
            a {
                color: #fff !important;
                font-size: 14px; }
            .line {
                height: 20px !important;
                border-right: 2px solid #eee !important;
                opacity: 0.3; } }
        .userDdh {
            i {
                color: #fff; } } } }
.set-inner {
    position: absolute;
    border: 1px solid #D8D8D8;
    padding: 15px 0 0;
    background-color: #fff;
    @include rounded(5px);
    a {
        color: $c_color;
        &:hover {
            color: $s_color; } }
    li {
        padding: 0 25px;
        line-height: 25px;
        margin-bottom: 15px;
        @include fs(14); }
    .d-bd {
        width: 0;
        position: absolute;
        display: inline-block;
        *display: inline;
        border-width: 10px;
        border-style: solid;
        border-color:  transparent transparent #D8D8D8 transparent;
        &:after {
            left: -8px;
            top: -6px;
            position: absolute;
            content: ' ';
            display: inline-block;
            *display: inline;
            border-width: 8px;
            border-style: solid;
            border-color:  transparent transparent #fff transparent; } } }
.common-nav {
    a {
        color: #666666;
        &:hover {
            color: $s_color; } }
    li {
        padding: 10px 25px;
        @include fs(14); } }


.android-nav {
    width: 150px;
    li {
        width: 170px;
        margin-left: -10px;
        padding: 10px 0px;
        text-align: center;
        &:hover {
            background-color: #21B890;
            a {
                color: #fff; }
            a:hover {
                color: #fff; } } } }

.qrcode-cont {
    text-align: center;
    padding: 10px;
    h4 {
        @include fs(18);
        line-height: 19px;
        margin-bottom: 9px; } }


.tag-nav {
    width: 400px;
    padding: 15px 15px 0;
    line-height: 15px;
    a {
        vertical-align: middle;
        display: inline-block;
        *display: inline;
        padding-right: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        color: $c_color;
        &:hover {
            color: $s_color; }
        &.last,&:last-child {
            border: none; } } }





// 头部

.p-header-hide {
    -webkit-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
    // -webkit-transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190)
    // -moz-transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190)
    // -o-transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190)
    transition: all 0ms cubic-bezier(0.550, 0.055, 0.675, 0.190); }

.p-header div.second-nav-small {
    height: 49px;
    line-height: 50px;
    background-color: #F3F5F6;
    -webkit-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -moz-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -o-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    .logo {
        display: none; }
    .columns {
        line-height: 50px;
        .column-icon {
            display: inline-block;
            *display: inline; }
        .current-read .tit {
            margin-top: 12px; } }
    .sort-by {
        .tit {
            margin-top: 12px; } }
    .column-title {
        margin-right: 92px; }
    .reference {
        height: 49px; } }
.p-header div.second-nav-large {
    height: 60px;
    line-height: 60px;
    background-color: #fff;
    -webkit-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -moz-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    -o-transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    transition: all 300ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
    .logo {
        display: inline-block;
        *display: inline; }
    .columns {
        line-height: 61px; } }
.p-header-show {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    // -webkit-transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000)
    // -moz-transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000)
    // -o-transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000)
    transition: all 0ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }
.p-header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100px;
    color: #909090;
    &.search-show {
        .first_nav_box {
            opacity: 0; }
        .first-nav {
            .options {
                width: 100%;
                position: relative;
                z-index: 99;
                .nav-search {
                    margin-left: 181px;
                    .search_btn_hide {
                        display: none; } }
                .formBox {
                    width: 537px !important; }
                form {
                    width: 480px !important;
                    transform: translateX(0px) !important;
                    opacity: 1 !important; } } } }
    .wrapperBox {
        display: inline-block;
        float: right; }
    .reference {
        display: inline-block;
        width: 0;
        height: $pc_nav_height;
        vertical-align: middle; }
    .first-nav {
        white-space: nowrap;
        background-color: #F3F5F6;
        color: #666666;
        height: 40px;
        line-height: 40px;
        padding: 0 50px;
        ul,li {
            display: inline-block;
            *display: inline; }
        li {
            margin-right: 45px; }
        li.last {
            margin-right: 60px; }
        a {
            color: #666666;
            @include fs(14);
            &.nav_title {
                @include fs(16); } }
        .left-c {
            width: 540px;
            height: 100%; }
        nav,.nav-search {
            vertical-align: top;
            display: inline-block;
            *display: inline;
            &.first_nav_box {
                float: left; } }
        .nav-search {
            vertical-align: top;
            position: absolute;
            left: 458px;
            top: 2.5px;
            input {
                display: inline-block;
                width: 100%;
                line-height: 22px;
                padding: 0;
                border: 0;
                border-bottom: 1px solid #979797;
                background-color: transparent;
                border-radius: 0;
                font-size: 14px;
                background-color: #fff;
                border: 1px #ccc solid;
                @include rounded(4px);
                height: 22px;
                width: 233px;
                vertical-align: middle;
                color: #000;
                border-radius: 4px;
                padding: 9px;
                padding-left: 15px;
                padding-right: 30px; }
            .search {
                position: relative;
                left: -25px;
                top: 1px;
                @include fs(15);
                vertical-align: middle;
                border: none;
                padding: 0;
                background-color: transparent;
                i {
                    // color: #b3b3b3
                    color: #ccc;
                    font-size: 19px;
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    &:hover {
                        color: #808080; } } } }
        .options {
            .btn {
                color: #fff;
                font-weight: normal; } }
        .current {
            a {
                color: $orange;
                &:hover {
                    color: $orange-hover; } } }

        .ddh-entrance {
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            position: relative;
            &:after {
                display: inline-block;
                content: '热';
                position: absolute;
                right: -7px;
                top: 3px;
                width: 22px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                border-radius: 11px;
                background: #FF2A2A;
                color: #fff;
                font-size: 12px; }
            .btn {
                height: 26px;
                line-height: 26px;
                color: #fff;
                font-weight: normal;
                border: 0 !important;
                background-color: $main_blue !important;
                vertical-align: middle; } }
        .dropdown-menu-part {
            vertical-align: middle;
            height: 66px;
            line-height: 68px;
            float: right; }
        .language-box {
            display: inline-block;
            height: $pc_nav_height;
            margin-left: 20px;
            vertical-align: top;
            font-size: 0;
            a {
                display: inline-block;
                width: 26px;
                height: 26px;
                line-height: 26px;
                border: 1px solid #666;
                border-radius: 26px;
                vertical-align: middle;
                text-align: center;
                font-size: 14px;
                color: #666;
                &:hover {
                    color: $main;
                    border-color: $main; } } } }
    .second-nav {
        height: 60px;
        background-color: #fff;
        line-height: 60px;
        overflow: visible;
        border-bottom: 1px solid #E5E5E5;
        border-top: 1px solid #E5E5E5;
        .shares {
            .txt {
                @include fs(14);
                color: #808080;
                margin-right: -5px; }
            [class^="circle"] {
                margin-left: 15px;
                border: 1px #000 solid;
                @include rounded(50%);
                display: inline-block;
                *display: inline;
                width: 40px;
                height: 40px;
                text-align: center;
                i {
                    line-height: 40px;
                    @include fs(18); } }
            .circle-weibo {
                line-height: 40px;
                border-color: #e74c3c;
                i {
                    color: #e74c3c; } }
            .circle-wechat {
                line-height: 40px;
                border-color: #44B549;
                i {
                    color: #8ee566; } }
            .circle-tencent {
                line-height: 40px;
                border-color: #39b2e2;
                i {
                    color: #39b2e2; } } }
        .dropdown-menu-part {
            height: $pc_nav_height;
            .dropdown-menu {
                right: 0px;
                padding: 3px 0px;
                line-height: 32px;
                @include rounded(3px);
                a {
                    display: inline-block;
                    *display: inline;
                    width: 86px;
                    text-align: center;
                    padding-right: 0;
                    margin-right: 0;
                    color: #666666;
                    &:hover {
                        color: $main; } }
                .current {
                    color: $main; }
                &::before {
                    right: calc(50% - 24px);
                    right: -webkit-calc(50% - 24px);
                    right: -moz-calc(50% - 24px); } } }
        .sort-by {
            .tit {
                width: 190px;
                color: gray;
                text-align: center;
                @include fs(13);
                border: 1px solid #d9d9d9;
                line-height: 25px;
                margin-top: 18px;
                @include rounded(3px);
                cursor: pointer; }
            a {
                width: 175px!important;
                text-align: left!important;
                padding: 0 15px; }

            i {
                @include fs(18);
                vertical-align: middle;
                margin-left: 5px; }
            .dropdown-menu {
                &::before {
                    right: calc(50% - 78px);
                    right: -webkit-calc(50% - 78px);
                    right: -moz-calc(50% - 78px); } } }
        .tag-sort-by {
            .tit {
                width: 160px; }
            a {
                width: 146px!important;
                padding: 0 15px; }
            .dropdown-menu {
                &::before {
                    right: calc(50% - 61px);
                    right: -webkit-calc(50% - 61px);
                    right: -moz-calc(50% - 61px); } } }

        .more-tag,.more-product {
            .more-btn {
                .tit {
                    color: #666666;
                    @include fs(14);
                    cursor: pointer;
                    margin-left: 20px;
                    i {
                        @include fs(20);
                        vertical-align: middle; } } }
            .more-product-dropdown {
                padding: 30px 30px 20px;
                width: 390px;
                right: -358px;
                ul {
                    margin-left: -33px;
                    li {
                        display: inline-block;
                        *display: inline;
                        margin-left: 33px; }
                    a {
                        width: 70px;
                        text-align: left; } }
                &::before {
                    right: calc(50% + 142px); } }
            .more-tag-dropdown {
                width: 244px;
                padding: 26px 20px 25px 40px;
                right: -125px;
                .more-tag-nav {
                    display: inline-block;
                    *display: inline;
                    text-align: left;
                    margin-bottom: 10px;
                    vertical-align: top;
                    &:first-child {
                        margin-right: 45px; }
                    .tit {
                        color: #666666;
                        display: inline-block;
                        *display: inline;
                        text-align: center;
                        @include fs(14);
                        padding-bottom: 10px;
                        border-bottom: 2px $main solid;
                        margin-bottom: 7px;
                        line-height: 14px; }
                    ul {
                        a {
                            text-align: left; } } }
                .more-a {
                    line-height: normal;
                    a {
                        color: #B3B3B3;
                        @include fs(12);
                        line-height: normal;
                        &:hover {
                            color: #666; } }
                    .icon {
                        margin-left: 5px;
                        @include fs(15); } } } }
        .more-trail {
            .more-product-dropdown {
                padding: 20px 0px 20px;
                width: 106px;
                left: -12px;
                right: inherit;
                ul {
                    margin: 0;
                    li {
                        margin: 0;
                        display: block;
                        text-align: center; }
                    a {
                        width: auto;
                        padding: 0 25px; } } }
            .dropdown-menu::before {
                right: calc(50% - 36px); } } }
    .inner {
        width: 1200px;
        margin: 0 auto;
        white-space: nowrap; }
    .menu {
        display: inline-block;
        *display: inline;
        margin-left: 11px;
        position: relative;
        .bar {
            vertical-align: middle;
            display: inline-block;
            *display: inline;
            cursor: pointer;
            color: #353A48;
            @include fs(20);
            i {
                @include fs(22);
                position: relative;
                right: -4px;
                top: 2px; } }
        .set-inner {
            width: 137px;
            left: -60px;
            top: 55px;
            .d-bd {
                top: -20px;
                right: 58px; } } }
    .line {
        height: 25px;
        display: inline-block;
        *display: inline;
        vertical-align: middle;
        margin: 0 15px;
        border-right: 1px solid #E5E5E5; }
    .columns {
        line-height: 61px;
        a {
            color: $black; }
        a,.column-title {
            @include fs(14); }
        .read-select {
            margin-right: 45px;
            margin-left: -6px; }
        .orange {
            color: $orange; }
        .unfollow {
            color: #fff; }
        .draft {
            .num {
                color: #666; } }
        .current-read {
            .tit {
                color: #808080;
                text-align: center;
                @include fs(13);
                border: 1px solid #D9D9D9;
                line-height: 25px;
                margin-top: 18px;
                @include rounded(3px);
                width: 86px; }
            i {
                @include fs(18);
                vertical-align: middle;
                margin-left: 5px; } }
        .column-title {
            color: #3A3E3F;
            margin: 0 40px 0 20px;
            @include fs(18);
            span {
                color: #b3b3b3; }
            .tag {
                color: $black; }
            a {
                color: $orange;
                &:hover {
                    color: $orange_hover; } } }
        .column-second-title {
            color: #333333;
            @include fs(14);
            margin-right: 35px; }
        .tag-title {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis; }
        .column-icon {
            display: none;
            margin-right: 3px;
            width: 22px;
            i {
                color: $main;
                @include fs(18);
                position: relative;
                top: 0px;
                vertical-align: middle; }
            .icon-tag2 {
                @include fs(18); }
            .icon-iconfont-present,.icon-bell2 {
                @include fs(18); }
            .icon-help2 {
                @include fs(22); }
            .icon-globe {
                @include fs(18); }
            .icon-read {
                @include fs(18); } }

        .tag-recommend {
            width: 555px;
            text-overflow: ellipsis;
            overflow: hidden; }
        .column-list {
            display: inline-block {
                *display: inline; }
            .tags {
                position: relative;
                i {
                    @include fs(20);
                    color: #b3b3b3;
                    vertical-align: middle;
                    position: relative;
                    top: -1px;
                    left: 2px; }
                .set-inner {
                    width: 400px;
                    padding: 25px;
                    line-height: 15px;
                    top: 50px;
                    left: 50%;
                    margin-left: -230px;
                    .d-bd {
                        top: -20px;
                        left: 50%;
                        margin-left: -5px; }
                    a {
                        vertical-align: middle;
                        @include fs(13);
                        display: inline-block;
                        *display: inline;
                        border-right: 1px solid #D7D7D7;
                        margin-right: 24px;
                        margin-bottom: 15px;
                        &:hover {
                            color: $s_color; }
                        &.last,&:last-child {
                            border: none; } } }
                .visible {
                    display: block; } }

            li {
                display: inline-block;
                *display: inline;
                margin-right: 48px;
                .num {
                    color: $s_color; }
                .nums {
                    background-color: $orange;
                    display: inline-block;
                    *display: inline;
                    *display: inline;
                    line-height: 17px;
                    @include fs(12);
                    @include rounded(50px);
                    transform: scale(0.9,0.9);
                    *font-size: 10;
                    color: #fff;
                    padding: 0 5px;
                    position: relative;
                    top: -1px; }
                a,.a_dropdown {
                    position: relative;
                    display: block;
                    color: #666666;
                    &:hover,&.current {
                        // border-bottom: 3px solid $s_color
                        // line-height: 58px
                        // padding-bottom: 0
                        .avia-menu-fx {
                            opacity: 1;
                            visibility: visible; } } }
                .a_dropdown {
                    .tit {
                        margin-left: 0; } }
                .avia-menu-fx {
                    position: absolute;
                    bottom: -1px;
                    height: 3px;
                    z-index: 2;
                    width: 100%;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    -moz-transition: all 0.3s ease-out;
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                    background-color: $main_blue; } } }

        .nav-list li {
            @include fs(14);
            a {
                color: #333;
                &.current {
                    color: $main; } }

            &:hover,&.current {
                a,.a_dropdown a .tit {
                    color: $orange; }
                .dropdown-menu a {
                    color: #666666; }
                .num {
                    color: $orange; } }
            span.num {
                margin-left: 3px;
                color: #666; }
            .dropdown-menu {
                li:hover,li.current {
                    a {
                        color: $orange; } } } }
        .info-list li {
            color: #808080;
            @include fs(13);
            span.num {
                color: #808080; } }
        .products-list {
            max-width: 595px; } }

    .draft {
        color: $s_color;
        vertical-align: middle;
        @include fs(14);
        .num {
            color: $i_color;
            margin: 0 5px;
            strong {
                font-weight: normal;
                margin: 0 3px; } } }
    .options {
        float: right;
        height: 100%;
        font-size: 0;
        @extend .fr;
        color: $i_color;
        right: 0;
        // .search
        .topic {
            margin-right: 70px;
            display: inline-block;
            *display: inline;
            .txt {
                display: inline-block;
                *display: inline;
                *display: inline;
                vertical-align: middle;
                width: 102px;
                height: 30px;
                line-height: 28px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            .ico {
                @include fs(20);
                color: #b3b3b3;
                vertical-align: middle;
                position: relative;
                top: -1px;
                left: 2px; } }
        .autosave {
            display: inline-block;
            *display: inline;
            // width: 110px
            margin-right: 20px; }
        .confrim-post_open {
            margin-right: 10px; }
        .post-edit {
            @include fs(14);
            margin: 0 30px 0 0; }

        .unlogin {
            color: #b3b3b3;
            a {
                color: #333;
                font-size: 16px;
                &:hover {
                    color: #000; } }
            // text-decoration: underline
            .line {
                margin: -10px 10px 0;
                height: 15px;
                vertical-align: middle;
                border-right: 3px solid #999; } }
        .setting {
            display: inline-block;
            *display: inline;
            position: relative;
            height: 66px;
            line-height: 71px;
            .avatar {
                display: inline-block; }
            .userDdh {
                display: inline-block;
                //vertical-align: middle
                margin-left: 7px;
                position: relative;
                top: -5px;
                vertical-align: middle;
                @include fs(12);
                i {
                    display: block;
                    max-width: 82px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-style: normal;
                    font-weight: normal;
                    height: 18px;
                    line-height: 16px;
                    margin-bottom: 2px;
                    &.table {
                        display: table;
                        padding: 3px 5px;
                        height: 12px;
                        line-height: 12px;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 3px;
                        background-color: $main_blue;
                        border: 1px solid $main_blue; } } }
            .avatar {
                position: relative;
                top: -1px;
                img {
                    width: 30px;
                    height: 30px;
                    @include rounded(50%); }
                .new {
                    display: inline-block;
                    *display: inline;
                    position: absolute;
                    right: -1px;
                    top: 19px;
                    width: 8px;
                    height: 8px;
                    background-color: $orange;
                    border: 1px #fff solid;
                    @include rounded(50%); } }
            .set_arrow {
                display: block;
                width: 0;
                height: 0;
                border-width: 8px 5px 0 5px;
                border-style: solid;
                border-color: #999 transparent transparent transparent;
                position: absolute;
                top: 50%;
                right: -20px;
                transform: translateY(-50%);
                &.curr {
                    border-width: 0 5px 8px 5px;
                    border-color: transparent transparent #999 transparent; } } }
        .pro-sign {
            display: inline-block;
            *display: inline;
            border-radius: 50%;
            position: absolute;
            right: -2px;
            bottom: 9px; }
        .set-inner {
            // width: 150px
            // right: 0
            // top: 68px
            // margin-top: -8px
            // .d-bd
            //     top: -20px
            //     right: 8px
            // .nums
            //     @include rounded(5px)
            //     background-color: $s_color
            //     display: inline-block
            *display: inline; }
        //     height: 16px
        //     line-height: 17px
        //     color: #fff
        //     padding: 0 2px
        //     @include fs(12)
        //     margin-left: 10px
        // li
        //     &.last
        //         margin-bottom: 0
        //         height: 40px
        //         line-height: 40px
        //         background-color: #F9f9f9
        //         color: #b3b3b3

        .topic-select {
            top: initial;
            // bottom: 52px
            position: fixed;
            // width: 220px
            width: 360px;
            .d-bd {
                top: inherit;
                bottom: -20px;
                border-color: #D8D8D8 transparent transparent transparent;
                &:after {
                    top: -10px;
                    border-color: #fff transparent transparent transparent; } } } }


    .pop-close {
        cursor: pointer; }
    .pop-select {
        padding: 0;
        // overflow: hidden
        left: 0px;
        display: none;
        top: 135px;
        color: #666;
        // max-height: 264px
        // overflow-y: auto
        background-color: #fff;
        .d-bd {
            margin-left: -20px;
            left: 50%;
            top: -20px; }
        li {
            word-break: break-all;
            white-space: initial;
            padding: 15px 30px;
            border-bottom: 1px solid #F2F2F2;
            cursor: pointer;
            line-height: 22px;
            margin-bottom: 0;
            &:hover,&.hover {
                color: #fff;
                background-color: $s_color; }
            &:last-child {
                border: none; }
            &.last {
                border-bottom: 0;
                background-color: #F9F9F9;
                text-align: center;
                color: $i_color; } } }
    .draft-select {
        width: 360px; } }
.about-management {
    .list-page {
        width: 1100px; }
    .cont {
        ul.person {
            margin-right: -20px;
            font-size: 0;
            margin-bottom: 40px;
            .on {
                background-image: none;
                .name {
                    color: #ffffff; }
                .tit {
                    color: #ffffff; } }
            li {
                display: inline-block;
                width: 260px;
                height: 330px;
                border: 1px solid rgba(198,200,206,0.50);
                box-sizing: border-box;
                text-align: center;
                padding: 40px 32px 0;
                background: #00A4A0 url('images/ffff.png') repeat-x 0 120px;
                margin-right: 20px;
                vertical-align: top;
                cursor: pointer;
                &:hover {
                    background-image: none;
                    img {
                        transform: scale(1.1);
                        transition: all 0.5s linear; }
                    .name {
                        color: #ffffff; }
                    .tit {
                        color: #ffffff; } }

                // flex: none
                img {
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    margin-bottom: 30px; }
                .name {
                    font-size: 20px;
                    // color: #FFFFFF
                    color: #333333;
                    line-height: 23px;
                    margin-bottom: 18px; }
                .tit {
                    text-align: center;
                    font-size: 16px;
                    // color: #ffffff
                    color: #808080;
                    line-height: 24px;
                    margin-bottom: 0; } } }
        ul.introduction {
            margin-bottom: 40px;
            li {
                display: none;
                padding: 30px 40px;
                border: 1px solid #E3E4E7;
                box-shadow: 0 0 6px 0 rgba(0,164,160,0.4);
                .name {
                    font-size: 28px;
                    color: #00A4A0;
                    line-height: 34px;
                    margin-bottom: 15px; }
                .text {
                    margin-bottom: 15px; } } } } }


.wrapper {
    // margin-top: 80px
    &.no_second_nav {
        margin-top: 0; } }

footer {
    color: #fff;
    background-color: #393E46;
    .link-title {
        padding-top: 30px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500; }
    .link-list {
        overflow: hidden;
        margin-top: 10px;
        margin-bottom: 20px;
        a {
            float: left;
            display: inline-block;
            margin-right: 10px;
            line-height: 21px;
            font-size: 12px;
            color: #fff; } }
    .copyright-info {
        border-top: 1px solid #666;
        padding: 15px 0;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        a {
            color: #fff; } } }
/*底部固定部分*/
.fixed-section {
    position: fixed;
    left: 50%;
    bottom: 40px;
    margin-left: 600px;
    z-index: 1000;
    width: 48px;
    background: #fff;
    ul li {
        height: 48px;
        border: 1px solid #eee;
        border-bottom: none;
        text-align: center;
        cursor: pointer;
        &:last-child {
            border-bottom: 1px solid #eee; }
        i {
            display: inline-block;
            margin-top: 7px;
            background-size: contain; }
        &:first-child i {
            width: 20px;
            height: 20px;
            background-image: url(/public/css/pc-new/new-icon7.png); }
        &:nth-child(2) {
            &:before {
                position: absolute;
                top: 49px;
                left: -30px;
                content: '';
                width: 30px;
                height: 50px; }
            &:hover .tip-coop {
                display: block; }
            i {
                width: 20px;
                height: 17px;
                background-image: url(/public/css/pc-new/new-icon8.png); } }
        &:nth-child(3) {
            &:before {
                position: absolute;
                top: 100px;
                left: -30px;
                content: '';
                width: 30px;
                height: 50px; }
            &:hover .two-bar-codes {
                display: block; }
            i {
                width: 20px;
                height: 20px;
                background-image: url(/public/css/pc-new/new-icon9.png); } }
        &:last-child i {
            width: 18px;
            height: 21px;
            background-image: url(/public/css/pc-new/new-icon10.png); }
        p {
            color: #999;
            font-size: 20px;
            transform: scale(0.5);
            margin-top: -8px; }
        &:hover {
            border-left: 1px solid #FD8A25;
            border-right: 1px solid #FD8A25;
            border-color: #FD8A25;
            background: #FD8A25; }
        &:first-child:hover i {
            background-image: url(/public/css/pc-new/new-icon7hover.png); }
        &:nth-child(2):hover i {
            background-image: url(/public/css/pc-new/new-icon8hover.png); }
        &:nth-child(3):hover i {
            background-image: url(/public/css/pc-new/new-icon9hover.png); }
        &:last-child:hover i {
            background-image: url(/public/css/pc-new/new-icon10hover.png); }
        &:hover p {
            color: #fff; } }
    .tip-coop {
        position: absolute;
        top: 3px;
        right: 78px;
        width: 360px;
        background: #fff;
        box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.08);
        display: none;
        div {
            margin: 0;
            padding-bottom: 7px; }
        h3 {
            margin-bottom: 20px; }
        section {
            height: 21px;
            line-height: 21px;
            padding: 0 15px;
            font-size: 0;
            color: #333;
            margin-bottom: 20px;
            text-align: left;
            span {
                display: inline-block;
                width: 62px;
                font-size: 14px; }
            a {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                display: inline-block;
                margin-left: 8px; }
            em {
                font-style: normal;
                font-size: 14px; } } }
    .two-bar-codes {
        position: absolute;
        top: 0;
        right: 78px;
        width: 166px;
        height: 196px;
        background: #fff;
        box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.08);
        box-sizing: border-box;
        border-top: 2px solid #333;
        display: none;
        .codesTitle {
            height: 44px;
            line-height: 44px;
            border-bottom: 1px solid #eee;
            span {
                box-sizing: border-box;
                display: inline-block;
                height: 44px;
                padding: 0 15px;
                font-size: 14px;
                color: #333;
                &:hover {
                    color: #fd8a25;
                    border-bottom: 4px solid #fd8a25; } }
            .nowCode {
                color: #fd8a25;
                border-bottom: 4px solid #fd8a25; } }
        .codesContent {
            text-align: center;
            padding: 20px 0;
            img {
                width: 84px; }
            h4 {
                font-size: 12px;
                color: #666;
                margin-top: 10px;
                line-height: 17px;
                font-weight: 400; } } } }

.p-header-login {
    margin: 100px 0 50px;
    border-top: 1px solid #f2f2f2;
    text-align: center;
    .logo {
        top: -16px;
        position: relative;
        display: inline-block;
        *display: inline;
        width: 132px;
        height: 30px;
        background: #fff url(img/tmt_logo@2x.png) center center no-repeat;
        -webkit-background-size: 92px 30px;
        background-size: 92px 30px; } }
.m-header {
    height: 64px;
    line-height: 64px;
    // margin-bottom: 4%
    background-color: #FFF;
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        display: block;
        // border-bottom: 1px solid #E5E5E5
        width: 92%;
        margin: 0 auto; }
    .back {
        padding-left: 4%;
        @include fs(16);
        color: #333; }
    h2.tc {
        margin-top: -64px;
        color: #666666;
        @include fs(21); }
    .return {
        padding-left: 4%;
        color: #000;
        @include fs(16);
        position: absolute;
        top: 23px;
        left: 5px;
        i {
            color: #666; }
        strong {
            position: relative;
            top: -2px;
            left: 5px;
            font-weight: normal;
            color: #666; } } }
.m-footer {
    padding: 8% 5% 5%;
    a {
        color: #b3b3b3; }
    .forget {
        text-align: right; } }


.c-page {
    width: 700px;
    margin: 0 auto;
    padding: 40px 0;
    color: #808080; }
.dropdown-menu-part {
    position: relative;
    display: inline-block;
    *display: inline;
    white-space: normal; }
.dropdown-menu,.dropup-menu {
    padding: 15px 20px;
    position: absolute;
    top: 66px;
    background: #fff;
    border: 1px solid rgba(26,53,71,.12);
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(26,53,71,.1);
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    zoom: 1;
    pointer-events: none;
    // -webkit-transform: scale(.8) translateY(-30%)
    transition: .25s cubic-bezier(.3, 0, 0, 1.3);
    &::before {
        content: "";
        position: absolute;
        top: -7px;
        right: calc(50% - 9px);
        right: -webkit-calc(50% - 9px);
        right: -moz-calc(50% - 9px);
        width: 13px;
        height: 7px;
        z-index: 4;
        background: url(/public/css/img/dropdown-menu-arrow.svg); }
    &.user-dropdown {
        &::before {
            left: 89px; } }
    &.visible {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: alpha(opacity=100);
        zoom: 1;
        pointer-events: auto;
        -webkit-transform: none;
        transform: none;
        z-index: 3; } }
.dropdown-menu {
    -webkit-transform: scale(.8) translateY(-30%); }
.dropup-menu {
    -webkit-transform: scale(.8) translateY(30%); }
.user-dropdown {
    width: 154px;
    padding: 10px 0 0;
    left: -81px;
    li {
        padding: 0 0 0 25px;
        line-height: 42px;
        color: #666666;
        a {
            color: #666666; }
        &.last {
            padding: 0 25px;
            height: 40px;
            line-height: 42px;
            background-color: #F9f9f9;
            color: #b3b3b3;
            width: 104px;
            margin-right: 0; }
        i {
            margin-right: 10px;
            vertical-align: middle;
            position: relative;
            top: -1px; }
        .icon-wealth {
            // font-size: 13px
            @include fs(12);
            margin-right: 8px; }
        .nums {
            background-color: $orange;
            line-height: 16px;
            @include fs(12);


            @include rounded(50px);
            color: #fff !important;
            margin-left: 10px;
            padding: 0 5px; } } }
.main-dropdown {
    padding: 10px 0 10px 0;
    width: 137px;
    left: -45px;
    li {
        line-height: 42px;
        padding: 0 0 0 25px;
        color: #666666; }
    i {
        margin-right: 10px;
        @include fs(16);
        display: inline-block;
        *display: inline;
        vertical-align: middle;
        margin-top: -3px; }
    .icon-iconfont-present {
        @include fs(17); }
    .icon-read {
        font-size: 14px; } }
.weixin-dropdown {
    width: 500px;
    left: -250px;
    bottom: 30px;
    top: initial;
    &::before {
        top: initial;
        bottom: -7px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }
.qq-dropdown,.wx-dropdown {
    width: 115px;
    left: -59px;
    bottom: 30px;
    top: initial;
    &::before {
        top: initial;
        bottom: -7px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }
.share-dropdown {
    width: 150px;
    right: -13px;
    bottom: 45px;
    top: initial;
    a {
        line-height: 20px;
        font-size: 14px !important;
        font-size: 1.4rem !important; }
    &::before {
        top: initial;
        bottom: -7px;
        right: 14px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); } }



//导航改版
//@author hopekayo@gmail.com
//@DateTime 2016-04-21T15:52:45+0800
.new-header {
    min-width: 1200px;
    height: auto;
    .first-nav {
        height: $pc_nav_height;
        line-height: $pc_nav_height;
        border-bottom: 1px solid #e5e5e5;
        @include border-shadow(0,5px,8px,0,rgba(0,0,0,0.1));
        background-color: #fff;
        padding:  0 80px;
        .user-nav {
            ul {
                width: 100%;
                li {
                    white-space: nowrap; } } }
        ul {
            line-height: 54px; }
        li {
            vertical-align: top;
            position: relative; }
        a {
            color: #333; }
        //font-weight: bold
        .logo {
            i {
                display: inline-block;
                width: 0;
                height: $pc_nav_height;
                vertical-align: middle; } }
        .down-nav {
            margin: 0 3px;
            i {
                vertical-align: middle;
                @include fs(12);
                display: inline-block;
                *display: inline;
                transform: scale(.6); }
            i.hover {
                animation: turnl 0.3s linear 0s 1 normal running forwards; }
            i.hover-out {
                animation: turnr 0.5s linear 0s 1 normal running forwards; } }
        .read-select,.tmtbase-select {
            .read-dropdown,.tmtbase-dropdown {
                padding: 15px 0; }
            .read-nav,.tmtbase-nav {
                line-height: 32px; }
            li {
                text-align: center;
                vertical-align: middle;
                width: 75px;
                margin: 0;
                padding: 0 4px;
                letter-spacing: 3px;
                &:last-child {
                    letter-spacing: 1px; }
                a {
                    font-weight: normal;
                    color: #666; }
                a:hover,a.current {
                    color: $orange; } } }
        .tmtbase-select {
            .tmtbase-dropdown {
                left: 42px; } } }
    .logo-part {
        height: 100%;
        margin-right: 80px;
        display: inline-block;
        *display: inline;
        //float: left
        img {
            width: 101px;
            height: 35px; } }
    .first_nav_box {}
    //float: left
    .second-nav {
        .new {
            position: absolute;
            top: -15px;
            right: 3px; }

        .columns {
            // padding-left: 50px
            .all {
                margin-right: 20px;
                a {
                    text-align: center;
                    @include rounded(50%);
                    background-color: #333;
                    display: inline-block;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    color: #fff;
                    @include fs(12); } }
            .tag-recommend {
                width: 580px; } } }
    div.second-nav-large {
        line-height: 60px;
        height: 60px;
        .columns {
            line-height: 60px; } }
    .columns .read-select {
        margin-right: 45px;
        margin-left: 0px; }
    &.p-header-hide {
        -webkit-transform: translateY(-115px);
        -ms-transform: translateY(-115px);
        transform: translateY(-115px); } }
.p-header-hide-en {
    &.p-header-hide {
        -webkit-transform: translateY(-188px);
        -ms-transform: translateY(-188px);
        transform: translateY(-188px); } }
.container {
    .event-container .top-events {
        margin-top: 55px;
        padding-top: 0; } }




//导航改版-2
//@author hopekayo@gmail.com
//@DateTime 2016-08-23T14:07:45+0800
.v1_header {
    .first-nav {
        padding: 0 50px;
        background-color: #171717;
        height: 62px;
        line-height: 62px;
        border-bottom: none;
        a {
            color: #b3b3b3;
            font-weight: bold; }

        .logo-part {
            margin-right: 75px; }
        nav li {
            margin-right: 50px;
            vertical-align: top;
            a {
                border-bottom: 3px solid #171717;
                display: inline-block;
                line-height: 56px; }
            &.current,&:hover {
                a {
                    border-bottom: 3px solid $main_current;
                    color: $main_current; } }
            .read-nav {
                a {
                    border-bottom: 0;
                    color: #666; } } }
        .read-select li {
            a {
                border-bottom: none;
                line-height: normal; }
            &.current,&:hover {
                a {
                    border-bottom: none;
                    color: #2cbe99; } } } }

    .options {
        font-size: 14px;
        .post-edit {
            @include rounded(0);
            border-color: $main_current !important;
            color: $main_current !important;
            line-height: 30px;
            padding: 0 16px;
            background-color: transparent; }
        .line {
            border-color: #b3b3b3;
            height: 14px; } }


    .down-nav {
        margin: 0 2px;
        i {
            vertical-align: middle;
            @include fs(12);
            display: inline-block;
            *display: inline;
            transform: scale(.6); }
        i.hover {
            animation: turnl 0.3s linear 0s 1 normal running forwards; }
        i.hover-out {
            animation: turnr 0.5s linear 0s 1 normal running forwards; } }
    .read-select,.tmtbase-select {
        .read-dropdown,.tmtbase-dropdown {
            padding: 15px 0; }
        .read-nav,.tmtbase-nav {
            line-height: 32px; }
        li {
            text-align: center;
            vertical-align: middle;
            width: 75px;
            margin: 0;
            padding: 0 4px;
            letter-spacing: 3px;
            &:last-child {
                letter-spacing: 1px; }
            a {
                font-weight: normal;
                color: #666; }
            a:hover,a.current {
                color: $orange; } } }
    .tmtbase-select {
        .tmtbase-dropdown {
            left: 33px; } }
    .logo-part {
        margin-right: 30px;
        display: inline-block;
        *display: inline; }
    .second-nav {
        .new {
            position: absolute;
            top: -15px;
            right: 3px; }

        .columns {
            // padding-left: 50px
            .all {
                margin-right: 20px;
                a {
                    text-align: center;
                    @include rounded(50%);
                    background-color: #333;
                    display: inline-block;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    color: #fff;
                    @include fs(12); } }
            .tag-recommend {
                width: 580px; } } }
    div.second-nav-large {
        line-height: 60px;
        height: 60px;
        .columns {
            line-height: 60px; } }
    .columns .read-select {
        margin-right: 45px;
        margin-left: 0px; }
    &.p-header-hide {
        -webkit-transform: translateY(-62px);
        -ms-transform: translateY(-62px);
        transform: translateY(-62px); } }

.first_nav_box {
    opacity: 1;
    transition: opacity 0.4s linear 0s;
    ul {
        height: $pc_nav_height;
        line-height: $pc_nav_height; }
    li {
        &.current {
            position: relative;
            &::after {
                display: block;
                content: "";
                width: 100%;
                height: 3px;
                background-color: $main_blue;
                position: absolute;
                bottom: 0;
                left: 0; } }
        a {
            font-weight: bold;
            font-size: 16px !important; } } }
.pc-user {
    .author-label {
        margin-top: -8px; } }
.author-label {
    display: inline-block;
    padding: 0 2px 0 4px;
    background: url(/public/css/img/ddh_tabel_icon1.png) left center no-repeat;
    background-size: 5px 100%;
    font-size: 12px;
    color: $main_blue;
    vertical-align: middle;
    em {
        display: block;
        border: 1px solid $main;
        border-left: 0; }
    i {
        display: block;
        font-style: normal;
        transform: scale(0.8,0.8);
        -ms-transform: scale(0.8,0.8);
        -moz-transform: scale(0.8,0.8);
        -webkit-transform: scale(0.8,0.8);
        -o-transform: scale(0.8,0.8); } }


// 分辨率兼容
@media (max-width: 1440px) {
    .new-header {
        .first-nav {
            min-width: 1030px;
            padding: 0 45px;
            li {
                margin-right: 30px; }
            .ddh-entrance {
                margin-right: 20px; }
            .get_app {
                margin-left: 20px; } }
        .logo-part {
            margin-right: 50px; }
        .options {
            .post-edit {
                margin: 0 20px 0 5px; } } }
    .post_new {
        .new-header {
            .first-nav {
                min-width: 865px; } } } }

.my_setting,.pc-user {
    .wrapper {} }
// margin-top: 60px

.chainsights_logo {
    @include animation2(scale_logo,2s,infinite,ease-in-out,-0.5s);
    @keyframes scale_logo {
        0% {
            @include scale(1.5,1.5); }
        50% {
            @include scale(1.5,1.5); }
        60% {
            @include scale(1.8,1.8); }
        70% {
            @include scale(1.5,1.5); }
        80% {
            @include scale(1.8,1.8); }
        100% {
            @include scale(1.5,1.5); } } }
